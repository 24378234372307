/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Box, Grid, Link, Typography, useTheme } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import RowsPerPage from 'components/rows-per-page/RowsPerPage';
import TransactionItem from 'components/transaction-item/TransactionItem';
import PAGE from 'constants/page';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TransactionService from 'services/transaction/transactionService';
import {
    containerStyle,
    StyledTransactionTitle,
    transactionContentStyle,
    paginationStyle,
    noresultBoxStyle,
    noTransactionFoundContainerStyle,
    noTransactionFoundBoxStyle,
    noresultTextStyle,
    headerTextStyle,
    noresultHeaderBoxStyle,
    resultTextStyle,
    headerBoxStyle,
    noresultImageStyle,
    noTransactionFoundImageStyle,
    centerStyle,
    linkStyle,
    viewAllStyle,
    StyledKeyboardArrowRightIcon,
} from './TransactionList.style';
import { transactionListPropTypes } from 'types/transactionListPropTypes';

const CONSTANTS = {
    LOCALIZATION: {
        TRANSACTION_TITLE: 'transactions.title',
    }
};

const TransactionList = ({
    pageSize,
    term,
    isShowPagination = true,
    isShowHeader = false,
    isShowBtnViewMore = false,
}) => {
    const theme = useTheme();

    const [transactions, setTransactions] = useState([]);
    const [currentPageSize, setCurrentPageSize] = useState(pageSize || 5);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [totalElement, setTotalElement] = useState(0);
    const { jwtToken } = useAuth();
    const [fetched, setFetched] = useState(false);
    
    const [isSearchResult, setIsSearchResult] = useState(false);
    const { t } = useTranslation();
    
    useEffect(() => {
        TransactionService.searchTransactions(
            currentPage,
            currentPageSize,
            term,
            jwtToken,
        ).then(response => {
            setFetched(true);
            setTotalPage(response.totalPages);
            setTotalElement(response.totalElements);
            setTransactions(response.data);
        });
    }, [term, currentPageSize, currentPage]);

    

    useEffect(() => {
        setIsSearchResult(!!(term && term.trim().length > 0));
    }, [transactions]);

    const hasNoTransaction = !transactions || transactions.length === 0;

    const showTransactionNotFound = (term || !isShowBtnViewMore) && hasNoTransaction && fetched;

    return (
        <Box sx={containerStyle}>
            {!term &&
            isShowBtnViewMore &&
            hasNoTransaction ? (
                <Box sx={noTransactionFoundContainerStyle}>
                    <StyledTransactionTitle>
                        {t(CONSTANTS.LOCALIZATION.TRANSACTION_TITLE)}
                    </StyledTransactionTitle>
                    <Box sx={noTransactionFoundBoxStyle(theme)}>
                        <img
                            src={theme.noDataFound}
                            alt={t('transactions.no-transactions-found')}
                            style={noTransactionFoundImageStyle}
                        />
                        <Typography sx={noresultTextStyle(theme)}>
                            {t('transactions.no-transactions-found')}
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <ShowTransactionList
                    showTransactionNotFound={showTransactionNotFound}
                    isShowBtnViewMore={isShowBtnViewMore}
                    transactions={transactions}
                    theme={theme}
                    currentPageSize={currentPageSize}
                    setCurrentPageSize={setCurrentPageSize}
                    totalElement={totalElement}
                    totalPage={totalPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isSearchResult={isSearchResult}
                    isShowHeader={isShowHeader}
                    isShowPagination={isShowPagination}
                />
            )}
        </Box>
    );
};

TransactionList.propTypes = {
    ...transactionListPropTypes,
};


const ShowTransactionList = ({
    showTransactionNotFound,
    isShowBtnViewMore,
    transactions,
    theme,
    currentPageSize,
    setCurrentPageSize,
    totalElement,
    totalPage,
    currentPage,
    setCurrentPage,
    isSearchResult,
    isShowHeader,
    isShowPagination,
}) => {
    const rowPerPages = [5, 10, 20, 50];
    const { t, i18n } = useTranslation();
    const dir = i18n.dir();
    const navigate = useNavigate();

    const handleViewMore = () => {
        navigate(PAGE.TRANSACTIONS);
    };
    return (
        <>
            {showTransactionNotFound ? (
                <Box sx={noresultBoxStyle}>
                    <Box sx={noresultHeaderBoxStyle}>
                        <Typography sx={headerTextStyle(theme)}>
                            {t('transactions.search-results')}
                        </Typography>
                    </Box>
                    <img
                        src={theme.noResult}
                        alt={t('transactions.no-result-found')}
                        style={noresultImageStyle}
                    />
                    <Typography sx={noresultTextStyle(theme)}>
                        {t('transactions.no-result-found')}
                    </Typography>
                </Box>
            ) : (
                <>
                    {isShowHeader ? (
                        <Box sx={headerBoxStyle}>
                            {isSearchResult ? (
                                <Typography sx={headerTextStyle(theme)}>
                                    {t('transactions.search-results')}
                                </Typography>
                            ) : (
                                <Typography sx={headerTextStyle(theme)}>
                                    {t(CONSTANTS.LOCALIZATION.TRANSACTION_TITLE)}
                                </Typography>
                            )}
                            <Typography sx={resultTextStyle(theme)}>
                                {totalElement}{' '}
                                {t('transactions.results')}
                            </Typography>
                        </Box>
                    ) : (
                        <Box>
                            <StyledTransactionTitle>
                                {t(CONSTANTS.LOCALIZATION.TRANSACTION_TITLE)}
                            </StyledTransactionTitle>
                        </Box>
                    )}
                    <Box sx={transactionContentStyle}>
                        <Grid
                            container
                            direction="column"
                            rowSpacing={3}
                        >
                            {transactions?.map(transaction => (
                                <TransactionItem
                                    key={transaction.id}
                                    txnItem={transaction}
                                />
                            ))}
                        </Grid>
                    </Box>
                    <ViewMoreComponent
                        t={t}
                        theme={theme}
                        dir={dir}
                        isShowBtnViewMore={isShowBtnViewMore}
                        handleViewMore={handleViewMore}
                    />
                    {isShowPagination && (
                        <Box style={paginationStyle}>
                            <RowsPerPage
                                defaultValue={currentPageSize}
                                rowsPerPage={rowPerPages}
                                totalRecords={totalElement}
                                firstRecordOfPage={(currentPage - 1) * currentPageSize + 1}
                                lastRecordOfPage={currentPage === totalPage ? totalElement : currentPageSize * currentPage}
                                onValueChange={e => {
                                    setCurrentPage(1);
                                    setCurrentPageSize(e);
                                }}
                            ></RowsPerPage>
                            <Pagination
                                defaultPage={currentPage}
                                totalPages={totalPage}
                                onValueChange={e => {
                                    setCurrentPage(e);
                                }}
                            ></Pagination>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};

const ViewMoreComponent = ({
    t,
    theme,
    dir,
    handleViewMore,
    isShowBtnViewMore,
}) => {
    return (
        isShowBtnViewMore && (
            <Box sx={centerStyle}>
                <Link onClick={handleViewMore} sx={linkStyle}>
                    <Typography sx={viewAllStyle(theme)}>
                        {t('currency-account.view-all')}
                    </Typography>
                    <StyledKeyboardArrowRightIcon dir={dir} />
                </Link>
            </Box>
        )
    );
};

export default TransactionList;

