/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { React, useEffect, useState } from 'react';
import Button from 'components/button/Button';
import { Box, Link, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    StyledDialog,
    StyledDialogContent,
    disablePadding,
    DialogTitleTypography,
    DialogContentTypography,
    viewDetailsContainer,
    linkStyle,
    expandIconStyle,
    contentDetailsStyle,
} from './Popup.style';
import { popupPropTypes } from 'types/popupPropTypes';
import { useTranslation } from 'react-i18next';

const Popup = ({
    title,
    content,
    viewDetails,
    displayCancel,
    onAgree,
    onClose,
    open,
    hideViewDetailsButton,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [isShowMore, setIsShowMore] = useState(false);
    useEffect(() => {
        setIsShowMore(hideViewDetailsButton);
    }, [hideViewDetailsButton]);
    return (
        <StyledDialog open={open} onClose={onClose}>
            {title && (
                <DialogTitle sx={disablePadding}>
                    <DialogTitleTypography>{title}</DialogTitleTypography>
                </DialogTitle>
            )}
            <StyledDialogContent
                sx={{
                    ...disablePadding,
                    ...(isShowMore && expandIconStyle),
                }}
            >
                <DialogContentTypography>{content}</DialogContentTypography>
                {viewDetails && (
                    <Box sx={viewDetailsContainer}>
                        {!hideViewDetailsButton && (
                            <Box>
                                <Link
                                    onClick={() => setIsShowMore(!isShowMore)}
                                    sx={linkStyle(theme)}
                                >
                                    <DialogContentTypography>
                                        {t('popup.view-details')}
                                    </DialogContentTypography>
                                    <ExpandMoreIcon />
                                </Link>
                            </Box>
                        )}
                        {isShowMore && (
                            <Typography sx={contentDetailsStyle(theme)}>
                                {viewDetails}
                            </Typography>
                        )}
                    </Box>
                )}
            </StyledDialogContent>
            <DialogActions sx={disablePadding}>
                {displayCancel && (
                    <Button label={t('button.cancel')} onClick={onClose} />
                )}
                <Button
                    variant="contained"
                    onClick={onAgree}
                    autoFocus
                    label={t('button.ok')}
                />
            </DialogActions>
        </StyledDialog>
    );
};

Popup.propTypes = {
    ...popupPropTypes,
};

export default Popup;

