/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Box } from '@mui/material';
import {
    boxStyle,
    StyledCircularProgress,
    buttonStyle,
    StyledButton,
} from './Button.style';
import { buttonPropTypes } from 'types/buttonPropTypes';
import { useTranslation } from 'react-i18next';

const Button = ({
    disabled,
    variant,
    size,
    startIcon,
    endIcon,
    label,
    type,
    onClick,
    loading,
    fullWidth,
}) => {
    const { i18n } = useTranslation();
    const dir = i18n.dir();
    return (
        <StyledButton
            disabled={disabled}
            startIcon={startIcon}
            endIcon={endIcon}
            variant={variant}
            type={type}
            fullWidth={fullWidth}
            onClick={onClick}
            style={{ ...buttonStyle, ...getSize(size), ...getBorder(variant) }}
            dir={dir}
        >
            <Box sx={boxStyle}>
                {label}
                {loading && <StyledCircularProgress />}
            </Box>
        </StyledButton>
    );
};

const getBorder = variant => {
    if (variant === 'outlined') {
        return {
            border: '2px solid',
        };
    }
    return {};
}

const getSize = size => {
    switch (size) {
        case 'small':
            return {
                minWidth: '100px',
                height: '36px',
            };
        case 'large':
            return {
                minWidth: '160px',
                height: '60px',
            };
        default:
            return {
                minWidth: '140px',
                height: '50px',
            };
    }
}

Button.propTypes = {
    ...buttonPropTypes,
};

Button.defaultProps = {
    disabled: false,
};
export default Button;

