/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { PropTypes } from 'prop-types';

export const tabPanelPropTypes = {
    children: PropTypes.node,
    value: PropTypes.number,
    index: PropTypes.number,
};