/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, useTheme } from '@mui/material';
import {
    containerStyle,
    boxItemStyle,
    requiredStyle,
    hyperTextStyle,
    titleStyle,
    errorStyle,
    formStyle,
    boxContainerStyle,
} from './BankAccountForm.style';
import PageTitle from 'components/page-title/PageTitle';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Input from 'components/input/Input';
import Button from 'components/button/Button';
import INPUT_FORMAT from 'constants/inputFormat';
import { useToast } from 'contexts/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import PAGE from 'constants/page';
import WalletService from 'services/wallet/walletService';
import { isSuccess } from 'constants/statusCode';
import { useAuth } from 'hooks/useAuth';
import Popup from 'components/pop-up/Popup';
import PAYMENT_METHOD from 'constants/paymentMethod';

const CONSTANTS = {
    LOCALIZATION: {
        VALIDATION_REQUIRED: 'validation.required',
    }
};

const BankAccountForm = ({ onClick, setIsAddBankAccount }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { showToast } = useToast();
    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [bankAccountDetail, setBankAccountDetail] = useState(null);
    const navigate = useNavigate();
    const { jwtToken } = useAuth();
    const [errorMsg, setErrorMsg] = useState('');
    const [isShowPopup, setIsShowPopup] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (id) {
            WalletService.getBankAccountDetail(jwtToken, id).then(
                response => {
                    if (isSuccess(response?.code)) {
                        setBankAccountDetail(response.result);
                    } else {
                        setIsShowPopup(true);
                        setErrorMsg(
                            response?.message || t('error-message.common'),
                        );
                    }
                },
            );
        }
    }, [id]);

    const onSubmit = data => {
        setLoading(true);
        data.paymentMethod = PAYMENT_METHOD.BANK_TRANSFER;
        WalletService.linkBankAccount(jwtToken, data).then(response => {
            if (isSuccess(response?.code)) {
                showToast(
                    true,
                    false,
                    false,
                    false,
                    '',
                    t('bank-account.successful'),
                    '320px',
                );
                setIsAddBankAccount();
            } else {
                showToast(
                    false,
                    true,
                    false,
                    false,
                    '',
                    response?.message || t('bank-account.failed'),
                    '320px',
                );
            }
            setLoading(false);
        });
    };

    return (
        <Container sx={containerStyle(id)}>
            <Popup
                open={isShowPopup}
                content={errorMsg}
                onClose={() => {
                    setIsShowPopup(false);
                    navigate(PAGE.LINKED_BANK_ACCOUNTS);
                }}
                onAgree={() => {
                    setIsShowPopup(false);
                    navigate(PAGE.LINKED_BANK_ACCOUNTS);
                }}
            />
            <Box sx={boxItemStyle}>
                {id ? (
                    <PageTitle
                        onClick={() => navigate(PAGE.LINKED_BANK_ACCOUNTS)}
                        title={t('bank-account.bank-account-information')}
                    />
                ) : (
                    <>
                        <PageTitle
                            onClick={onClick}
                            title={t('bank-account.link-a-bank-account')}
                        />
                    </>
                )}
            </Box>
            <form onSubmit={handleSubmit(onSubmit)} style={formStyle(theme)}>
                <Box sx={boxContainerStyle}>
                    <BankNameComponent
                        t={t}
                        theme={theme}
                        control={control}
                        errors={errors}
                        id={id}
                        bankAccountDetail={bankAccountDetail}
                    />
                    <BankAccountNumberComponent
                        t={t}
                        theme={theme}
                        control={control}
                        errors={errors}
                        id={id}
                        bankAccountDetail={bankAccountDetail}
                    />
                    <Box sx={boxItemStyle}>
                        <Typography sx={titleStyle}>
                            {t('manage-accounts.account-holder-name')}{' '}
                            {!id && <span style={requiredStyle(theme)}>*</span>}
                        </Typography>
                        <Controller
                            name="cardHolderName"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <Input
                                    isDisabled={!!id}
                                    isViewDetail={id ? id : ''}
                                    defaultValue={
                                        bankAccountDetail
                                            ? bankAccountDetail.cardHolderName
                                            : ''
                                    }
                                    placeholder={
                                        !id &&
                                        t(
                                            'bank-account.enter-account-holder-name',
                                        )
                                    }
                                    onValueChange={value =>
                                        field.onChange(value)
                                    }
                                />
                            )}
                        />
                        {errors.cardHolderName?.type === 'required' && (
                            <Typography sx={errorStyle(theme)}>
                                {t(CONSTANTS.LOCALIZATION.VALIDATION_REQUIRED, {
                                    field: t(
                                        'manage-accounts.account-holder-name',
                                    ),
                                })}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={boxItemStyle}>
                        <Typography sx={titleStyle}>
                            {t('bank-account.routing-number')}
                        </Typography>
                        <Controller
                            name="routingNumber"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    isDisabled={!!id}
                                    isViewDetail={id ? id : ''}
                                    defaultValue={
                                        bankAccountDetail
                                            ? bankAccountDetail.routingNumber
                                            : ''
                                    }
                                    placeholder={
                                        !id &&
                                        t('bank-account.enter-routing-number')
                                    }
                                    onValueChange={value =>
                                        field.onChange(value)
                                    }
                                    format={INPUT_FORMAT.NUMERIC.name}
                                />
                            )}
                        />
                        {!id && (
                            <Typography sx={hyperTextStyle(theme)}>
                                {t('bank-account.only-for-us-bank-accounts')}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={boxItemStyle}>
                        <Typography sx={titleStyle}>
                            {t('bank-account.swift-code')}
                        </Typography>
                        <Controller
                            name="swiftCode"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    isDisabled={!!id}
                                    isViewDetail={id ? id : ''}
                                    defaultValue={
                                        bankAccountDetail
                                            ? bankAccountDetail.swiftCode
                                            : ''
                                    }
                                    placeholder={
                                        !id &&
                                        t('bank-account.enter-SWIFT-code')
                                    }
                                    onValueChange={value =>
                                        field.onChange(value)
                                    }
                                    format={INPUT_FORMAT.NUMERIC.name}
                                />
                            )}
                        />
                        {!id && (
                            <Typography sx={hyperTextStyle(theme)}>
                                {t(
                                    'bank-account.only-for-non-us-bank-accounts',
                                )}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={boxItemStyle}>
                        <Typography sx={titleStyle}>
                            {t('bank-account.iban')}
                        </Typography>
                        <Controller
                            name="iban"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    isDisabled={!!id}
                                    isViewDetail={id ? id : ''}
                                    defaultValue={
                                        bankAccountDetail
                                            ? bankAccountDetail.iban
                                            : ''
                                    }
                                    placeholder={
                                        !id && t('bank-account.enter-IBAN')
                                    }
                                    onValueChange={value =>
                                        field.onChange(value)
                                    }
                                    format={INPUT_FORMAT.NUMERIC.name}
                                />
                            )}
                        />
                        {!id && (
                            <Typography sx={hyperTextStyle(theme)}>
                                {t(
                                    'bank-account.only-for-non-us-bank-accounts',
                                )}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={boxItemStyle}>
                        <Typography sx={titleStyle}>
                            {t('bank-account.address')}{' '}
                            {!id && <span style={requiredStyle(theme)}>*</span>}
                        </Typography>
                        <Controller
                            name="address"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <Input
                                    isDisabled={!!id}
                                    isViewDetail={id ? id : ''}
                                    defaultValue={
                                        bankAccountDetail
                                            ? bankAccountDetail.address
                                            : ''
                                    }
                                    placeholder={
                                        !id && t('bank-account.enter-address')
                                    }
                                    onValueChange={value =>
                                        field.onChange(value)
                                    }
                                />
                            )}
                        />
                        {errors.address?.type === 'required' && (
                            <Typography sx={errorStyle(theme)}>
                                {t(CONSTANTS.LOCALIZATION.VALIDATION_REQUIRED, {
                                    field: t('bank-account.address'),
                                })}
                            </Typography>
                        )}
                    </Box>
                </Box>
                {!id && (
                    <Button
                        disabled={loading}
                        loading={loading}
                        fullWidth={true}
                        type="submit"
                        variant="contained"
                        label={t('bank-account.link-bank-account')}
                    />
                )}
            </form>
        </Container>
    );
};

const BankNameComponent = ({
    t,
    theme,
    control,
    errors,
    id,
    bankAccountDetail,
}) => {
    return (
        <Box sx={boxItemStyle}>
            <Typography>
                {t('bank-account.bank-name')}{' '}
                {!id && <span style={requiredStyle(theme)}>*</span>}
            </Typography>
            <Controller
                name="bankName"
                control={control}
                rules={{
                    required: true,
                }}
                render={({ field }) => (
                    <Input
                        isDisabled={!!id}
                        isViewDetail={id ? id : ''}
                        defaultValue={
                            bankAccountDetail ? bankAccountDetail.bankName : ''
                        }
                        placeholder={!id && t('bank-account.enter-bank-name')}
                        onValueChange={value => field.onChange(value)}
                    />
                )}
            />
            {errors.bankName?.type === 'required' && (
                <Typography sx={errorStyle(theme)}>
                    {t(CONSTANTS.LOCALIZATION.VALIDATION_REQUIRED, {
                        field: t('bank-account.bank-name'),
                    })}
                </Typography>
            )}
        </Box>
    );
};

const BankAccountNumberComponent = ({
    t,
    theme,
    control,
    errors,
    id,
    bankAccountDetail,
}) => {
    return (
        <Box sx={boxItemStyle}>
            <Typography sx={titleStyle}>
                {t('bank-account.bank-account-number')}{' '}
                {!id && <span style={requiredStyle(theme)}>*</span>}
            </Typography>
            <Controller
                name="cardNumber"
                control={control}
                rules={{
                    required: true,
                }}
                render={({ field }) => (
                    <Input
                        isDisabled={!!id}
                        isViewDetail={id ? id : ''}
                        defaultValue={
                            bankAccountDetail
                                ? bankAccountDetail.cardNumber
                                : ''
                        }
                        placeholder={
                            !id && t('bank-account.enter-bank-account-number')
                        }
                        onValueChange={value => field.onChange(value)}
                        format={INPUT_FORMAT.NUMERIC.name}
                    />
                )}
            />
            {errors.cardNumber?.type === 'required' && (
                <Typography sx={errorStyle(theme)}>
                    {t(CONSTANTS.LOCALIZATION.VALIDATION_REQUIRED, {
                        field: t('bank-account.bank-account-number'),
                    })}
                </Typography>
            )}
        </Box>
    );
};

export default BankAccountForm;

