/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { List } from '@mui/material';
import Popup from 'components/pop-up/Popup';
import PAYMENT_METHOD from 'constants/paymentMethod';
import { useAuth } from 'hooks/useAuth';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import WalletService from 'services/wallet/walletService';
import PaymentMethodItem from './payment-method-item/PaymentMethodItem';
import { paymentMethodsStyled } from './PaymentMethodList.style';
import { paymentMethodListPropTypes } from 'types/paymentMethodListPropTypes';
export default function PaymentMethodList({ items, ...props }) {
    const navigate = useNavigate();
    const { jwtToken } = useAuth();
    const [open, setOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const { t } = useTranslation();
    const handleClick = async (item) => {
        if (item.paymentMethod === PAYMENT_METHOD.CREDIT_CARD) {
            const allowCreation = await getCreationInfo(item.paymentMethod);
            if (allowCreation) {
                navigate(item.navigate, {
                    state: {
                        allowCreation: true,
                    },
                });
            } else if (allowCreation === undefined) {
                setOpen(true);
                setErrorMsg(t('error-message.common'));
            } else {
                setOpen(true);
                setErrorMsg(t('add-funds.error.kyc-level'));
            }
        } else {
            navigate(item.navigate);
        }
    };
    const getCreationInfo = async (paymentMethod) => {
        const allowCreation = await WalletService.getCreationInfo(
            jwtToken,
            paymentMethod,
        ).then((response) => {
            return response?.allowCreation;
        });
        return allowCreation;
    };

    return (
        <>
            <Popup
                open={open}
                content={errorMsg}
                onClose={() => {
                    setOpen(false);
                }}
                onAgree={() => {
                    setOpen(false);
                }}
            />
            <List sx={[paymentMethodsStyled]} {...props}>
                {items?.map((item) => {
                    return (
                        <PaymentMethodItem
                            key={item.id}
                            value={item.value}
                            prefixIcon={item.prefixIcon}
                            suffixIcon={item.suffixIcon}
                            onClick={() => handleClick(item)}
                        />
                    );
                })}
            </List>
        </>
    );
}
PaymentMethodList.propTypes = {
    ...paymentMethodListPropTypes,
};

