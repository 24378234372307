/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import PageTitle from 'components/page-title/PageTitle';
import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, useTheme, Container } from '@mui/material';
import {
    boxContainerStyle,
    boxItemStyle,
    errorStyle,
    requiredStyle,
    formStyle,
    boxInfoStyle,
} from './AddCardForm.style';
import { useTranslation } from 'react-i18next';
import Dropdown from 'components/dropdown/Dropdown';
import resourceUrl from 'resourceResolver';
import Button from 'components/button/Button';
import { useForm, Controller } from 'react-hook-form';
import { useAuth } from 'hooks/useAuth';
import Popup from 'components/pop-up/Popup';
import WalletService from 'services/wallet/walletService';
import { getAccountInfo } from 'services/profile/profileService';
import { getBranding } from 'services/payment/paymentService';
import AddCardSuccessful from 'components/add-card-successful/AddCardSuccessful';
import { isSuccess } from 'constants/statusCode';
import Input from 'components/input/Input';
const iconCard = resourceUrl('icons/card.svg');
const AddCardForm = ({ onClick, setIsAddCard }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [cardTypeList, setCardTypeList] = useState([]);
    const [subSection, setSubSection] = useState([]);
    const [branding, setBranding] = useState([]);
    const [data, setData] = useState([]);
    const [accountHolderName, setAccountHolderName] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const cardType = useRef();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isShowPopup, setIsShowPopup] = useState(false);
    const {
        control,
        handleSubmit,
        watch,
        resetField,
        formState: { errors },
    } = useForm();
    const { jwtToken } = useAuth();
    cardType.current = watch('cardType');

    useEffect(() => {
        if (!cardType.current) {
            return;
        }
        setOpen(true);
        setLoading(true);
        resetField('brand');
        getBranding(jwtToken, cardType.current).then(response => {
            const mappedData = response?.map(item => {
                return {
                    text: item.brandName,
                    value: item.code,
                };
            });
            setBranding(mappedData);
            setLoading(false);
        });
    }, [cardType.current]);

    useEffect(() => {
        WalletService.getSubsection(jwtToken).then(response => {
            const mappedData = response?.map(item => {
                return {
                    text: item.label,
                    value: item.subsection,
                };
            });
            setSubSection(response);
            setCardTypeList(mappedData);
        });
    }, []);

    useEffect(() => {
        getAccountInfo(jwtToken).then(response => {
            if (response) {
                setAccountHolderName(response.fullName);
            } else {
                setErrorMsg(t('error-message.common'));
                setIsShowPopup(true);
            }
        });
    }, []);

    const onSubmit = data => {
        setIsSubmit(true);
        const paymentMethod = subSection.find(
            item => item.subsection === data.cardType,
        )?.paymentMethod;
        const body = {
            paymentMethod: paymentMethod,
            cardHolderName: data.cardHolderName,
            brand: data.brand || '',
        };
        WalletService.createPaymentAccount(jwtToken, body).then(response => {
            if (isSuccess(response?.code)) {
                setData(response.result);
            } else {
                setIsSubmit(false);
                setErrorMsg(response?.message || t('error-message.common'));
                setIsShowPopup(true);
            }
        });
    };

    let onClickHandler = onClick;
    if (isSubmit) {
    onClickHandler = null;
    }

    return (
        <>
            {data.length === 0 && (
                <Container sx={boxContainerStyle}>
                    <Popup
                        open={isShowPopup}
                        content={errorMsg}
                        onClose={() => {
                            setIsShowPopup(false);
                        }}
                        onAgree={() => {
                            setIsShowPopup(false);
                        }}
                    />
                    <PageTitle
                        title={t('manage-accounts.add-card')}
                        onClick={onClickHandler}
                        submit={`${isSubmit}`}
                    />
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={formStyle(theme)}
                    >
                        <Box sx={boxInfoStyle}>
                            <Box sx={boxItemStyle}>
                                <Typography>
                                    {t('card-information.card-type')}
                                </Typography>
                                <Controller
                                    name="cardType"
                                    control={control}
                                    render={({ field }) => (
                                        <Dropdown
                                            icon={iconCard}
                                            disabled={
                                                cardTypeList.length === 0 ||
                                                isSubmit
                                            }
                                            label={t(
                                                'transfer.tab.transfer-money.choose-a-card',
                                            )}
                                            options={cardTypeList}
                                            onChangeValue={value =>
                                                field.onChange(value)
                                            }
                                        />
                                    )}
                                />
                            </Box>
                            {open && (
                                <>
                                    {branding && branding.length > 0 && (
                                        <Box sx={boxItemStyle}>
                                            <Typography>
                                                {t('manage-accounts.branding')}{' '}
                                                <span
                                                    style={requiredStyle(theme)}
                                                >
                                                    *
                                                </span>
                                            </Typography>
                                            <Controller
                                                name="brand"
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) => (
                                                    <Dropdown
                                                        key={cardType.current}
                                                        label={t(
                                                            'manage-accounts.choose-branding',
                                                        )}
                                                        disabled={isSubmit}
                                                        options={branding}
                                                        onChangeValue={(
                                                            value,
                                                        ) => {
                                                            field.onChange(
                                                                value,
                                                            );
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.brand?.type ===
                                                'required' && (
                                                <Typography
                                                    sx={errorStyle(theme)}
                                                >
                                                    {t('validation.required', {
                                                        field: t(
                                                            'manage-accounts.branding',
                                                        ),
                                                    })}
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                    <Box sx={{ ...boxItemStyle, mb: 1 }}>
                                        <Typography>
                                            {t(
                                                'manage-accounts.account-holder-name',
                                            )}
                                        </Typography>
                                        <Controller
                                            name="cardHolderName"
                                            control={control}
                                            defaultValue={accountHolderName}
                                            render={() => (
                                                <Input
                                                    defaultValue={
                                                        accountHolderName
                                                    }
                                                    isDisabled={true}
                                                    isViewDetail={true}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Button
                                        label={t('manage-accounts.add-card')}
                                        type="submit"
                                        fullWidth={true}
                                        variant="contained"
                                        disabled={isSubmit || loading}
                                        loading={isSubmit}
                                    ></Button>
                                </>
                            )}
                        </Box>
                    </form>
                </Container>
            )}
            {data.length !== 0 && (
                <AddCardSuccessful
                    response={data}
                    setIsAddCard={setIsAddCard}
                />
            )}
        </>
    );
};

export default AddCardForm;

