/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { PropTypes } from 'prop-types';

export const popupPropTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    displayCancel: PropTypes.bool,
    onAgree: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
};
