/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import PropTypes from "prop-types";

export const accountPropTypes = {
    data: PropTypes.object,
    transferAction: PropTypes.func,
    viewAction: PropTypes.func,
};