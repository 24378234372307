/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    StyledTypography,
    imgStyle,
    boxConatinerStyle,
} from './EmptyPage.style';
import { emptyPagePropTypes } from '../../types/emptyPagePropTypes';

const EmptyPage = ({ sx }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Box sx={{ ...boxConatinerStyle, ...sx }}>
            <StyledTypography>{t('empty-page.title')}</StyledTypography>
            <img style={imgStyle} src={theme.emptyPage} alt="Page not found" />
        </Box>
    );
};
EmptyPage.propTypes = {
    ...emptyPagePropTypes,
};
export default EmptyPage;
