/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

const CARD_TYPE = {
    BANK: 'BANK',
    BANCOOMEVA: 'BANCOOMEVA',
    PAYPAL: 'PAYPAL',
    CREDIT: 'CREDIT',
    VISA: 'VISA',
    MASTER_CARD: 'MASTER_CARD',
    JCB: 'JCB',
    DISCOVER: 'DISCOVER',
    DINERS_CLUB: 'DINERS_CLUB',
    AMERICAN_EXPRESS: 'AMERICAN_EXPRESS',
    CASH_CARD: 'KK',
    KEMP: 'KEMP',
    EBT: 'EBT',
    SA: 'SA',
    SD: 'SD',
};
export default CARD_TYPE;
