/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material/styles';

export const boxNameStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
};

export const boxLoadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    height: '204px',
    alignItems: 'center',
};

export const CustomSVG = styled(ReactSVG)(({ theme }) => ({
    svg: {
        'path[fill="#8694A3"]': {
            fill: theme.appPalette?.grey.grey60,
        },
        'path[stroke="#8694A3"]': {
            stroke: theme.appPalette?.grey.grey60,
        },
    },
    lineHeight: 0,
}));