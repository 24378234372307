/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { useState } from 'react';
import { Box, Button, MenuItem, Typography } from '@mui/material';
import { StyledMenu, CustomSVG, buttonStyle } from './Menu.style';
const Menu = ({ icon, items, onClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (id, action) => {
        setAnchorEl(null);
        const payload = { id, action };
        onClick(payload);
    };

    return (
        <Box>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={buttonStyle}
            >
                <CustomSVG src={icon} />
            </Button>

            <StyledMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {items &&
                    items.map((item) => (
                        <MenuItem
                            disableRipple
                            key={item.label}
                            onClick={() => handleClose(item.id, item.action)}
                        >
                            <CustomSVG src={item.icon} />
                            <Typography>{item.label}</Typography>
                        </MenuItem>
                    ))}
            </StyledMenu>
        </Box>
    );
};

export default Menu;
