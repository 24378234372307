/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { useEffect, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import resourceUrl from 'resourceResolver';
import { Box, Container, Typography, useTheme } from '@mui/material';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import {
    containerStyle,
    titleStyle,
    formStyle,
    boxContainerStyle,
    boxItemStyle,
    inlineErrorStyle,
    requiredStyle,
    hyperTextStyle,
    CustomSVG,
    confirmTitleStyle,
    boxTitleStyle,
    boxTransferStyle,
    boxInfoStyle,
    boxInfoItemStyle,
    titleInfoStyle,
    memoStyle,
    imageStyle,
    successfulTitleStyle,
    boxSuccessStyle,
    loadTitleStyle,
    boxFailed,
    descStyle,
    viewDetailsStyle,
    formattedAmountStyle,
    boxFromStyle,
    boxToStyle,
    boxAmountStyle,
    boxOrderStyle,
} from 'components/transfer-form/TransferForm.style';
import CardAccountCustomComboBox from 'components/card-account-custom-combo-box/CardAccountCustomComboBox';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import PAGE from 'constants/page';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import {
    getCardListing,
    getLinkedAccounts,
} from 'services/accounting/accountingService';
import { useAuth } from 'hooks/useAuth';
import { v4 } from 'uuid';
import {
    getTransferInfo,
    kount,
    transferMoney,
} from 'services/payment/paymentService';
import Popup from '../../components/pop-up/Popup';
import PaymentMethod from '../../constants/paymentMethod';
import Textarea from 'components/textarea/Textarea';

const iconCard = resourceUrl('icons/card.svg');
const arrowLeft = resourceUrl('icons/arrow-left.svg');

const CONSTANTS = {
    LOCALIZATION: {
        TRANSFER_FROM: 'transfer.tab.transfer-money.transfer-from',
        TRANSFER_TO: 'transfer.tab.transfer-money.transfer-to',
        SOURCE_OF_FUND: 'transfer.source-of-fund',
        LOAD_TO: 'transfer.load-to',
    },
    MEMO_MAX_LENGTH: 200,
    AMOUNT_GREATER_THAN: 0,
};

const TransferForm = () => {
    const { jwtToken } = useAuth();
    const [cashAccountOptions, setCashAccountOptions] = useState([]);
    const [linkedAccountOptions, setLinkedAccountOptions] = useState([]);
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        watch,
    } = useForm();
    const [sessionId, setSessionId] = useState(null);
    const [transferInfo, setTransferInfo] = useState(null);
    const [linkAccounts, setLinkAccounts] = useState([]);
    const [cashAccounts, setCashAccounts] = useState([]);
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [errorMsg, setErrorMsg] = useState({
        title: '',
        content: '',
        extraInfo: '',
    });
    const [isSubmit, setIsSubmit] = useState(false);
    const dir = i18n.dir();
    const memo = useRef({});
    memo.current = watch('memo');
    const [remitterInfo, setRemitterInfo] = useState({});
    const [beneficiaryInfo, setBeneficiaryInfo] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [transactionId, setTransactionId] = useState('');
    const [formattedAmount, setFormattedAmount] = useState('');
    const [isTransferPage, setIsTransferPage] = useState(false);
    const [isConfirmPage, setIsConfirmPage] = useState(false);
    const [extraInfo3SP, setExtraInfo3SP] = useState(false);
    const normalizeCardOptions = (data, setOptions) => {
        const cardOptions = data.map(item => {
            return {
                title: item.cardName + ' *' + item.cardNum,
                value: item.id,
                logo: iconCard,
            };
        });
        setOptions(cardOptions);
    };

    const getLinkAccounts = () => {
        getLinkedAccounts(jwtToken).then(response => {
            setLinkAccounts(response);
            normalizeCardOptions(response, setLinkedAccountOptions);
        });
    };

    const getCashAccounts = () => {
        getCardListing(jwtToken, PaymentMethod.CASH_CARD, true).then(
            response => {
                //Remove after support transfer for SA card
                const mappedData = response.filter(
                    val => val.paymentMethod !== PaymentMethod.SA,
                );
                setCashAccounts(mappedData);
                normalizeCardOptions(mappedData, setCashAccountOptions);
            },
        );
    };

    useEffect(() => {
        getCashAccounts();
        getLinkAccounts();
        setIsTransferPage(location.pathname === PAGE.TRANSFER);
    }, []);

    useEffect(() => {
        if (sessionId && transferInfo?.kountUrl) {
            kount.loadEvent(`${transferInfo.kountUrl}&s=${sessionId}`);
        }
        setIsConfirmPage(transferInfo && sessionId)
    }, [sessionId, transferInfo]);

    const onSubmit = async data => {
        const remitterInfo = getCardInfoById(getRemitterAccounts(), data.from);
        setRemitterInfo(remitterInfo);
        const beneficiaryInfo = getCardInfoById(cashAccounts, data.to);
        setBeneficiaryInfo(beneficiaryInfo);
        const remitterCard = getCardInfoById(getRemitterAccounts(), data.from);
        const newTransferInfo = await enrichTransferInfo(
            data.amount,
            remitterCard.paymentMethod,
        );
        const sessionId = v4().toString().replaceAll(/-/g, '');
        if (newTransferInfo?.kountUrl) {
            await kount.clean(`${newTransferInfo?.kountUrl}`);
        }
        setSessionId(sessionId);
    };

    const getCardInfoById = (data, id) => data.find(card => card.id === id);

    function getRemitterAccounts() {
        return location.pathname === PAGE.TRANSFER
            ? cashAccounts
            : linkAccounts;
    }

    const onConfirm = async data => {
        setIsSubmit(true);
        const remitterInfo = getCardInfoById(getRemitterAccounts(), data.from);
        const beneficiaryInfo = getCardInfoById(cashAccounts, data.to);
        const body = {
            remitterWalletSessionId: remitterInfo.cardToken || remitterInfo.id,
            remitterCardType: remitterInfo.paymentMethod,
            beneficiaryWalletSessionId: beneficiaryInfo.id,
            beneficiaryCardType: beneficiaryInfo.paymentMethod,
            sessionId: sessionId,
            memo: data.memo,
            amount: data.amount,
        };

        const fallbackResponse = {
            transferFrom: remitterInfo.cardName + ' *' + remitterInfo.cardNum,
            transferTo:
                beneficiaryInfo.cardName + ' *' + beneficiaryInfo.cardNum,
            amount: theme.numericFormatted.prefix + data.amount,
            fee: transferInfo?.formattedTotalFee,
            memo: data.memo,
            isSuccess: false,
        };
        await transferMoney(jwtToken, body, fallbackResponse).then(
            response => {
                if (response?.isSuccess) {
                    setIsSuccess(true);
                    setIsFailed(false);
                    setTransactionId(response.transactionId);
                } else {
                    if (location.pathname === PAGE.LOAD) {
                        setIsFailed(true);
                        setTransactionId(response?.transactionId);
                        setExtraInfo3SP(response?.extraInfo3SP);
                        setErrorMsg({
                            title: t('popup.additional-information'),
                            content: '',
                            extraInfo: response?.extraInfo3SP,
                        });
                    } else {
                        setErrorMsg({
                            title: '',
                            content: t('add-funds.error.error-message-content'),
                            extraInfo: '',
                        });
                        setIsShowPopup(true);
                    }
                }
                setIsSubmit(false);
            },
        );
    };

    const onBackClick = () => {
        setIsSubmit(false);
        setIsFailed(false);
        setTransactionId('');
        if (sessionId) {
            kount.clean(`${transferInfo?.kountUrl}`);
            setTransferInfo(null);
            setSessionId(null);
        } else {
            navigate(PAGE.DASHBOARD);
        }
    };

    const enrichTransferInfo = async (amount, remitterPaymentMethod) => {
        return getTransferInfo(jwtToken, amount, remitterPaymentMethod).then(
            data => {
                if (data !== null) {
                    setTransferInfo(data);
                } else {
                    setErrorMsg({
                        title: t(''),
                        content: t('error-message.common'),
                    });
                    setIsShowPopup(true);
                }
                return data;
            },
        );
    };

    function getAccountOptions() {
        return location.pathname === PAGE.TRANSFER
            ? cashAccountOptions
            : linkedAccountOptions;
    }

    return (
        <Container sx={containerStyle}>
            {isShowPopup && (
                <Popup
                    open={isShowPopup}
                    title={errorMsg.title}
                    content={errorMsg.content}
                    viewDetails={errorMsg.extraInfo}
                    hideViewDetailsButton={true}
                    onClose={() => {
                        setIsShowPopup(false);
                    }}
                    onAgree={() => {
                        setIsShowPopup(false);
                    }}
                />
            )}
            <TitleTransferFormComponent location={location} t={t} />
            <form
                onSubmit={handleSubmit(isConfirmPage ? onConfirm : onSubmit)}
                style={formStyle(theme)}
            >
                {isConfirmPage && (
                    <Box sx={boxContainerStyle}>
                        <TitleConfirmTransferFormComponent
                            dir={dir}
                            t={t}
                            isSuccess={isSuccess}
                            isFailed={isFailed}
                            onBackClick={onBackClick}
                        />
                        <IconResultTransferFormComponent
                            t={t}
                            isSuccess={isSuccess}
                            isFailed={isFailed}
                        />
                        <Box sx={boxTransferStyle(theme)}>
                            {location.pathname === PAGE.LOAD ? (
                                <Typography sx={loadTitleStyle(theme)}>
                                    {t('transfer.you-load')}
                                </Typography>
                            ) : (
                                <Typography sx={loadTitleStyle(theme)}>
                                    {t('transfer.you-transfer')}
                                </Typography>
                            )}
                            <Typography sx={formattedAmountStyle}>
                                {formattedAmount}
                            </Typography>
                        </Box>
                        <Box sx={boxInfoStyle}>
                            <Box
                                sx={{
                                    ...boxInfoItemStyle,
                                    ...boxFromStyle(isTransferPage),
                                }}
                            >
                                <FromToTitle
                                    t={t}
                                    theme={theme}
                                    isFrom={true}
                                    isTransferPage={isTransferPage}
                                    isConfirmPage={isConfirmPage}
                                />
                                <Typography>
                                    {remitterInfo.cardName +
                                        ' *' +
                                        remitterInfo.cardNum}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    ...boxInfoItemStyle,
                                    ...boxToStyle(isTransferPage),
                                }}
                            >
                                <FromToTitle
                                    t={t}
                                    theme={theme}
                                    isFrom={false}
                                    isTransferPage={isTransferPage}
                                    isConfirmPage={isConfirmPage}
                                />
                                <Typography>
                                    {beneficiaryInfo.cardName +
                                        ' *' +
                                        beneficiaryInfo.cardNum}
                                </Typography>
                            </Box>
                            {transactionId && (
                                <Box sx={{ ...boxInfoItemStyle, order: 3 }}>
                                    <Typography sx={titleInfoStyle(theme)}>
                                        {t('transfer-status.transaction-ID')}
                                    </Typography>
                                    <Typography>{transactionId}</Typography>
                                </Box>
                            )}
                            <Box sx={{ ...boxInfoItemStyle, order: 4 }}>
                                <Typography sx={titleInfoStyle(theme)}>
                                    {t('transfer.tab.transfer-money.fee')}
                                </Typography>
                                <Typography>
                                    {transferInfo?.formattedTotalFee}
                                </Typography>
                            </Box>
                            <Box sx={{ ...boxInfoItemStyle, order: 5 }}>
                                <Typography sx={titleInfoStyle(theme)}>
                                    {t('transfer.tab.transfer-money.memo')}
                                </Typography>
                                <Typography sx={memoStyle}>
                                    {getValues('memo')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
                {!isConfirmPage && (
                    <Box sx={boxContainerStyle}>
                        <Box
                            sx={{
                                ...boxItemStyle,
                                ...boxFromStyle(isTransferPage),
                            }}
                        >
                            <FromToTitle
                                t={t}
                                theme={theme}
                                isFrom={true}
                                isTransferPage={isTransferPage}
                                isConfirmPage={isConfirmPage}
                            />
                            <Controller
                                name="from"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <CardAccountCustomComboBox
                                        defaultValue={getValues('from')}
                                        disabled={
                                            getAccountOptions()?.length === 0
                                        }
                                        label={t(
                                            'transfer.tab.transfer-money.choose-a-card',
                                        )}
                                        options={getAccountOptions()}
                                        isSvgImage={true}
                                        onChangeValue={e => {
                                            field.onChange(e.target.value);
                                        }}
                                    />
                                )}
                            />
                            <TransferFromErrorMessageComponent
                                t={t}
                                errors={errors}
                                isTransferPage={isTransferPage}
                            />
                        </Box>
                        <Box
                            sx={{
                                ...boxItemStyle,
                                ...boxToStyle(isTransferPage),
                            }}
                        >
                            <FromToTitle
                                t={t}
                                theme={theme}
                                isFrom={false}
                                isTransferPage={isTransferPage}
                                isConfirmPage={isConfirmPage}
                            />
                            <Controller
                                name="to"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: () =>
                                        getValues('from') !== getValues('to'),
                                }}
                                render={({ field }) => (
                                    <CardAccountCustomComboBox
                                        defaultValue={getValues('to')}
                                        label={t(
                                            'transfer.tab.transfer-money.choose-a-card',
                                        )}
                                        disabled={
                                            cashAccountOptions?.length === 0
                                        }
                                        options={cashAccountOptions}
                                        isSvgImage={true}
                                        onChangeValue={e =>
                                            field.onChange(e.target.value)
                                        }
                                    />
                                )}
                            />
                            <TransferToErrorMessageComponent
                                t={t}
                                errors={errors}
                                isTransferPage={isTransferPage}
                            />
                        </Box>
                        <Box sx={{ ...boxItemStyle, ...boxAmountStyle }}>
                            <Typography>
                                {t('transfer.tab.transfer-money.amount')}{' '}
                                <span style={requiredStyle(theme)}>*</span>
                            </Typography>
                            <Controller
                                name="amount"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: {
                                        largerThanZero: v =>
                                            v > CONSTANTS.AMOUNT_GREATER_THAN,
                                    },
                                }}
                                render={({ field }) => (
                                    <Input
                                        defaultValue={getValues('amount')}
                                        onValueChange={value => {
                                            setFormattedAmount(value);
                                            field.onChange(
                                                value
                                                    .replace(/[\$,]/g, '')
                                                    .replace(
                                                        theme.numericFormatted
                                                            .prefix,
                                                        '',
                                                    ),
                                            );
                                        }}
                                        name="amount-input"
                                        placeholder={t(
                                            'transfer.tab.transfer-money.enter-amount',
                                        )}
                                        inputProps={{
                                            inputComponent: NumericFormatCustom,
                                            inputProps: {
                                                prefix: theme.numericFormatted
                                                    .prefix,
                                                scale: theme.numericFormatted
                                                    .scale,
                                            },
                                        }}
                                    />
                                )}
                            />
                            <AmountErrorMessageComponent
                                t={t}
                                errors={errors}
                            />
                        </Box>
                        <Box sx={{ ...boxItemStyle, ...boxOrderStyle }}>
                            <Typography>
                                {t('transfer.tab.transfer-money.memo')}
                            </Typography>
                            <Controller
                                name="memo"
                                control={control}
                                render={({ field }) => (
                                    <Textarea
                                        maxLength="200"
                                        maxWidth="488px"
                                        defaultValue={getValues('memo')}
                                        onChange={value =>
                                            field.onChange(value)
                                        }
                                        placeholder={t('transfer.enter-memo')}
                                    />
                                )}
                            />
                            <Typography sx={hyperTextStyle(theme)}>
                                {memo.current?.length || '0'}/
                                {CONSTANTS.MEMO_MAX_LENGTH}
                            </Typography>
                        </Box>
                    </Box>
                )}
                <ButtonComponent
                    navigate={navigate}
                    theme={theme}
                    t={t}
                    isConfirmPage={isConfirmPage}
                    setIsShowPopup={setIsShowPopup}
                    isSuccess={isSuccess}
                    isFailed={isFailed}
                    isSubmit={isSubmit}
                    onBackClick={onBackClick}
                    extraInfo3SP={extraInfo3SP}
                />
            </form>
        </Container>
    );
};

const NumericFormatCustom = React.forwardRef(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={values => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.formattedValue,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                allowNegative={false}
                decimalScale={other.scale}
                prefix={other.prefix}
            />
        );
    },
);

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const TitleTransferFormComponent = ({location, t}) => {
    return (
        location.pathname === PAGE.LOAD ? (
            <Typography sx={titleStyle}>{t('wallet-info.load')}</Typography>
        ) : (
            <Typography sx={titleStyle}>
                {t('wallet-info.transfer')}
            </Typography>
        )
    );
};

const TitleConfirmTransferFormComponent = ({
    dir,
    t,
    isSuccess,
    isFailed,
    onBackClick,
}) => {
    return (
        !isSuccess &&
        !isFailed && (
            <Box sx={boxTitleStyle}>
                <CustomSVG dir={dir} src={arrowLeft} onClick={onBackClick} />
                <Typography sx={confirmTitleStyle}>
                    {t('transfer.confirm-information')}
                </Typography>
            </Box>
        )
    );
};

const FromToTitle = ({ t, theme, isFrom, isTransferPage, isConfirmPage }) => {
    const getTitle = () => {
        if (isFrom) {
            return isTransferPage
                ? t(CONSTANTS.LOCALIZATION.TRANSFER_FROM)
                : t(CONSTANTS.LOCALIZATION.SOURCE_OF_FUND);
        } else {
            return isTransferPage
                ? t(CONSTANTS.LOCALIZATION.TRANSFER_TO)
                : t(CONSTANTS.LOCALIZATION.LOAD_TO);
        }
    };
    return (
        <Typography sx={isConfirmPage ? titleInfoStyle(theme) : null}>
            {getTitle()}
            {!isConfirmPage && <span style={requiredStyle(theme)}> *</span>}
        </Typography>
    );
};

const IconResultTransferFormComponent = ({ t, isSuccess, isFailed }) => {
    return (
        <>
            {isSuccess && (
                <Box sx={boxSuccessStyle}>
                    <img
                        style={imageStyle}
                        src={resourceUrl('icons/successful-icon.svg')}
                        alt={t('transfer-status.successful')}
                    />
                    <Typography sx={successfulTitleStyle}>
                        {t('transfer-status.successful')}
                    </Typography>
                </Box>
            )}
            {isFailed && (
                <Box sx={boxSuccessStyle}>
                    <img
                        style={imageStyle}
                        src={resourceUrl('icons/failed.svg')}
                        alt={t('transfer-status.failed')}
                    />
                    <Typography sx={successfulTitleStyle}>
                        {t('transfer-status.failed')}
                    </Typography>
                </Box>
            )}
        </>
    );
};

const TransferFromErrorMessageComponent = ({ t, errors, isTransferPage }) => {
    return (
        errors.from?.type === 'required' && (
            <Typography sx={inlineErrorStyle}>
                {t('validation.required', {
                    field: isTransferPage
                        ? t(CONSTANTS.LOCALIZATION.TRANSFER_FROM)
                        : t(CONSTANTS.LOCALIZATION.SOURCE_OF_FUND),
                })}
            </Typography>
        )
    );
};

const TransferToErrorMessageComponent = ({ t, errors, isTransferPage }) => {
    return (
        (errors.to?.type === 'required' || errors.to?.type === 'validate') && (
            <Typography sx={inlineErrorStyle}>
                {t(
                    `validation.${errors.to?.type === 'required' ? 'required' : 'invalid'}`,
                    {
                        field: isTransferPage
                            ? t(CONSTANTS.LOCALIZATION.TRANSFER_TO)
                            : t(CONSTANTS.LOCALIZATION.LOAD_TO),
                    },
                )}
            </Typography>
        )
    );
};

const AmountErrorMessageComponent = ({ t, errors }) => {
    return (
        (errors.amount?.type === 'required' ||
            errors.amount?.type === 'largerThanZero') && (
            <Typography sx={inlineErrorStyle}>
                {t(
                    errors.amount?.type === 'required'
                        ? 'validation.required'
                        : 'validation.invalid',
                    {
                        field: t('transfer.tab.transfer-money.amount'),
                    },
                )}
            </Typography>
        )
    );
};

const ButtonComponent = ({
    theme,
    t,
    navigate,
    isConfirmPage,
    setIsShowPopup,
    isSuccess,
    isFailed,
    isSubmit,
    onBackClick,
    extraInfo3SP,
}) => {
    return (
        <>
            {isFailed && (
                    <Box sx={boxFailed}>
                        <Typography sx={descStyle(theme)}>
                            {t('add-funds.error.error-message-content')}
                            {extraInfo3SP && (
                                <>
                                    {' '}
                                    <span
                                        style={viewDetailsStyle(theme)}
                                        onClick={() => setIsShowPopup(true)}
                                    >
                                        {t('button.view-details')}
                                    </span>
                                </>
                            )}
                        </Typography>
                    </Box>
                )}
                {!isSuccess && !isFailed && (
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmit}
                        loading={isSubmit}
                        fullWidth={true}
                        label={
                            isConfirmPage
                                ? t('transfer.tab.transfer-money.confirm')
                                : t('button.continue')
                        }
                    />
                )}
                {isFailed && (
                    <Button
                        onClick={onBackClick}
                        variant="contained"
                        fullWidth={true}
                        label={t('button.try-again')}
                    />
                )}
                {isSuccess && (
                    <Button
                        onClick={() => navigate(PAGE.DASHBOARD)}
                        variant="contained"
                        fullWidth={true}
                        label={t('button.ok')}
                    />
                )}
        </>
    );
};

export default TransferForm;

