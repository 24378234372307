/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Container } from '@mui/material';
import React from 'react';
import TransactionList from 'components/transaction-list/TransactionList';
import SearchBox from 'components/search-box/SearchBox';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const containerStyle = {
    mt: '100px',
    mb: '60px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '1008px',
};

const TERM_FIELD = 'term';
const Transactions = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const searchTransactions = async (data) => {
        setSearchParams({term: data})
    };

    const term = searchParams.get(TERM_FIELD);
    return (
        <Container sx={containerStyle}>
            <SearchBox
                placeholder={`${t('wallet-info.search')}...`}
                searchAction={searchTransactions}
                defaultValue={term}
            />
            <TransactionList
                pageSize={20}
                isShowPagination={true}
                isShowHeader={true}
                isShowBtnViewMore={false}
                term={term}
            />
        </Container>
    );
};

export default Transactions;
