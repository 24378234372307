/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    width: '588px',
    marginTop: '100px',
};

export const formStyle = (theme) => ({
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    gap: '24px',
    alignSelf: 'stretch',
    borderRadius: '16px',
    border: `1px solid ${theme.appPalette?.grey.grey8}`,
    background: '#FFF',
    boxShadow: '0px 8px 15px 0px rgba(95, 104, 113, 0.12)',
});

export const boxItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
};

export const boxExpiryStyle = (isError) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '50%',
    height: '76px',
    marginBottom: isError ? '24px' : 0,
});

export const requiredStyle = (theme) => ({
    color: theme.appPalette.danger,
});

export const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
};

export const hyperTextStyle = (theme) => ({
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.appPalette?.grey.grey60,
});

export const errorStyle = (theme) => ({
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    color: '#DD1D1D',
});

