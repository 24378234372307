/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { PropTypes } from 'prop-types';

export const paginationPropTypes = {
    defaultPage: PropTypes.number,
    totalPages: PropTypes.number,
    onValueChange: PropTypes.func,
};
