/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const commonStyle = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
};

export const borderRadius = {
    borderRadius: '8px',
};

export const linearProgressMoneyOutStyle = {
    width: '100%',
    '& .MuiLinearProgress-bar': {
        backgroundColor: '#FF77B4',
    },
    '& .MuiLinearProgress-dashedColorPrimary': {
        backgroundImage:
            'radial-gradient(#E3E7EF 0%, #E3E7EF 16%, transparent 42%)',
    },
};

export const linearProgressMoneyInStyle = {
    width: '100%',
    '& .MuiLinearProgress-bar': {
        backgroundColor: '#4D68EB',
    },
    '& .MuiLinearProgress-dashedColorPrimary': {
        backgroundImage:
            'radial-gradient(#E3E7EF 0%, #E3E7EF 16%, transparent 42%)',
    },
};

export const imageStyle = {
    display: 'flex',
    width: '160px',
    height: '160px',
    justifyContent: 'center',
    alignItems: 'center',
};

export const containerStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '24px',
    width: '100%',
};

export const wrapperStyle = {
    display: 'flex',
    minHeight: '492px',
    padding: '48px 64px',
    flexDirection: 'column',
    gap: '30px',
    borderRadius: '12px',
    background: '#FFF',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.20)',
};

export const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    height: '342px',
};

export const boxItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
};

export const boxItem2Style = {
    height: '42px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
};

export const boxEmptyStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '51px',
    paddingTop: '51px',
};

export const threeDotsStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};

export const titleMoneyStyle = (theme) => ({
    color: theme.appPalette?.grey.grey60,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '14px',
    textTransform: 'uppercase',
});

export const balanceStyle = (theme) => ({
    color: theme.appPalette?.grey.grey80,
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '40px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

export const emptyStyle = (theme) => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    color: theme.appPalette?.grey.grey60,
});

export const titleStyle = (theme) => ({
    color: theme.appPalette?.grey.grey80,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    whiteSpace: 'nowrap',
});
