/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React from 'react';
import {
    Table as TableMUI,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { v4 } from 'uuid';
import { StyledPaper } from './Table.style';

const Table = ({ columns, rows }) => {
    return (
        <StyledPaper>
            <TableContainer>
                <TableMUI>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => {
                            const id = v4();
                            return (
                                <TableRow key={id}>
                                    {columns.map(column => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                sx={
                                                    index === rows.length - 1
                                                        ? {
                                                              border: 'none !important',
                                                          }
                                                        : {}
                                                }
                                            >
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </TableMUI>
            </TableContainer>
        </StyledPaper>
    );
};

export default Table;
