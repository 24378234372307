/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import Api from 'configs/api';
import LOCAL_STORAGE from 'constants/localStorage';
import PAGE from 'constants/page';
import { isSuccess } from 'constants/statusCode';
import PropTypes from 'prop-types';
import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiClient from 'services/api-client/apiClient';
import { useLocalStorage  } from 'hooks/useLocalStorage';
import authenticationService from "../services/authentication/authenticationService";

const AuthContext = createContext();

const authProviderPropTypes = {
    children: PropTypes.element,
};
export const AuthProvider = ({ children }) => {
    const [jwtToken, setJwtToken] = useLocalStorage (
        LOCAL_STORAGE.JWT_TOKEN,
        null,
    );
    const [otpNonce, setOtpNonce] = useLocalStorage (
        LOCAL_STORAGE.OTP_NONCE,
        null,
    );
    const [otpExpireDate, setOtpExpireDate] = useLocalStorage (
        LOCAL_STORAGE.OTP_EXPIRE_DATE,
        null,
    );
    const [, setSearch] = useLocalStorage (LOCAL_STORAGE.SEARCH, '');
    const navigate = useNavigate();

    const login = async (data) => {
        const { email, password } = data;
        const response = await authenticationService.login(email.toLowerCase(), password);

        if (response.jwt) {
            setJwtToken(response.jwt);
            navigate(PAGE.DASHBOARD);
        }
        if (response.nonce) {
            setOtpNonce(response.nonce);
            setOtpExpireDate(response.expiry);
            navigate(PAGE.OTP);
        }
        if (response?.code) {
            throw new Error(response.message);
        }
    };

    const logout = () => {
        setJwtToken(null);
        setOtpNonce(null);
        setOtpExpireDate(null);
        setSearch(null);

        navigate(PAGE.LOGIN);
    };

    const verify2FACode = async (code) => {
        const response = await ApiClient.post(Api.VERIFY_OTP, {
            nonce: otpNonce,
            otp: code,
        });
        if (response && isSuccess(response.code)) {
            const { jwtToken } = response.result;
            setOtpNonce(null);
            setOtpExpireDate(null);
            if (jwtToken && jwtToken !== '') {
                setJwtToken(jwtToken);
                navigate(PAGE.DASHBOARD);
            } else {
                navigate('/');
            }
        } else {
            throw new Error(response?.message);
        }
    };

    const value = useMemo(
        () => ({
            jwtToken,
            login,
            logout,
            otpExpireDate,
            otpNonce,
            verify2FACode,
        }),
        [jwtToken, login, logout, otpExpireDate, otpNonce, verify2FACode],
    );

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};
AuthProvider.propTypes = {
    ...authProviderPropTypes,
};
export const useAuth = () => {
    return useContext(AuthContext);
};
