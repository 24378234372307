/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { useEffect, useRef, useState } from 'react';
import { textareaPropTypes } from 'types/textareaPropTypes';
import { StyledTextarea } from './Textarea.style';

const Textarea = ({
    placeholder,
    onChange,
    disabled,
    defaultValue,
    focus,
    readOnly,
    maxLength,
    maxWidth,
}) => {
    const textareaRef = useRef();
    const [value, setValue] = useState('');

    useEffect(() => {
        focus && textareaRef.current?.focus();
    }, [focus]);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleChange = (event) => {
        const value = event.target.value;
        setValue(value);
        onChange(value);
    };

    return (
        <StyledTextarea
            ref={textareaRef}
            value={value}
            readOnly={readOnly}
            disabled={disabled}
            onChange={handleChange}
            maxLength={maxLength}
            placeholder={placeholder}
            style={{ maxWidth }}
        />
    );
};

Textarea.propTypes = {
    ...textareaPropTypes,
};

export default Textarea;

