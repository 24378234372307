/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import Api from 'configs/api';
import { isSuccess } from 'constants/statusCode';
import ApiClient from 'services/api-client/apiClient';

const WalletService = {
    getWalletBalance: async (jwtToken) => {
        try {
            const response = await ApiClient.get(Api.GET_WALLET_BALANCE, {
                headers: {
                    Authorization: 'Bearer ' + jwtToken,
                },
            });
            if (response && isSuccess(response.code)) {
                return response.result;
            }
        } catch (e) {
            return {
                totalAssets: '$0.00',
                availableCash: '$0.00',
            };
        }
    },
    getCreationInfo: async (jwtToken, paymentMethod) => {
        try {
            const response = await ApiClient.get(Api.GET_CREATION_INFO, {
                headers: {
                    Authorization: 'Bearer ' + jwtToken,
                },
                params: {
                    paymentMethod: paymentMethod,
                },
            });
            if (response && isSuccess(response.code)) {
                return response.result;
            }
            return null;
        } catch (e) {
            return null;
        }
    },
    addExternalPaymentMethod: async (jwtToken, body) => {
        try {
            const headers = {
                Authorization: 'Bearer ' + jwtToken,
            };
            const response = await ApiClient.post(
                Api.ADD_EXTERNAL_PAYMENT_METHOD,
                body,
                {
                    ...headers,
                },
            );
            if (response && isSuccess(response.code)) {
                return {
                    ...response,
                    isSuccess: true,
                };
            }
            return response;
        } catch (e) {
            return null;
        }
    },
    getAccountListing: async (jwtToken, paymentMethods) => {
        try {
            const response = await ApiClient.get(Api.GET_ACCOUNT_LISTING, {
                headers: {
                    Authorization: 'Bearer ' + jwtToken,
                },
                params: {
                    paymentMethods: paymentMethods,
                },
            });

            if (response && isSuccess(response.code)) {
                return response.result;
            }

            return [];
        } catch (e) {
            return [];
        }
    },
    getSubsection: async (jwtToken) => {
        try {
            const response = await ApiClient.get(Api.GET_SUBSECTION, {
                headers: {
                    Authorization: 'Bearer ' + jwtToken,
                },
            });

            if (response && isSuccess(response.code)) {
                return response.result;
            }

            return [];
        } catch (e) {
            return [];
        }
    },
    createPaymentAccount: async (jwtToken, body) => {
        try {
            const headers = {
                Authorization: 'Bearer ' + jwtToken,
            };
            const response = await ApiClient.post(
                Api.CREATE_PAYMENT_ACCOUNT,
                body,
                {
                    ...headers,
                },
            );
            return response;
        } catch (e) {
            return null;
        }
    },
    linkBankAccount: async (jwtToken, body) => {
        try {
            const headers = {
                Authorization: 'Bearer ' + jwtToken,
            };
            const response = await ApiClient.post(Api.LINK_BANK_ACCOUNT, body, {
                ...headers,
            });
            return response;
        } catch (e) {
            return null;
        }
    },
    getBankAccountDetail: async (jwtToken, id) => {
        try {
            const response = await ApiClient.get(`${Api.LINK_BANK_ACCOUNT}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + jwtToken,
                },
            });
            return response;
        } catch (e) {
            return null;
        }
    },
};

export default WalletService;

