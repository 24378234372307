/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import PropTypes from "prop-types";

export const actionItemPropTypes = {
    imagePath: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    action: PropTypes.func
};