/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const containerStyle = {
    marginTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    width: '617px',
}