/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { styled } from '@mui/system';
import { ReactSVG } from 'react-svg';
import DIRECTION from 'constants/direction';

export const CustomSVG = styled(ReactSVG)(({ theme, dir }) => ({
    svg: {
        'path[fill]': {
            fill: theme.appPalette?.grey.grey80,
        },
        'path[stroke]': {
            stroke: theme.appPalette?.grey.grey80,
        },
        width: '24px',
        height: '24px',
    },
    lineHeight: 0,
    transform: dir === DIRECTION.RTL ? 'rotate(180deg)' : 'unset',
    cursor: 'pointer',
}));

export const containerStyle = {
    display: 'flex',
    width: '588px',
    flexDirection: 'column',
    alignItems: 'flex-start',//NOSONAR
    gap: '48px',
};

export const titleStyle = {
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '34px',
};

export const formStyle = theme => ({
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    alignSelf: 'stretch',
    borderRadius: '16px',
    border: `1px solid ${theme.appPalette?.grey.grey8}`,
    background: '#FFF',
    boxShadow: '0px 8px 15px 0px rgba(95, 104, 113, 0.12)',
});

export const boxContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
};

export const boxFromStyle = isTransferPage => ({
    order: isTransferPage ? 1 : 2,
});

export const boxToStyle = isTransferPage => ({
    order: isTransferPage ? 2 : 1,
});

export const boxAmountStyle = {
    order: 3,
}

export const boxOrderStyle = {
    order: 4,
}

export const boxItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignSelf: 'stretch',
};

export const inlineErrorStyle = {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    color: '#E84343',
};

export const requiredStyle = theme => ({
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    color: theme.appPalette.danger,
});

export const hyperTextStyle = theme => ({
    display: 'flex',
    justifyContent: 'end',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    color: theme.appPalette?.grey.grey60,
});

export const confirmTitleStyle = {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
};

export const boxTitleStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
};

export const boxTransferStyle = theme => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    gap: '4px',
    paddingBottom: '16px',
    paddingTop: '8px',
    borderBottom: `1px solid ${theme.appPalette?.grey.grey12}`,
});

export const boxInfoStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    alignSelf: 'stretch',
};

export const boxInfoItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
};

export const titleInfoStyle = theme => ({
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    color: theme.appPalette?.grey.grey60,
});

export const memoStyle = {
    textAlign: 'right',
    maxWidth: '304px',
    lineHeight: '18px',
    wordWrap: 'break-word',
};

export const imageStyle = {
    width: '64px',
    height: '64px',
};

export const successfulTitleStyle = {
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '34px',
};

export const boxSuccessStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
};

export const loadTitleStyle = theme => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.appPalette?.grey.grey60,
});

export const boxFailed = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
};

export const descStyle = (theme) => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.appPalette?.grey.grey60,
    textAlign: 'center',
});

export const viewDetailsStyle = (theme) => ({
    color: theme.appPalette?.primary.primary100,
    cursor: 'pointer',
    textDecorationLine: 'underline',
});

export const formattedAmountStyle = {
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '34px',
};

