/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import Grid from '@mui/material/Grid';
import PAGE from "constants/page";
import { useAuth } from "hooks/useAuth";
import { Navigate } from "react-router-dom";
import { loginPropTypes } from 'types/loginPropTypes';
import {
    GridBanner,
    GridContainer,
    formContainerStyle,
    formGroupStyle,
} from './Login.style';
const Login = ({ children }) => {
    const { jwtToken } = useAuth();
    if (jwtToken) {
        return <Navigate to={PAGE.DASHBOARD} />;
    }
    return (
        <GridContainer
            container
            component="main"
            justifyContent="space-between"
            data-testid="login-container"
        >
            <Grid item xs={12} sm={7} md={5}>
                <Grid
                    justifyContent="center"
                    alignItems="center"
                    container
                    sx={formContainerStyle}
                >
                    <Grid sx={formGroupStyle} item xs={9} md={8}>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
            <GridBanner
                item
                xs={false} // Hide on xs screens
                sm={5}
                md={7}
            />
        </GridContainer>
    );
};

Login.propTypes = {
    ...loginPropTypes,
};

export default Login;
