/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Box, Grid, Typography, useTheme } from '@mui/material';
import CardType from 'constants/cardType';
import DIRECTION from 'constants/direction';
import { useTranslation } from 'react-i18next';
import { cardImagePropTypes } from 'types/cardImagePropTypes';
import resourceUrl from '../../resourceResolver';
import {
    bodyContainerStyles,
    bodyContentStyles,
    footerContainerStyles,
    footerGroupStyles,
    cardNameStyle,
    cardNumStyle,
    cardHolderStyle,
    expiryDateStyle,
    boxContainerStyle,
    bodyStyle,
    footerStyle,
    expiryBoxStyle,
    titleStyle,
    footerContentStyles,
    cardContainerStyle,
    endingInStyle,
    backgroundImageStyle,
} from './CardImage.style';

const bankCardBg = resourceUrl('images/bank-card-bg.svg');
const paypalCardBg = resourceUrl('images/paypal-card.svg');
const paypalCardBgRtl = resourceUrl('images/paypal-card-rtl.svg');
const bancoomevaCardBg = resourceUrl('images/bancxxxxxxva-card.svg');
const creditCardBg = resourceUrl('images/credit-card-bg.svg');
const visaCardBg = resourceUrl('images/visa.svg');
const visaCardBgRtl = resourceUrl('images/visa-bg-rtl.svg');
const masterCardBg = resourceUrl('images/master-card.svg');
const masterCardBgRtl = resourceUrl('images/master-card-rtl.svg');
const JCBCardBg = resourceUrl('images/JCB-card.svg');
const JCBCardBgRtl = resourceUrl('images/JCB-card-rtl.svg');
const discoverCardBg = resourceUrl('images/discover-card.svg');
const discoverCardBgRtl = resourceUrl('images/discover-card-rtl.svg');
const dinersClubCardBg = resourceUrl('images/diners-club-card.svg');
const dinersClubCardBgRtl = resourceUrl('images/diners-club-card-rtl.svg');
const kemCardBg = resourceUrl('images/kem-card.svg');
const EBTCardBg = resourceUrl('images/EBT.svg');
const dots = resourceUrl('images/dots.svg');
const sandDollarCardBg = resourceUrl('images/sand-dollar-card.svg');
const saBg = resourceUrl('images/cash-card-coomeva.svg');
const americanExpressCardBgRtl = resourceUrl(
    'images/american-express-card-rtl.svg',
);

const backgroundImage = (dir, theme) => {
    return {
        BANK: bankCardBg,
        BANCOOMEVA: bancoomevaCardBg,
        PAYPAL: dir === DIRECTION.RTL ? paypalCardBgRtl : paypalCardBg,
        CREDIT: creditCardBg,
        VISA: dir === DIRECTION.RTL ? visaCardBgRtl : visaCardBg,
        MASTER_CARD: dir === DIRECTION.RTL ? masterCardBgRtl : masterCardBg,
        JCB: dir === DIRECTION.RTL ? JCBCardBgRtl : JCBCardBg,
        DISCOVER: dir === DIRECTION.RTL ? discoverCardBgRtl : discoverCardBg,
        DINERS_CLUB:
            dir === DIRECTION.RTL ? dinersClubCardBgRtl : dinersClubCardBg,
        KEMP: kemCardBg,
        EBT: EBTCardBg,
        SD: sandDollarCardBg,
        SA: saBg,
        AMERICAN_EXPRESS:
            dir === DIRECTION.RTL
                ? americanExpressCardBgRtl
                : theme.americanExpressCardBg,
    };
};

const CardImage = ({
    cardType,
    cardNum,
    cardEmail,
    cardHolder,
    cardExpiry,
    cardName,
    balance,
    cardNetwork,
    cardCode,
    cardBackgroundImg,
}) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const dir = i18n.dir();
    
    const backgroundUrl = backgroundImage(dir, theme)[cardType] || bankCardBg;
    const bodyContainerStyle =
        bodyContainerStyles[cardType] || bodyContainerStyles.BANK;
    const bodyContentStyle =
        bodyContentStyles[cardType] || bodyContentStyles.BANK;
    const bodyContent = cardType === CardType.BANK ? cardNum : cardEmail;

    const listCardTypes = new Set([
        CardType.VISA,
        CardType.MASTER_CARD,
        CardType.JCB,
        CardType.DISCOVER,
        CardType.DINERS_CLUB,
        CardType.AMERICAN_EXPRESS,
        CardType.CASH_CARD,
        CardType.KEMP,
        CardType.EBT,
        CardType.SA,
        CardType.SD,
    ]);

    const listCardTypesHasEnding = new Set([
        CardType.CASH_CARD,
        CardType.EBT,
        CardType.SA,
        CardType.SD,
    ]);

    return cardBackgroundImg ? (
        renderCardImage(
            t,
            cardBackgroundImg,
            balance,
            cardHolder,
            cardNum,
            cardName,
            dir,
        )
    ) : (
        <>
            {listCardTypes.has(cardType) ? (
                <Box sx={boxContainerStyle}>
                    <CardBackgroundComponent
                        cardType={cardType}
                        cardCode={cardCode}
                        dir={dir}
                        theme={theme}
                    />
                    <CardNameComponent
                        cardType={cardType}
                        cardCode={cardCode}
                        cardName={cardName}
                    />
                    <CardBalanceComponent
                        cardType={cardType}
                        balance={balance}
                    />
                    <CardNumberComponent
                        cardType={cardType}
                        cardNum={cardNum}
                    />
                    <Box sx={footerStyle}>
                        <Typography sx={cardHolderStyle}>
                            {cardHolder}
                        </Typography>
                        {listCardTypesHasEnding.has(cardType) ? (
                            <Box sx={expiryBoxStyle}>
                                <Typography
                                    sx={{
                                        ...expiryDateStyle,
                                        ...endingInStyle,
                                    }}
                                >
                                    {t('card-information.ending-in')}
                                </Typography>
                                <Typography sx={cardHolderStyle}>
                                    {cardNum}
                                </Typography>
                            </Box>
                        ) : (
                            <Box sx={expiryBoxStyle}>
                                <Typography sx={expiryDateStyle}>
                                    {t('card-information.expiry-date')}
                                </Typography>
                                <Typography sx={cardHolderStyle}>
                                    {cardExpiry}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : (
                <Grid
                    container
                    sx={cardContainerStyle(backgroundUrl, cardType)}
                >
                    <Grid item xs={12}>
                        <Typography sx={titleStyle}>{cardName}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={bodyContainerStyle}>
                        <Typography sx={bodyContentStyle}>
                            {bodyContent}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <CardFooterComponent
                            t={t}
                            theme={theme}
                            cardType={cardType}
                            cardHolder={cardHolder}
                            cardExpiry={cardExpiry}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

const renderCardImage = (
    t,
    cardBackgroundImg,
    balance,
    cardHolder,
    cardNum,
    cardName,
    dir,
) => {
    return (
        <Box sx={boxContainerStyle}>
            <Typography sx={cardNameStyle(dir)}>{cardName}</Typography>
            <img style={backgroundImageStyle} src={cardBackgroundImg} alt="" />
            <Box sx={{ ...bodyStyle, justifyContent: 'flex-start' }}>
                <Typography sx={cardNumStyle}>{balance}</Typography>
            </Box>
            <Box sx={footerStyle}>
                <Typography sx={cardHolderStyle}>{cardHolder}</Typography>
                <Box sx={expiryBoxStyle}>
                    <Typography
                        sx={{
                            ...expiryDateStyle,
                            ...endingInStyle,
                        }}
                    >
                        {t('card-information.ending-in')}
                    </Typography>
                    <Typography sx={cardHolderStyle}>{cardNum}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

CardImage.propTypes = {
    ...cardImagePropTypes,
};

const CardBackgroundComponent = ({ cardType, cardCode, dir, theme }) => {
    return (
        <>
            {cardType === CardType.CASH_CARD ? (
                <>
                    {cardCode === CardType.KEMP || cardCode === CardType.EBT ? (
                        <img
                            style={backgroundImageStyle}
                            src={backgroundImage(dir, theme)[cardCode]}
                            alt={cardCode}
                        />
                    ) : (
                        <img
                            style={backgroundImageStyle}
                            src={theme.cashCardBg}
                            alt={cardType}
                        />
                    )}
                </>
            ) : (
                <img
                    src={backgroundImage(dir, theme)[cardType]}
                    alt={cardType}
                />
            )}
        </>
    );
};

const CardNameComponent = ({ cardType, cardCode, cardName, dir }) => {
    return (
        <>
            {cardCode !== CardType.KEMP &&
                cardCode !== CardType.EBT &&
                cardType !== CardType.SD && (
                    <Typography sx={cardNameStyle(dir)}>{cardName}</Typography>
                )}
        </>
    );
};

const CardBalanceComponent = ({ cardType, balance }) => {
    return (
        <>
            {(cardType === CardType.CASH_CARD ||
                cardType === CardType.SA ||
                cardType === CardType.SD) && (
                <Box sx={{ ...bodyStyle, justifyContent: 'flex-start' }}>
                    <Typography sx={cardNumStyle}>{balance}</Typography>
                </Box>
            )}
        </>
    );
};

const CardNumberComponent = ({ cardType, cardNum }) => {
    return (
        <>
            {cardType !== CardType.CASH_CARD &&
                cardType !== CardType.SA &&
                cardType !== CardType.SD && (
                    <Box sx={bodyStyle}>
                        <img src={dots} alt="dots" />
                        <img src={dots} alt="dots" />
                        <img src={dots} alt="dots" />
                        <Typography sx={cardNumStyle}>{cardNum}</Typography>
                    </Box>
                )}
        </>
    );
};

const CardFooterComponent = ({
    t,
    theme,
    cardType,
    cardHolder,
    cardExpiry,
}) => {
    const footerContainerStyle =
        footerContainerStyles[cardType] || footerContainerStyles.BANK;
    const footerGroupStyle =
        footerGroupStyles[cardType] || footerGroupStyles.BANK;
    const footerContentStyle =
        footerContentStyles(theme)[cardType] || footerContentStyles(theme).BANK;
    return (
        <Grid container item sx={footerContainerStyle}>
            {cardType === CardType.BANK && (
                <Grid
                    container
                    item
                    xs={4.5}
                    spacing={0.5}
                    direction="column"
                    sx={footerGroupStyle}
                >
                    <Grid item>
                        <Typography sx={footerContentStyle}>
                            {t('card-information.card-holder')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            sx={{
                                ...footerContentStyle,
                                opacity: '1',
                            }}
                        >
                            {cardHolder}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {cardType === CardType.BANK && (
                <Grid
                    container
                    item
                    xs={4.5}
                    spacing={0.5}
                    direction="column"
                    sx={footerGroupStyle}
                >
                    <Grid item>
                        <Typography sx={footerContentStyle}>
                            {t('card-information.expiry-date')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            sx={{
                                ...footerContentStyle,
                                opacity: '1',
                            }}
                        >
                            {cardExpiry}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {cardType === CardType.BANCOOMEVA && (
                <Grid container item sx={footerGroupStyle}>
                    <Grid item>
                        <Typography sx={footerContentStyle}>
                            {cardHolder}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {cardType === CardType.CREDIT && (
                <Grid container item sx={footerGroupStyle}>
                    <Grid item>
                        <Typography sx={footerContentStyle}>
                            {cardHolder}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default CardImage;

