/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { PropTypes } from 'prop-types';

export const transactionListPropTypes = {
    pageSize: PropTypes.number,
    term: PropTypes.string,
    isShowPagination: PropTypes.bool,
    isShowHeader: PropTypes.bool,
    isShowBtnViewMore: PropTypes.bool,
};
