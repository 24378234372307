/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Stack, useTheme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import React, {useState, useEffect} from 'react';
import {
    listItemStyle,
    listItemButtonStyle,
    primaryTypographyProps,
    buttonContentContainerStyle,
    imageBoxStyle,
    CustomSVG,
} from './PaymentMethodItem.style';
import { paymentMethodItemPropTypes } from 'types/paymentMethodItemPropTypes';
import { useTranslation } from 'react-i18next';

const CustomListItem = styled(ListItem)(() => listItemStyle);

const CustomListItemButton = styled(ListItemButton)(() => listItemButtonStyle);

const ButtonContentContainer = styled(Stack)(() => buttonContentContainerStyle);

const ImageBox = styled(Stack)(() => imageBoxStyle);
export default function PaymentMethodItem({
    prefixIcon,
    value,
    suffixIcon,
    onClick,
}) {
    const theme = useTheme();
    const { i18n } = useTranslation();
    const dir = i18n.dir();
    return (
        <CustomListItem disablePadding onClick={onClick}>
            <CustomListItemButton role={undefined} dense>
                <ButtonContentContainer>
                    <Stack direction="row" alignItems="center" gap="8px">
                        <ImageBox>
                            <CustomSVG src={prefixIcon} primary={'true'} />
                        </ImageBox>
                        <ListItemText
                            primary={value}
                            primaryTypographyProps={primaryTypographyProps(
                                theme,
                            )}
                        />
                    </Stack>
                    <CustomSVG dir={dir} src={suffixIcon} />
                </ButtonContentContainer>
            </CustomListItemButton>
        </CustomListItem>
    );
}

PaymentMethodItem.defaultProps = {
    suffixIcon: 'images/next.svg',
};

PaymentMethodItem.propTypes = {
    ...paymentMethodItemPropTypes,
};

