/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const inputPropTypes = {
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onValueChange: PropTypes.func,
    defaultValue: PropTypes.string,
    defaultFocus: PropTypes.bool,
    name: PropTypes.string,
};