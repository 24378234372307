/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import {
    containerStyle,
    CustomSVG,
    boxStyle,
    titleStyle,
    boxContainerStyle,
} from './BankAccount.style';
import Button from 'components/button/Button';
import resourceUrl from 'resourceResolver';
import { useTranslation } from 'react-i18next';
import BankAccountListing from 'components/bank-account-listing/BankAccountListing';
import BankAccountForm from 'components/bank-account-form/BankAccountForm';

const BankAccount = () => {
    const { t } = useTranslation();
    const [isAddBankAccount, setIsAddBankAccount] = useState(false);
    return (
        <Container sx={containerStyle}>
            {!isAddBankAccount ? (
                <Box sx={boxContainerStyle}>
                    <Box sx={boxStyle}>
                        <Typography sx={titleStyle}>
                            {t('bank-account.linked-bank-accounts')}
                        </Typography>
                        <Button
                            label={t('bank-account.add-bank-account')}
                            variant="contained"
                            startIcon={
                                <CustomSVG src={resourceUrl('icons/add.svg')} />
                            }
                            onClick={() => setIsAddBankAccount(true)}
                        ></Button>
                    </Box>
                    <BankAccountListing />
                </Box>
            ) : (
                <BankAccountForm
                    onClick={() => setIsAddBankAccount(false)}
                    setIsAddBankAccount={() => setIsAddBankAccount(false)}
                />
            )}
        </Container>
    );
};

export default BankAccount;
