/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const TRANSFER_STATE = {
    LOAD: 'load',
    TRANSFER: 'transfer',
    WITHDRAW: 'withdraw',
};

