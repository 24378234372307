import TENANT from 'constants/tenant';

export const Configuration = {
    // Static resouces baseURL, remember to end it with a slash (/)
        staticResourcesBaseURL: 'https://resource.demo.bankingcore.kardinc.com/',
    /**
     * DEV-OPS concern
     */
    baseURL: 'https://api.demo.bankingcore.kardinc.com',
    tenants: [
        {
            domain: 'demo.kanoo.bankingcore.kardinc.com',
            code: TENANT.KANOO,
        },
        {
            domain: 'demo.coomeva.bankingcore.kardinc.com',
            code: TENANT.COOMEVA,
        },
        {
            domain: 'demo.bankingcore.kardinc.com',
            code: TENANT.COOMEVA,
        },
        {
            domain: 'demo.hyt.bankingcore.kardinc.com',
            code: 'hytapp',
        },
        {
            domain: 'demo.kard.bankingcore.kardinc.com',
            code: '1004',
        },
        {
            domain: 'demo.uae.bankingcore.kardinc.com',
            code: '1008',
        },
        {
            domain: 'demo.japan.bankingcore.kardinc.com',
            code: '1009',
        }
    ],
};