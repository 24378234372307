/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import DIRECTION from 'constants/direction';

export const componentStyle = (theme, dir) => ({
    backgroundColor: theme.appPalette.grey.white,
    padding: '16px',
    border: 1,
    borderColor: 'white',
    borderRadius: '10px',
    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexShrink: 0,
    height: '104px',
    direction: dir,
    justifyContent: 'space-between',
    alignItems: 'center',
});

export const titleStyle = (theme, dir) => ({
    color: theme.appPalette.grey.grey80,
    fontSize: '16px',
    marginBottom: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    maxHeight: '20px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: dir === DIRECTION.RTL ? 'right' : 'left',
});

export const contentContainerStyle = (theme) => ({
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    height: '42px',
});

export const contentStyle = (theme, dir) => ({
    fontSize: '12px',
    color: theme.appPalette.grey.grey60,
    letterSpacing: '0px',
    fontWeight: 400,
    lineHeight: '14px',
    marginInlineStart: '4px',
    maxHeight: '42px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    textAlign: dir === DIRECTION.RTL ? 'right' : 'left',
});

export const contentHeaderStyle = (theme, dir) => ({
    color: theme.appPalette.grey.grey80,
    fontSize: '24px',
    fontStyle: 'normal',
    display: 'flex',
    justifyContent: 'left',
    marginInlineStart: '4px',
    fontWeight: 600,
    lineHeight: '28px',
    textAlign: dir === DIRECTION.RTL ? 'right' : 'left',
});

export const imageContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
};

export const imageStyle = {
    width: '64px',
    minWidth: '64px',
    height: '64px',
    minHeight: '64px',
    borderRadius: '15px',
};

export const tooltipStyle = (theme, dir) => ({
    transform: 'translateX(-56px) !important',
    color: theme.appPalette.grey.white,
    backgroundColor: theme.appPalette.grey.grey80,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    padding: '8px',
    marginTop: '20px !important',
    textAlign: dir === DIRECTION.RTL ? 'right' : 'left',
});

