/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Configuration } from './configs/environment';

export default function resourceUrl(resource) {
    const baseUrl = Configuration.staticResourcesBaseURL;

    return baseUrl + resource;
}
