/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { AppBar, useTheme } from '@mui/material';
import React from 'react';
import {headerStyle, logoStyle} from './Header.style';
const Header = () => {
    const theme = useTheme();
    return (
        <AppBar position="fixed" sx={[headerStyle(theme)]}>
            <img src={theme.headerLogo} alt="Logo" style={logoStyle(theme)} />
        </AppBar>
    );
};

const headerPropTypes = {};

Header.propTypes = {
    ...headerPropTypes,
};

Header.defaultProps = {};

export default Header;
