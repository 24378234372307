/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import resourceUrl from 'resourceResolver';
import { getCardListing } from 'services/accounting/accountingService';
import { useAuth } from 'hooks/useAuth';
import {
    imgStyle,
    boxContainerStyle,
    titleStyle,
    boxNoAccountsFoundStyle,
    noAccountsFoundStyle,
} from './CardListing.style';
import { useTranslation } from 'react-i18next';
import SUBSECTION from 'constants/subsection';
import PAYMENT_METHOD from 'constants/paymentMethod';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import CardBackground from 'components/card-background/CardBackground';

const kemCardBg = resourceUrl('images/kem-card.svg');
const EBTCardBg = resourceUrl('images/EBT.svg');
const sandDollarCardBg = resourceUrl('images/sand-dollar-card.svg');
const saBg = resourceUrl('images/usd-saving-card.svg');
const unemploymentCard = resourceUrl('images/unemployment-card.png');

const backgroundImages = {
    SAND_DOLLAR: sandDollarCardBg,
    SAVING: saBg,
    1001: kemCardBg,
    1002: unemploymentCard,
    1000: EBTCardBg,
};

const CardSlider = () => {
    const [data, setData] = useState([]);
    const [fetched, setFetched] = useState(false);
    const theme = useTheme();
    const { jwtToken } = useAuth();
    const { t, i18n } = useTranslation();
    const getCardBgImage = (item) => {
        return item.backgroundUrl
            ? item.backgroundUrl
            : item.ssCategory === SUBSECTION.KASH
              ? backgroundImages[item.branchCode] || theme.cashCardBg
              : backgroundImages[item.ssCategory];
    };

    const getCardName = (item) => {
        let cardName;
        // Display card name for saving card and cash card whose background
        // image does not contain a name
        if (
            item.ssCategory === SUBSECTION.SAVING ||
            (item.ssCategory === SUBSECTION.KASH &&
                !backgroundImages[item.branchCode])
        ) {
            cardName = item.cardName;
        }
        return cardName;
    };

    useEffect(() => {
        getCardListing(
            jwtToken,
            `${PAYMENT_METHOD.CASH_CARD},${PAYMENT_METHOD.SAND_DOLLAR}`,
        ).then((response) => {
            setFetched(true);
            setData(response);
        });
    }, []);

    const dir = i18n.dir();

    return (
        <Box sx={boxContainerStyle(theme)}>
            <Typography sx={titleStyle(theme)}>
                {t('card-listing.title')}
            </Typography>
            {(!data || data.length === 0) && fetched ? (
                <Box sx={boxNoAccountsFoundStyle(theme)}>
                    <img
                        style={imgStyle}
                        src={theme.noAccountsFound}
                        alt={t('card-listing.no-cash-card-found')}
                    />
                    <Typography sx={noAccountsFoundStyle(theme)}>
                        {t('card-listing.no-cash-card-found')}
                    </Typography>
                </Box>
            ) : (
                <Swiper
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                        dynamicMainBullets: 22,
                    }}
                    modules={[Pagination]}
                >
                    {data?.map((item) => (
                        <SwiperSlide key={item.id}>
                            <CardBackground
                                cardBackgroundImg={getCardBgImage(item)}
                                cardNum={item.cardNum}
                                cardName={getCardName(item)}
                                cardHolder={item.cardHolder}
                                balance={item.balance}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </Box>
    );
};

export default CardSlider;

