/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

// Styled components
export const GridContainer = styled(Grid)(({ theme }) => ({
    height: '100vh',
    backgroundColor: theme.appPalette?.primary.primary4,
}));

export const GridBanner = styled(Grid)(({ theme }) => ({
    backgroundImage: `url(${theme.backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    textAlign: 'center',
    display: { xs: 'none', sm: 'flex' }, // Hide on xs screens
}));

// Custom styles
export const formContainerStyle = {
    height: '100%',
};

export const formGroupStyle = {
    minHeight: '497px',
};
