/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { PropTypes } from 'prop-types';

export const shoppingNetworkItemPropTypes = {
    title: PropTypes.string,
    contentHeader: PropTypes.string,
    content: PropTypes.string,
    imagePath: PropTypes.string,
};
