/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

const INPUT_FORMAT = {
    ALPHABETICAL_CHARACTER_SPACE: {
        name: 'ALPHABETICAL_CHARACTER_SPACE',
        pattern: /^[a-zA-Z\s]*$/,
    },
    NUMERIC: {
        name: 'NUMERIC',
        pattern: /^\d*$/,
    },
    NUMERIC_HYPHEN: {
        name: 'NUMERIC_HYPHEN',
        pattern: /^[\d-]*$/,
    },
    CARD_NUMBER_FORMAT: {
        name: 'CARD_NUMBER_FORMAT'
    },
};

export default INPUT_FORMAT;
