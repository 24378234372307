/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React from 'react';
import { useTheme, Alert, Box, Typography } from '@mui/material';
import {
    StyledSnackbar,
    Icon,
    ActionIcon,
    titleStyle,
    contentStyle,
    boxStyle,
} from './ToastMessage.style';
import {toastMessagePropTypes} from 'types/toastMessagePropTypes';

const ToastMessage = ({
    isSuccess,
    isWarning,
    isInformation,
    isError,
    open,
    handleClose,
    title,
    content,
    maxWidth,
}) => {
    const theme = useTheme();
    return (
        <StyledSnackbar
            open={open}
            autoHideDuration={title ? 3500 : 2000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            maxwidth={maxWidth}
            title={title}
        >
            <Alert
                icon={
                    <Icon
                        isSuccess={isSuccess}
                        isError={isError}
                        isWarning={isWarning}
                        isInformation={isInformation}
                    />
                }
                action={<ActionIcon handleClose={handleClose} />}
            >
                <Box sx={boxStyle(title)}>
                    <Typography sx={titleStyle(theme)}>{title}</Typography>
                    <Typography sx={contentStyle(theme, title)}>
                        {content}
                    </Typography>
                </Box>
            </Alert>
        </StyledSnackbar>
    );
};

ToastMessage.propTypes = {
    ...toastMessagePropTypes
}

export default ToastMessage;
