/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { styled } from '@mui/material/styles';
import { CircularProgress, Button } from '@mui/material';
import DIRECTION from 'constants/direction';

export const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
};

export const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    '&.MuiCircularProgress-root': {
        width: '24px !important',
        height: '24px !important',
        color: theme.appPalette?.grey.grey20,
    },
}));

export const buttonStyle = {
    fontStyle: 'normal',
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: 600,
    borderRadius: '12px',
    boxShadow: 'none',
};

export const StyledButton = styled(Button)(({dir}) => ({
    '& .MuiButton-endIcon': {
        marginInlineEnd: '-4px',
        marginInlineStart: '8px',
        transform: (dir === DIRECTION.RTL && 'rotate(180deg)') || 'unset',
    },
    '& .MuiButton-startIcon': {
        marginInlineStart: '-4px',
        marginInlineEnd: '8px',
        transform: (dir === DIRECTION.RTL && 'rotate(180deg)') || 'unset',
    }
}))
