/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { createTheme } from '@mui/material';
import TENANT from 'constants/tenant';
import resourceUrl from './resourceResolver';
import { themeColors } from './themeColors';

const CONSTANTS = {
    IMAGES: {
        AMERICAN_EXPRESS_CARD: 'images/american-express-card.svg',
    },
    LANGUAGES_SUPPORTED: {
        ENGLISH: {
            CODE: 'en',
            VALUE: 'English',
        },
        ARABIC: {
            CODE: 'ar',
            VALUE: 'Arabic',
        },
        SPANISH: {
            CODE: 'es',
            VALUE: 'Spanish',
        },
        PORTUGUESE: {
            CODE: 'pt',
            VALUE: 'Portuguese',
        },
        PORTUGUESE_BRAZIL: {
            CODE: 'pt-br',
            VALUE: 'Portuguese(Brazil)',
        },
    },
};

export function resolveTheme(tenant) {
    const tenantTheme = tenantThemes.find(
        (pallete) => pallete.tenant === tenant,
    );

    const fontFamily = tenantTheme.font.fontFamily;
    const theme = createTheme({
        appPalette: {},
        typography: {
            fontFamily: fontFamily,
            body1: {
                color: tenantTheme.appPalette?.grey.grey80,
                fontWeight: 600,
                fontSize: 14,
            },
        },
        components: {
            MuiTouchRipple: {
                styleOverrides: {
                    root: {
                        display: 'none',
                    },
                },
            },
        },
        headerLogo: tenantTheme.headerLogo,
        rewardAccount: tenantTheme.rewardAccount,
        numericFormatted: tenantTheme.numericFormatted,
    });

    theme.palette.primary = tenantTheme.primary;
    theme.appPalette = tenantTheme.appPalette;
    theme.palette.action = {
        ...theme.palette.action,
        ...tenantTheme.action,
    };
    theme.backgroundImage = tenantTheme.backgroundImage;
    theme.logoImage = tenantTheme.logoImage;
    theme.logoWidth = tenantTheme.logoWidth;
    theme.headerShadow = tenantTheme.headerShadow;
    theme.noResult = tenantTheme.noResult;
    theme.noDataFound = tenantTheme.noDataFound;
    theme.noAccountsFound = tenantTheme.noAccountsFound;
    theme.noPersonsFound = tenantTheme.noPersonsFound;
    theme.emptyPage = tenantTheme.emptyPage;
    theme.cashCardBg = tenantTheme.cashCardBg;
    theme.americanExpressCardBg = tenantTheme.americanExpressCardBg;
    theme.cashCardIcon = tenantTheme.cashCardIcon;
    theme.languageSupported = tenantTheme.languageSupported;

    setFavicon(tenantTheme.favIcon);
    setTitle(tenantTheme.title);
    return theme;
}

function setFavicon(favIconUrl) {
    const head = document.getElementsByTagName('head')[0];
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.type = 'image/x-icon';
    favicon.href = favIconUrl;
    head.appendChild(favicon);
}

function setTitle(title) {
    document.title = title;
}

const tenantThemes = [
    {
        tenant: TENANT.KANOO,
        rewardAccount: {
            // Label for PointNetworkItem.jsx
            poweredBy: 'Kanoo',
        },
        numericFormatted: {
            scale: 2,
            prefix: '$',
        },
        title: 'Kanoo',
        favIcon: resourceUrl('favicons/kanoo.png'),
        logoImage: resourceUrl('icons/kanoo-logo.svg'),
        headerLogo: resourceUrl('images/header-kanoo-logo.svg'),
        logoWidth: '104px',
        headerShadow: '0px 0px 10px 0px rgba(137, 125, 141, 0.30)',
        backgroundImage: resourceUrl('images/login-background-kanoo.png'),
        noResult: resourceUrl('icons/no-result-kanoo.svg'),
        noDataFound: resourceUrl('icons/result-not-found-kanoo.svg'),
        noAccountsFound: resourceUrl('icons/no-accounts-found-kanoo.svg'),
        noPersonsFound: resourceUrl('icons/no-persons-found-kanoo.svg'),
        emptyPage: resourceUrl('icons/empty-page-kanoo.svg'),
        cashCardBg: resourceUrl('images/cash-card-kanoo.svg'),
        cashCardIcon: resourceUrl('icons/cash-card-kanoo.svg'),
        americanExpressCardBg: resourceUrl(CONSTANTS.IMAGES.AMERICAN_EXPRESS_CARD),
        ...getColorConfig(TENANT.KANOO),
        languageSupported: [
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.ENGLISH.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.ENGLISH.VALUE,
                isBase: true,
            },
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.ARABIC.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.ARABIC.VALUE,
                isBase: false,
            },
        ],
    },
    {
        tenant: TENANT.COOMEVA,
        rewardAccount: {
            poweredBy: 'HYT',
        },
        numericFormatted: {
            scale: 0,
            prefix: '$',
        },
        title: 'Bancxxxxxxva',
        favIcon: resourceUrl('favicons/coomeva.png'),
        logoImage: resourceUrl('icons/Coomeva-logo.svg'),
        headerLogo: resourceUrl('images/header-coomeva-logo.svg'),
        logoWidth: '93px',
        headerShadow: 'none',
        backgroundImage: resourceUrl('images/login-background-Coomeva.png'),
        noResult: resourceUrl('icons/no-result-coomeva.svg'),
        noDataFound: resourceUrl('icons/result-not-found-coomeva.svg'),
        noAccountsFound: resourceUrl('icons/no-accounts-found-coomeva.svg'),
        noPersonsFound: resourceUrl('icons/no-persons-found-coomeva.svg'),
        emptyPage: resourceUrl('icons/empty-page-coomeva.svg'),
        cashCardBg: resourceUrl('images/cash-card-coomeva.svg'),
        cashCardIcon: resourceUrl('icons/cash-card-kard.svg'),
        americanExpressCardBg: resourceUrl(CONSTANTS.IMAGES.AMERICAN_EXPRESS_CARD),
        ...getColorConfig(TENANT.COOMEVA),
        languageSupported: [
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.ENGLISH.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.ENGLISH.VALUE,
                isBase: false,
            },
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.SPANISH.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.SPANISH.VALUE,
                isBase: true,
            },
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE.VALUE,
                isBase: false,
            },
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE_BRAZIL.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE_BRAZIL.VALUE,
                isBase: false,
            },
        ],
    },
    {
        tenant: TENANT.GEORGIA,
        rewardAccount: {
            poweredBy: 'GEORGIA',
        },
        numericFormatted: {
            scale: 0,
            prefix: '$',
        },
        title: 'Georgia',
        favIcon: resourceUrl('favicons/georgia.png'),
        logoImage: resourceUrl('icons/georgia-logo.svg'),
        headerLogo: resourceUrl('images/header-georgia-logo.svg'),
        logoWidth: '177px',
        headerShadow: '#897D8D4D',
        backgroundImage: resourceUrl('images/login-background-georgia.png'),
        noResult: resourceUrl('icons/no-result-coomeva.svg'),
        noDataFound: resourceUrl('icons/result-not-found-coomeva.svg'),
        noAccountsFound: resourceUrl('icons/no-accounts-found-coomeva.svg'),
        noPersonsFound: resourceUrl('icons/no-persons-found-coomeva.svg'),
        emptyPage: resourceUrl('icons/empty-page-coomeva.svg'),
        cashCardBg: resourceUrl('images/cash-card-georgia.svg'),
        americanExpressCardBg: resourceUrl('images/georgia_card.svg'),
        ...getColorConfig(TENANT.GEORGIA),
        languageSupported: [
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.ENGLISH.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.ENGLISH.VALUE,
                isBase: true,
            },
        ],
    },
    {
        tenant: TENANT.HYT,
        rewardAccount: {
            poweredBy: 'HYT',
        },
        numericFormatted: {
            scale: 0,
            prefix: '$',
        },
        title: 'HYT',
        favIcon: resourceUrl('favicons/hyt.svg'),
        logoImage: resourceUrl('icons/hyt-logo.svg'),
        headerLogo: resourceUrl('images/header-hyt-logo.svg'),
        logoWidth: '64px',
        headerShadow: '#897D8D4D',
        backgroundImage: resourceUrl('images/login-background-hyt.svg'),
        noResult: resourceUrl('icons/no-result-hyt.svg'),
        noDataFound: resourceUrl('icons/result-not-found-hyt.svg'),
        noAccountsFound: resourceUrl('icons/no-accounts-found-hyt.svg'),
        noPersonsFound: resourceUrl('icons/no-persons-found-hyt.svg'),
        emptyPage: resourceUrl('icons/empty-page-hyt.svg'),
        cashCardBg: resourceUrl('images/cash-card-hyt.svg'),
        cashCardIcon: resourceUrl('icons/cash-card-hyt.svg'),
        americanExpressCardBg: resourceUrl(CONSTANTS.IMAGES.AMERICAN_EXPRESS_CARD),
        ...getColorConfig(TENANT.HYT),
        languageSupported: [
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.ENGLISH.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.ENGLISH.VALUE,
                isBase: false,
            },
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.SPANISH.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.SPANISH.VALUE,
                isBase: true,
            },
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE.VALUE,
                isBase: false,
            },
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE_BRAZIL.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE_BRAZIL.VALUE,
                isBase: false,
            },
        ],
    },
    {
        tenant: TENANT.KARD,
        rewardAccount: {
            poweredBy: 'KARD',
        },
        numericFormatted: {
            scale: 0,
            prefix: '$',
        },
        title: 'KARD',
        favIcon: resourceUrl('favicons/kard.svg'),
        logoImage: resourceUrl('icons/kard-logo.svg'),
        headerLogo: resourceUrl('images/header-kard-logo.svg'),
        logoWidth: '64px',
        headerShadow: '#897D8D4D',
        backgroundImage: resourceUrl('images/login-background-kard.svg'),
        noResult: resourceUrl('icons/no-result-kard.svg'),
        noDataFound: resourceUrl('icons/result-not-found-kard.svg'),
        noAccountsFound: resourceUrl('icons/no-accounts-found-kard.svg'),
        noPersonsFound: resourceUrl('icons/no-persons-found-kard.svg'),
        emptyPage: resourceUrl('icons/empty-page-kard.svg'),
        cashCardBg: resourceUrl('images/cash-card-kard.svg'),
        cashCardIcon: resourceUrl('icons/cash-card-kard.svg'),
        americanExpressCardBg: resourceUrl(CONSTANTS.IMAGES.AMERICAN_EXPRESS_CARD),
        ...getColorConfig(TENANT.KARD),
        languageSupported: [
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.ENGLISH.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.ENGLISH.VALUE,
                isBase: true,
            },
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.SPANISH.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.SPANISH.VALUE,
                isBase: false,
            },
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE.VALUE,
                isBase: false,
            },
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE_BRAZIL.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE_BRAZIL.VALUE,
                isBase: false,
            },
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.ARABIC.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.ARABIC.VALUE,
                isBase: false,
            },
        ],
    },
    {
        tenant: TENANT.UAE,
        rewardAccount: {
            poweredBy: 'KardOne',
        },
        numericFormatted: {
            scale: 0,
            prefix: 'Dhs',
        },
        title: 'KardOne',
        favIcon: resourceUrl('favicons/uae.svg'),
        logoImage: resourceUrl('icons/uae-logo.svg'),
        headerLogo: resourceUrl('images/header-uae-logo.svg'),
        logoWidth: '100px',
        headerShadow: '#897D8D4D',
        backgroundImage: resourceUrl('images/login-background-uae.svg'),
        noResult: resourceUrl('icons/no-result-uae.svg'),
        noDataFound: resourceUrl('icons/result-not-found-uae.svg'),
        noAccountsFound: resourceUrl('icons/no-accounts-found-uae.svg'),
        noPersonsFound: resourceUrl('icons/no-persons-found-uae.svg'),
        emptyPage: resourceUrl('icons/empty-page-uae.svg'),
        cashCardBg: resourceUrl('images/cash-card-uae.svg'),
        cashCardIcon: resourceUrl('icons/cash-card-uae.svg'),
        americanExpressCardBg: resourceUrl(CONSTANTS.IMAGES.AMERICAN_EXPRESS_CARD),
        ...getColorConfig(TENANT.UAE),
        languageSupported: [
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.ARABIC.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.ARABIC.VALUE,
                isBase: true,
            },
        ],
    },
    {
        tenant: TENANT.JAPAN,
        rewardAccount: {
            poweredBy: 'Japan Demo',
        },
        numericFormatted: {
            scale: 0,
            prefix: '¥',
        },
        title: 'Japan Demo',
        favIcon: resourceUrl('favicons/japan.svg'),
        logoImage: resourceUrl('icons/japan-logo.svg'),
        headerLogo: resourceUrl('images/header-japan-logo.svg'),
        logoWidth: '64px',
        headerShadow: '#897D8D4D',
        backgroundImage: resourceUrl('images/login-background-japan.svg'),
        noResult: resourceUrl('icons/no-result-japan.svg'),
        noDataFound: resourceUrl('icons/result-not-found-japan.svg'),
        noAccountsFound: resourceUrl('icons/no-accounts-found-japan.svg'),
        noPersonsFound: resourceUrl('icons/no-persons-found-japan.svg'),
        emptyPage: resourceUrl('icons/empty-page-japan.svg'),
        cashCardBg: resourceUrl('images/cash-card-japan.svg'),
        cashCardIcon: resourceUrl('icons/cash-card-japan.svg'),
        americanExpressCardBg: resourceUrl(CONSTANTS.IMAGES.AMERICAN_EXPRESS_CARD),
        ...getColorConfig(TENANT.JAPAN),
        languageSupported: [
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.ENGLISH.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.ENGLISH.VALUE,
                isBase: true,
            },
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.SPANISH.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.SPANISH.VALUE,
                isBase: false,
            },
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE.VALUE,
                isBase: false,
            },
            {
                code: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE_BRAZIL.CODE,
                language: CONSTANTS.LANGUAGES_SUPPORTED.PORTUGUESE_BRAZIL.VALUE,
                isBase: false,
            },
        ],
    },
];

function getColorConfig(tenant) {
    return themeColors.find((color) => color.tenant === tenant);
}

