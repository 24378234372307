/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const boxContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    width: '588px',
};

export const boxItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
};

export const errorStyle = (theme) => ({
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    color: theme.appPalette?.danger,
});

export const requiredStyle = (theme) => ({
    color: theme.appPalette.danger,
});

export const formStyle = (theme) => ({
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    gap: '32px',
    alignSelf: 'stretch',
    borderRadius: '16px',
    border: `1px solid ${theme.appPalette?.grey.grey8}`,
    background: '#FFF',
    boxShadow: '0px 8px 15px 0px rgba(95, 104, 113, 0.12)',
});

export const boxInfoStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
};
