/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import {
    containerStyle,
    CustomSVG,
    boxStyle,
    titleStyle,
    boxContainerStyle,
} from './ManageAccounts.style';
import Button from 'components/button/Button';
import resourceUrl from 'resourceResolver';
import AddCardForm from 'components/add-card-form/AddCardForm';
import { useTranslation } from 'react-i18next';
import AccountsListing from 'components/accounts-listing/AccountsListing';

const ManagaAccounts = () => {
    const { t } = useTranslation();
    const [isAddCard, setIsAddCard] = useState(false);
    return (
        <Container sx={containerStyle}>
            {!isAddCard ? (
                <Box sx={boxContainerStyle}>
                    <Box sx={boxStyle}>
                        <Typography sx={titleStyle}>
                            {t('sidebar.cards')}
                        </Typography>
                        <Button
                            label={t('manage-accounts.add-card')}
                            variant="contained"
                            startIcon={
                                <CustomSVG src={resourceUrl('icons/add.svg')} />
                            }
                            onClick={() => setIsAddCard(true)}
                        ></Button>
                    </Box>
                    <AccountsListing />
                </Box>
            ) : (
                <AddCardForm
                    onClick={() => setIsAddCard(false)}
                    setIsAddCard={() => setIsAddCard(false)}
                />
            )}
        </Container>
    );
};

export default ManagaAccounts;
