/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { PropTypes } from 'prop-types';

export const paymentMethodItemPropTypes = {
    value: PropTypes.string.isRequired,
    prefixIcon: PropTypes.string.isRequired,
    suffixIcon: PropTypes.string,
    onClick: PropTypes.func,
};
