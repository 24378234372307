/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */


import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledH4 = styled(Typography)(({ theme }) => ({
    color: theme.appPalette?.grey.grey80,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
}));


export const StyledBody2 = styled(Typography)(({ theme }) => ({
    color: theme.appPalette?.grey.grey60,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
}));

export const contentGridStyle = {
    padding: '0 20px',
    justifyContent: 'space-between',
    display: 'flex',
    minHeight: '74px',
    alignItems: 'center',
    borderBottom: '1px solid #D6D6D6',
};

export const subContentGridStyle = {
    flexDirection: 'column',
};

export const rightGridStyle = {
    textAlign: 'right',
};