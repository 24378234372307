/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { styled } from '@mui/material/styles';
import { Box, IconButton, Tooltip, tooltipClasses } from '@mui/material';
import { ReactSVG } from 'react-svg';
import { sidebarClasses, menuClasses } from 'react-pro-sidebar';

export const Z_INDEX = 1200;
export const SidebarBox = styled(Box)(({ theme }) => ({
    position: 'sticky',
    zIndex: Z_INDEX,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    top: 0,
}));

export const StyledCollapseButton = styled(IconButton, {
    shouldForwardProp: prop => prop !== 'collapsed',
})(({ theme, collapsed }) => ({
    zIndex: Z_INDEX,
    width: '24px',
    height: '24px',
    position: 'absolute',
    right: '-12px',
    top: '72px',
    padding: '0px',
    backgroundColor: theme.appPalette.primary.primary4,
    borderRadius: '99px',
    transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
    boxShadow: '0px 1px 4px 0px rgba(0, 60, 94, 0.20)',
    svg: {
        path: {
            fill: theme.appPalette.primary.primary100,
        },
    },
    '&:hover': {
        backgroundColor: theme.appPalette.primary.primary100,
        svg: {
            path: {
                fill: theme.appPalette.primary.primary4,
            },
        },
    },
}));

export const SvgIcon = styled(ReactSVG)(() => ({
    lineHeight: 0,
}));

export const SidebarContentContainer = styled(Box)`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
`;

export const menuItemStyles = collapsed => {
    return {
        root: {
            padding: '0 16px',
            width: 'inherit',
        },
        button: {
            [`&`]: {
                width: collapsed ? '40px' : undefined,
            },
        },
    };
};

export const CustomTooltip = styled(Tooltip)(({ theme }) => ({}));

export const tooltipProps = theme => ({
    popper: {
        sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                {
                    marginInlineStart: '5px',
                },
        },
    },
    tooltip: {
        sx: {
            color: '#fff',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '14px',
            padding: '3px 4px',
            borderRadius: '4px',
            backgroundColor: theme.appPalette?.grey.grey80,
        },
    },
});

export const sidebarStyles = {
    height: '100%',
    border: '0',
    [`.${sidebarClasses.container}`]: {
        padding: '100px 0 40px 0',
    },
};

const baseButtonStyles = {
    height: '40px !important',
    padding: '0 !important',
};
export const hoverButtonStyles = theme => ({
    backgroundColor: `${theme.appPalette.primary.primary8} !important`,
    borderRadius: '10px',
    [`.${menuClasses.icon}`]: {
        animation: 'swing ease-in-out 0.5s 1 alternate',
    },
});
export const iconStyles = {
    width: '40px',
    minWidth: '40px',
    marginInlineStart: '12px',
    marginInlineEnd: '-2px',
    opacity: 1,
};
export const hoverSelectedButtonStyles = {
    backgroundColor: 'transparent !important',//NOSONAR
    cursor: 'default',
    [`.${menuClasses.icon}`]: {
        animation: 'none',
    },
};
export const activeIconStyles = theme => ({
    [`.${menuClasses.prefix}.ps-active > div div svg path[fill="#55585E"]`]: {
        fill: theme.appPalette?.primary.primary100,
    },
    [`.${menuClasses.prefix}.ps-active > div div svg path[stroke="#55585E"]`]: {
        stroke: theme.appPalette?.primary.primary100,
    },
    [`.${menuClasses.prefix} > div div svg path[fill="#55585E"]`]: {
        fill: theme.appPalette.grey.grey80,
    },
    [`.${menuClasses.prefix} > div div svg path[stroke="#55585E"]`]: {
        stroke: theme.appPalette.grey.grey80,
    },
});
export const rootSubMenuStyles = theme => ({
    [`.${menuClasses.button}`]: baseButtonStyles,
    [`.${menuClasses.button}:hover`]: hoverButtonStyles(theme),
    [`.${menuClasses.button}.ps-active:hover`]: hoverSelectedButtonStyles,
    [`.${menuClasses.prefix}`]: iconStyles,
    [`.${menuClasses.button}:focus`]: { outline: 'none' },
    [`.${menuClasses.SubMenuExpandIcon}`]: { display: 'none' },
    [`.${menuClasses.open}`]: {
        backgroundColor: 'transparent !important',
    },
    [`.${menuClasses.suffix}`]: {
        display: 'flex',
    },
    [`.${menuClasses.suffix}.ps-open .MuiSvgIcon-root`]: {
        transform: 'rotate(180deg)',
    },
    [`.${menuClasses.label} > .MuiTypography-body1`]: {
        marginInlineStart: '48px',
    },
    [`.${menuClasses.subMenuContent}.${menuClasses.label}`]: {
        overflow: 'visible',
    },
    [`.${menuClasses.subMenuContent}`]: {
        background: 'transparent !important',
    },
    [`.${menuClasses.subMenuContent}.css-wlra1l`]: {
        marginInlineStart: '10px !important',//NOSONAR
        padding: '10px',
    },
    [`.${menuClasses.subMenuContent}.css-1ilvxxa`]: {
        marginInlineStart: '10px !important',
        padding: '10px',
    },
    [`.${menuClasses.subMenuContent}.css-1ilvxxa`]: {
        marginLeft: '10px !important',
        padding: '10px',
    },
    [`.${menuClasses.subMenuContent} a`]: {
        width: '100%',
    },
    [`.${menuClasses.subMenuContent} li`]: {
        padding: '0 !important',
        marginInlineStart: '3px',
    },
    ...activeIconStyles(theme),
});
