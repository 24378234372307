/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const OverflowTip = ({ title, tooltipStyle, textStyle }) => {
    const textRef = useRef(null);
    const [isOverflowed, setIsOverflowed] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            const element = textRef.current;
            setIsOverflowed(
                element.scrollWidth > element.clientWidth ||
                    element.scrollHeight > element.clientHeight,
            );
        };

        handleResize(); // Check initially
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [title]);

    return (
        <Tooltip
            title={title}
            disableHoverListener={!isOverflowed}
            placement='bottom-start'
            componentsProps={{
                tooltip: {
                    sx: tooltipStyle,
                },
            }}
        >
            <Typography ref={textRef} sx={textStyle}>
                {title}
            </Typography>
        </Tooltip>
    );
};
const overflowTipPropTypes = {
    title: PropTypes.string,
    tooltipStyle: PropTypes.object,
    textStyle: PropTypes.object,
};

OverflowTip.propTypes = {
    ...overflowTipPropTypes,
};
export default OverflowTip;
