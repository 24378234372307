/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const btnStyles = {
    position: 'absolute',
    top: '45%',
    zIndex: 1,
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
};

export const iconStyle = {
    width: '16px',
    height: '16px',
};

export const btnArrowLeftStyle = {
    left: '-6px',
};

export const btnArrowRightStyle = {
    right: '-10px',
};

export const carouselItemStyles = {
    margin: '0 16px',
    padding: '8px 0',
};

export const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 992 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 992, min: 576 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1,
    },
};

export const networkBoxStyle = {margin: "0 -16px"};