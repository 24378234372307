/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { PropTypes } from 'prop-types';

export const toastMessagePropTypes = {
    isSuccess: PropTypes.bool,
    isWarning: PropTypes.bool,
    isInformation: PropTypes.bool,
    isError: PropTypes.bool,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    title: PropTypes.string,
    content: PropTypes.string,
    maxWidth: PropTypes.string,
}