/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Box, Tab as TabMUI, Tabs, useTheme } from '@mui/material';
import React, { useState } from 'react';
import {
    indicatorStyle,
    labelStyle,
    overrideLabelStyle,
    minHeight,
} from 'components/tab/Tab.style';
import { tabPropTypes } from '../../types/tabPropTypes';

const Tab = ({ tabs, onTabChange }) => {
    const theme = useTheme();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (onTabChange) {
            onTabChange(newValue);
        }
    };

    return (
        <Box>
            <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                    style: {
                        ...indicatorStyle(theme),
                    },
                }}
                sx={{ ...overrideLabelStyle(theme), ...minHeight }}
            >
                {tabs?.map((tab) => (
                    <TabMUI key={tab} label={tab} sx={labelStyle} />
                ))}
            </Tabs>
        </Box>
    );
};

Tab.propTypes = {
    ...tabPropTypes,
};

export default Tab;
