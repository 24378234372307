/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    boxContainerStyle,
    cardNameStyle,
    backgroundImageStyle,
    bodyStyle,
    cardNumStyle,
    footerStyle,
    cardHolderStyle,
    expiryBoxStyle,
    expiryDateStyle,
    endingInStyle,
} from './CardBackground.style';
import resourceUrl from '../../resourceResolver';
import DIRECTION from 'constants/direction';

const dots = resourceUrl('images/dots.svg');
const paypalCardBg = resourceUrl('images/paypal-card.svg');
const paypalCardBgRtl = resourceUrl('images/paypal-card-rtl.svg');
const visaCardBg = resourceUrl('images/visa.svg');
const visaCardBgRtl = resourceUrl('images/visa-bg-rtl.svg');
const masterCardBg = resourceUrl('images/master-card.svg');
const masterCardBgRtl = resourceUrl('images/master-card-rtl.svg');
const JCBCardBg = resourceUrl('images/JCB-card.svg');
const JCBCardBgRtl = resourceUrl('images/JCB-card-rtl.svg');
const discoverCardBg = resourceUrl('images/discover-card.svg');
const discoverCardBgRtl = resourceUrl('images/discover-card-rtl.svg');
const dinersClubCardBg = resourceUrl('images/diners-club-card.svg');
const dinersClubCardBgRtl = resourceUrl('images/diners-club-card-rtl.svg');
const americanExpressCardBgRtl = resourceUrl(
    'images/american-express-card-rtl.svg',
);

const CardBackground = ({
    cardBackgroundImg,
    balance,
    cardHolder,
    cardNum,
    cardName,
    cardType,
    cardExpiry,
}) => {
    const { t, i18n } = useTranslation();
    const dir = i18n.dir();
    const theme = useTheme();
    const backgroundImage = {
        PAYPAL: dir === DIRECTION.RTL ? paypalCardBgRtl : paypalCardBg,
        VISA: dir === DIRECTION.RTL ? visaCardBgRtl : visaCardBg,
        MASTER_CARD: dir === DIRECTION.RTL ? masterCardBgRtl : masterCardBg,
        JCB: dir === DIRECTION.RTL ? JCBCardBgRtl : JCBCardBg,
        DISCOVER: dir === DIRECTION.RTL ? discoverCardBgRtl : discoverCardBg,
        DINERS_CLUB:
            dir === DIRECTION.RTL ? dinersClubCardBgRtl : dinersClubCardBg,
        AMERICAN_EXPRESS:
            dir === DIRECTION.RTL
                ? americanExpressCardBgRtl
                : theme.americanExpressCardBg,
    };

    return (
        <RenderCardBackground
            cardBackgroundImg={cardBackgroundImg}
            balance={balance}
            cardHolder={cardHolder}
            cardNum={cardNum}
            cardName={cardName}
            t={t}
            dir={dir}
            theme={theme}
            cardType={cardType}
            backgroundImage={backgroundImage}
            dots={dots}
            cardExpiry={cardExpiry}
        />
    );
};

const RenderCardBackground = ({
    cardBackgroundImg,
    balance,
    cardHolder,
    cardNum,
    cardName,
    dir,
    t,
    cardType,
    backgroundImage,
    dots,
    cardExpiry,
}) => {
    return (
        <Box sx={boxContainerStyle}>
            <Typography sx={cardNameStyle(dir)}>{cardName}</Typography>
            {cardType && (
                <>
                    <img
                        style={backgroundImageStyle}
                        src={backgroundImage[cardType]}
                        alt={cardType}
                    />
                    <Box sx={bodyStyle}>
                        <img src={dots} alt="dots" />
                        <img src={dots} alt="dots" />
                        <img src={dots} alt="dots" />
                        <Typography sx={cardNumStyle}>{cardNum}</Typography>
                    </Box>
                </>
            )}
            {!cardType && (
                <>
                    <img
                        style={backgroundImageStyle}
                        src={cardBackgroundImg}
                        alt=""
                    />
                    <Box
                        sx={{
                            ...bodyStyle,
                            justifyContent: 'flex-start',
                            width: '368px',
                            left: '25px',
                        }}
                    >
                        <Typography sx={cardNumStyle}>{balance}</Typography>
                    </Box>
                </>
            )}
            <Box sx={footerStyle}>
                <Typography sx={cardHolderStyle}>{cardHolder}</Typography>
                <Box sx={expiryBoxStyle}>
                    <Typography
                        sx={{
                            ...expiryDateStyle,
                            ...endingInStyle,
                        }}
                    >
                        {cardType && t('card-information.expiry-date')}
                        {!cardType && t('card-information.ending-in')}
                    </Typography>
                    {cardType && (
                        <Typography sx={cardHolderStyle}>
                            {cardExpiry}
                        </Typography>
                    )}
                    {!cardType && (
                        <Typography sx={cardHolderStyle}>{cardNum}</Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default CardBackground;
