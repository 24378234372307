/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { ThemeProvider } from '@mui/material';
import { resolveTenant } from './tenantResolver';
import { resolveTheme } from './theme';
import { AuthProvider } from './hooks/useAuth';
import Routes from './routes/Routes';
import { ToastProvider } from 'contexts/ToastContext';
import I18nProvider from 'contexts/I18nProvider';
import RoutesProvider from 'contexts/RoutesContext';
import { RootColorsProvider } from 'contexts/RootColorsProvider';

function App() {
    addAppVersion();
    return (
        <ThemeProvider theme={resolveTheme(resolveTenant())}>
            <I18nProvider>
                <AuthProvider>
                    <RoutesProvider>
                        <ToastProvider>
                            <RootColorsProvider>
                                <Routes></Routes>
                            </RootColorsProvider>
                        </ToastProvider>
                    </RoutesProvider>
                </AuthProvider>
            </I18nProvider>
        </ThemeProvider>
    );
}

function addAppVersion() {
    const head = document.querySelector('head'); // Access the first element
    if (!head) {
        return;
    }
    const metaVersion = document.createElement('meta');
    metaVersion.name = 'version';
    metaVersion.content = process.env.REACT_APP_VERSION || undefined;
    head.appendChild(metaVersion);
}

export default App;

