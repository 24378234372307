/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageEN from './locales/en/translate.json';
import languageES from './locales/es/translate.json';
import languageAR from './locales/ar/translate.json';
import languagePT from './locales/pt/translate.json';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: languageEN,
            es: languageES,
            ar: languageAR,
            pt: languagePT,
        },
        /* When react i18next not finding any language to as default in borwser */
        fallbackLng: 'en',
        /* debugger For Development environment */
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: '.',
        interpolation: {
            escapeValue: false, // react already safes from xss
            formatSeparator: ',',
        },
    });

export default i18n;

