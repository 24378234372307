/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import TENANT from 'constants/tenant';

const CONSTANTS = {
    FONT_FAMILY: {
       AVENIR_NEXT_LT_PRO : 'Avenir Next LT Pro',
    },
};

export const themeColors = [
    {
        tenant: TENANT.KANOO,
        appPalette: {
            background: {
                header: '#FFFFFF',
            },
            checkbox: {
                disabled: '#F2F0F3',
            },
            grey: {
                black: '#000000',
                grey80: '#422C54',
                grey60: '#958D9C',
                grey40: '#BAB4BF',
                grey20: '#CCC7D0',
                grey12: '#DFDCE1',
                grey8: '#EBE9EC',
                grey4: '#F2F0F3',
                grey2: '#F7F6F8',
                white: '#FFFFFF'
            },
            primary: {
                primary150: '#612974',
                primary100: '#723088',
                primary80: '#8E59A0',
                primary60: '#AA83B8',
                primary40: '#C7ACCF',
                primary20: '#E3D6E7',
                primary12: '#ECE3EF',
                primary8: '#F4EFF6',
                primary4: '#FAF7FA',
            },
            info: '#1E7CD7',
            danger: '#DD1D1D',
            success: '#16BB93',
            warning: '#8F6900',
        },
        primary: {
            main: '#723088',
            light: '#723088',
            dark: '#612974',
            contrastText: '#fff',
        },
        action: {
            disabledBackground: '#ECE3EF',
            disabled: '#CCC7D0',
        },
        font: {
            fontSize: 14,
            fontFamily: CONSTANTS.FONT_FAMILY.AVENIR_NEXT_LT_PRO,
        },
    },
    {
        tenant: TENANT.COOMEVA,
        appPalette: {
            background: {
                header: '#003C5E',
            },
            select: {
                hover: '#EDF5FC',
            },
            checkbox: {
                disabled: '#EDF3F6',
            },
            grey: {
                black: '#000000',
                grey80: '#003C5E',
                grey60: '#8694A3',
                grey40: '#B0BAC3',
                grey20: '#C4CDD3',
                grey12: '#DAE0E3',
                grey8: '#E6ECEF',
                grey4: '#EDF3F6',
                grey2: '#F5F7F9',
                white: '#FFFFFF',
            },
            primary: {
                primary150: '#155889',
                primary100: '#1E7CD7',
                primary80: '#4B96DF',
                primary60: '#78B0E7',
                primary40: '#A5CBEF',
                primary20: '#D2E5E7',
                primary12: '#E0EDFA',
                primary8: '#EDF5FC',
                primary4: '#F6FAFE',
            },
            info: '#1E7CD7',
            danger: '#DD1D1D',
            success: '#16BB93',
            warning: '#8F6900',
        },
        primary: {
            main: '#1E7CD7',
            light: '#1E7CD7',
            dark: '#155899',
            contrastText: '#fff',
        },
        action: {
            disabledBackground: '#E0EDFA',
            disabled: '#C4CDD3',
        },
        font: {
            fontFamily: CONSTANTS.FONT_FAMILY.AVENIR_NEXT_LT_PRO,
            fontSize: 14,
        },
    },
    {
        tenant: TENANT.GEORGIA,
        appPalette: {
            background: {
                header: '#02343D',
            },
            select: {
                hover: '#EDF5FC',
            },
            checkbox: {
                disabled: '#EDF3F6',
            },
            grey: {
                black: '#000000',
                grey80: '#2C5154',
                grey60: '#8D9C99',
                grey20: '#C4CDD3',
                grey12: '#DAE0E3',
                grey8: '#EBE9EC',
                grey2: '#F5F7F9',
                white: '#FFFFFF',
            },
            primary: {
                primary150: '#155889',
                primary100: '#306888',
                primary80: '#4B96DF',
                primary60: '#78B0E7',
                primary12: '#E0EDFA',
                primary8: '#EFF6F6',
                primary4: '#F6FAFE',
            },
            info: '#1E7CD7',
            danger: '#DD1D1D',
            success: '#16BB93',
            warning: '#8F6900',
        },
        primary: {
            main: '#02343D',
            light: '#1E7CD7',
            dark: '#155899',
            contrastText: '#fff',
        },
        action: {
            disabledBackground: '#E0EDFA',
            disabled: '#C4CDD3',
        },
        font: {
            fontFamily: CONSTANTS.FONT_FAMILY.AVENIR_NEXT_LT_PRO,
            fontSize: 14,
        },
    },
    {
        tenant: TENANT.HYT,
        appPalette: {
            background: {
                header: '#FFFFFF',
            },
            select: {
                hover: '#EDF5FC',
            },
            checkbox: {
                disabled: '#EDF3F6',
            },
            grey: {
                black: '#000000',
                grey80: '#003C5E',
                grey60: '#8694A3',
                grey40: '#B0BAC3',
                grey20: '#C4CDD3',
                grey12: '#DAE0E3',
                grey8: '#E6ECEF',
                grey4: '#EDF3F6',
                grey2: '#F5F7F9',
                white: '#FFFFFF',
            },
            primary: {
                primary150: '#283CB3',
                primary100: '#2C42C5',
                primary80: '#5668D1',
                primary60: '#808EDC',
                primary40: '#ABB3E8',
                primary20: '#D5D9F3',
                primary12: '#E2E4F7',
                primary8: '#EEF0FB',
                primary4: '#F7F8FD',
            },
            info: '#1E7CD7',
            danger: '#DD1D1D',
            success: '#16BB93',
            warning: '#8F6900',
        },
        primary: {
            main: '#2C42C5',
            light: '#2C42C5',
            dark: '#283CB3',
            contrastText: '#fff',
        },
        action: {
            disabledBackground: '#E2E4F7',
            disabled: '#C4CDD3',
        },
        font: {
            fontFamily: CONSTANTS.FONT_FAMILY.AVENIR_NEXT_LT_PRO,
            fontSize: 14,
        },
    },
    {
        tenant: TENANT.KARD,
        appPalette: {
            background: {
                header: '#FFFFFF',
            },
            select: {
                hover: '#EDF5FC',
            },
            checkbox: {
                disabled: '#EDF3F6',
            },
            grey: {
                black: '#000000',
                grey80: '#003C5E',
                grey60: '#8694A3',
                grey40: '#B0BAC3',
                grey20: '#C4CDD3',
                grey12: '#DAE0E3',
                grey8: '#E6ECEF',
                grey4: '#EDF3F6',
                grey2: '#F5F7F9',
                white: '#FFFFFF',
            },
            primary: {
                primary150: '#1B71C4',
                primary100: '#1E7CD7',
                primary80: '#4B96DF',
                primary60: '#78B0E7',
                primary40: '#A5CBEF',
                primary20: '#D2E5F7',
                primary12: '#E0EDFA',
                primary8: '#EDF5FC',
                primary4: '#F6FAFE',
            },
            info: '#593C23',
            danger: '#DD1D1D',
            success: '#16BB93',
            warning: '#8F6900',
        },
        primary: {
            main: '#1E7CD7',
            light: '#1E7CD7',
            dark: '#1B71C4',
            contrastText: '#fff',
        },
        action: {
            disabledBackground: '#E0EDFA',
            disabled: '#C4CDD3',
        },
        font: {
            fontFamily: CONSTANTS.FONT_FAMILY.AVENIR_NEXT_LT_PRO,
            fontSize: 14,
        },
    },
    {
        tenant: TENANT.UAE,
        appPalette: {
            background: {
                header: '#FFFFFF',
            },
            select: {
                hover: '#EDF5FC',
            },
            checkbox: {
                disabled: '#EDF3F6',
            },
            grey: {
                black: '#000000',
                grey80: '#003C5E',
                grey60: '#8694A3',
                grey40: '#B0BAC3',
                grey20: '#C4CDD3',
                grey12: '#DAE0E3',
                grey8: '#E6ECEF',
                grey4: '#EDF3F6',
                grey2: '#F5F7F9',
                white: '#FFFFFF',
            },
            primary: {
                primary150: '#1B71C4',
                primary100: '#1E7CD7',
                primary80: '#4B96DF',
                primary60: '#78B0E7',
                primary40: '#A5CBEF',
                primary20: '#D2E5F7',
                primary12: '#E0EDFA',
                primary8: '#EDF5FC',
                primary4: '#F6FAFE',
            },
            info: '#593C23',
            danger: '#DD1D1D',
            success: '#16BB93',
            warning: '#8F6900',
        },
        primary: {
            main: '#1E7CD7',
            light: '#1E7CD7',
            dark: '#1B71C4',
            contrastText: '#fff',
        },
        action: {
            disabledBackground: '#E0EDFA',
            disabled: '#C4CDD3',
        },
        font: {
            fontFamily: CONSTANTS.FONT_FAMILY.AVENIR_NEXT_LT_PRO,
            fontSize: 14,
        },
    },
    {
        tenant: TENANT.JAPAN,
        appPalette: {
            background: {
                header: '#FFFFFF',
            },
            select: {
                hover: '#1B71C4',
            },
            checkbox: {
                disabled: '#EDF3F6',
            },
            grey: {
                black: '#000000',
                grey80: '#003C5E',
                grey60: '#8694A3',
                grey40: '#B0BAC3',
                grey20: '#C4CDD3',
                grey12: '#DAE0E3',
                grey8: '#E6ECEF',
                grey4: '#EDF3F6',
                grey2: '#F5F7F9',
                white: '#FFFFFF',
            },
            primary: {
                primary150: '#1B71C4',
                primary100: '#1E7CD7',
                primary80: '#4B96DF',
                primary60: '#78B0E7',
                primary40: '#A5CBEF',
                primary20: '#D2E5E7',
                primary12: '#E0EDFA',
                primary8: '#EDF5FC',
                primary4: '#F6FAFE',
            },
            info: '#1E7CD7',
            danger: '#DD1D1D',
            success: '#16BB93',
            warning: '#8F6900',
        },
        primary: {
            main: '#1E7CD7',
            light: '#1E7CD7',
            dark: '#1E7CD7',
            contrastText: '#fff',
        },
        action: {
            disabledBackground: '#E2E4F7',
            disabled: '#C4CDD3',
        },
        font: {
            fontFamily: CONSTANTS.FONT_FAMILY.AVENIR_NEXT_LT_PRO,
            fontSize: 14,
        },
    },
];
