/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const boxNameStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
};

export const cardImageStyle = {
    height: '20px',
    width: 'auto',
    borderRadius: '3px'
}

export const boxLoadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    height: '204px',
    alignItems: 'center',
};
