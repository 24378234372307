/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { ReactSVG } from 'react-svg';
import { TextField } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)(({ theme }) => ({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
        {
            borderColor: theme.appPalette?.grey.grey12,
        },
    [`& .${outlinedInputClasses.input}`]: {
        color: theme.appPalette?.grey.grey80,
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
        {
            borderColor: theme.appPalette?.primary.primary60,
        },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
        {
            border: `1px solid ${theme.appPalette?.primary.primary60}`,
        },
    '& .MuiInputBase-input::placeholder': {
        color: theme.appPalette?.grey.grey60,
    },
}));

export const searchBoxStyle = {
    height: '36px',
    fontWeight: 600,
    padding: '8px 12px',
    borderRadius: '8px',
    background: '#FFF',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: 'normal',
};

export const CustomSVG = styled(ReactSVG)(({ theme, issearchicon }) => ({
    svg: {
        'path[stroke]': {
            stroke: issearchicon
                ? theme.appPalette?.grey.grey60
                : theme.appPalette?.grey.grey80,
        },
        'path[fill]': {
            fill: issearchicon
                ? theme.appPalette?.grey.grey60
                : theme.appPalette?.grey.grey80,
        },
    },
    lineHeight: 0,
}));
