/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { styled } from '@mui/material/styles';
import { MenuItem, TextField } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiSelect-select': {
        padding: 0,
        minHeight: 'auto !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiSvgIcon-root': {
        color: theme.appPalette?.grey.grey60,
    },
    '& .Mui-disabled': {
        color: theme.appPalette?.grey.grey20,
        WebkitTextFillColor: `${theme.appPalette?.grey.grey20} !important`,
    },
}));

export const components = {
    MuiPaper: {
        defaultProps: {
            sx: {
                borderRadius: '8px',
                background: '#FFF',
                marginTop: '8px',
                maxHeight: '360px !important',
                '&::-webkit-scrollbar': {
                    width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#DAE0E3',
                    borderRadius: '99px',
                },
            },
        },
    },
    MuiList: {
        defaultProps: {
            sx: {
                padding: '12px 0',
            },
        },
    },
    MuiMenuItem: {
        defaultProps: {
            sx: {
                height: '50px',
                padding: '16px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '18px',
                display: 'flex',
                gap: '8px',
                background: 'transparent !important',
            },
        },
    },
};

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&.MuiMenuItem-root:hover': {
        backgroundColor: theme.appPalette?.primary.primary8 + ' !important',//NOSONAR
    },
    '&.MuiMenuItem-root.Mui-selected': {
        backgroundColor: theme.appPalette?.primary.primary8 + ' !important',
        borderLeft: `4px solid ${theme.appPalette?.primary.primary100} !important`,
    },
    '&.MuiSelected:hover': {
        backgroundColor: theme.appPalette?.primary.primary8 + ' !important',
    },
}));

export const boxContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
};

export const typographyStyle = {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
};

export const fontWeightBold = {
    fontWeight: 'bold',
};
