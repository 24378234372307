/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import resourceUrl from 'resourceResolver';
import {
    boxLoadingStyle,
    boxNameStyle,
    CustomSVG,
} from './BankAccountListing.style';
import { useTranslation } from 'react-i18next';
import Table from 'components/table/Table';
import Loading from 'components/loading/Loading';
import NoDataFound from 'components/no-data-found/NoDataFound';
import WalletService from 'services/wallet/walletService';
import { useAuth } from 'hooks/useAuth';
import PAYMENT_METHOD from 'constants/paymentMethod';
import Menu from 'components/menu/Menu';
import { useNavigate } from 'react-router-dom';
import PAGE from 'constants/page';
import ACTION from 'constants/action';

const BankAccountListing = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const theme = useTheme();
    const { t } = useTranslation();
    const { jwtToken } = useAuth();
    const navigate = useNavigate();

    const columns = [
        {
            id: 'bankName',
            label: t('bank-account.bank-name'),
        },
        {
            id: 'last4Digits',
            label: t('bank-account.bank-account-number'),
        },
        {
            id: 'cardHolderName',
            label: t('manage-accounts.account-holder-name'),
        },
        {
            id: 'action',
            label: t(''),
        },
    ];

    const items = [
        {
            label: t('action.view-detail'),
            icon: resourceUrl('icons/view.svg'),
            action: ACTION.VIEW,
        },
    ];

    const handleAction = (payload) => {
        if (payload.action === ACTION.VIEW) {
            viewDetail(payload.id);
        }
    };

    const viewDetail = (id) => {
        navigate(`${PAGE.LINKED_BANK_ACCOUNTS_DETAIL.replace(':id', id)}`);
    };

    useEffect(() => {
        setLoading(true);
        WalletService.getAccountListing(
            jwtToken,
            `${PAYMENT_METHOD.BANK_TRANSFER}`,
        ).then((response) => {
            const data = response.map((item) => {
                return {
                    bankName: (
                        <Box sx={boxNameStyle}>
                            <CustomSVG src={resourceUrl('icons/bank.svg')} />
                            <Typography noWrap sx={{ width: '228px' }}>
                                {item.bankName}
                            </Typography>
                        </Box>
                    ),
                    last4Digits: (
                        <Box>
                            <Typography noWrap sx={{ width: '228px' }}>••{item.last4Digits}</Typography>
                        </Box>
                    ),
                    cardHolderName: (
                        <Box>
                            <Typography noWrap sx={{ width: '228px' }}>
                                {item.cardHolderName}
                            </Typography>
                        </Box>
                    ),
                    action: (
                        <Menu
                            onClick={(payload) => handleAction(payload)}
                            icon={resourceUrl('icons/more.svg')}
                            items={items.map((value) => {
                                return {
                                    ...value,
                                    id: item.ssId,
                                };
                            })}
                        />
                    ),
                };
            });
            setData(data);
            setLoading(false);
        });
    }, []);

    return (
        <>
            {loading && (
                <Box sx={boxLoadingStyle}>
                    <Loading />
                </Box>
            )}

            {!loading && data.length === 0 && (
                <NoDataFound
                    icon={theme.noAccountsFound}
                    label={t('bank-account.no-account-found')}
                />
            )}

            {!loading && data.length > 0 && (
                <Table columns={columns} rows={data} />
            )}
        </>
    );
};

export default BankAccountListing;
