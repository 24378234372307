/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Box, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { getLinkedAccounts } from 'services/accounting/accountingService';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import {
    imgStyle,
    boxContainerStyle,
    boxNoAccountsFoundStyle,
    titleStyle,
    noAccountsFoundStyle,
} from './CardSlider.style';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import CardBackground from 'components/card-background/CardBackground';

const externalLinkedAccountPropType = {
    data: PropTypes.array,
};

const CardSlider = () => {
    const [data, setData] = useState([]);
    const [fetched, setFetched] = useState(false);
    const theme = useTheme();
    const { jwtToken } = useAuth();
    const { t } = useTranslation();
    useEffect(() => {
        getLinkedAccounts(jwtToken).then((response) => {
            setFetched(true);
            setData(response);
        });
    }, []);

    return (
        <Box sx={boxContainerStyle(theme)}>
            <Typography sx={titleStyle(theme)}>
                {t('card-information.external-linked-accounts')}
            </Typography>
            {(!data || data.length === 0) && fetched ? (
                <Box sx={boxNoAccountsFoundStyle(theme)}>
                    <img
                        style={imgStyle}
                        src={theme.noAccountsFound}
                        alt={t('card-listing.no-linked-account-found')}
                    />
                    <Typography sx={noAccountsFoundStyle(theme)}>
                        {t('card-listing.no-linked-account-found')}
                    </Typography>
                </Box>
            ) : (
                <Swiper
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                        dynamicMainBullets: 22,
                    }}
                    modules={[Pagination]}
                >
                    {data?.map((item) => (
                        <SwiperSlide key={item.id}>
                            <CardBackground
                                cardType={item.cardType}
                                cardNum={item.cardNum}
                                cardName={item.cardName}
                                cardHolder={item.cardHolder}
                                cardExpiry={item.cardExpiry}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </Box>
    );
};

CardSlider.propTypes = {
    ...externalLinkedAccountPropType,
};

export default CardSlider;

