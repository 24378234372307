/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import resourceUrl from 'resourceResolver';
import Button from '../../components/button/Button';
import {
    boxContainerStyle,
    boxWrapperStyle,
    boxItemStyle,
    boxItemInfoStyle,
    flexEndStyle,
    successfulTitleStyle,
    loadedStyle,
    boxItemLoadStyle,
    titleItemStyle,
    valueStyle,
    imageStyle,
    boxInformationStyle,
    descInformationStyle,
    CustomSVG,
} from './TransferStatus.style';
import { useNavigate, useLocation } from 'react-router-dom';
import PAGE from '../../constants/page';
import { useTranslation } from 'react-i18next';

const TransferStatus = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { state } = useLocation();
    const redirectToDashboard = () => {
        navigate(PAGE.DASHBOARD);
    };
    useEffect(() => {
        if (!state) {
            redirectToDashboard();
        }
    }, []);
    return (
        <>
            {state && (
                <Box sx={boxContainerStyle}>
                    <Box sx={boxWrapperStyle}>
                        <img
                            style={imageStyle}
                            src={resourceUrl('icons/successful-icon.svg')}
                            alt="Successful icon"
                        />
                        {!state?.isWithdrawal ? (
                            <Typography sx={successfulTitleStyle(theme)}>
                                {t('transfer-status.successful')}
                            </Typography>
                        ) : (
                            <Typography sx={successfulTitleStyle(theme)}>
                                {t('withdraw.create-successful-request')}
                            </Typography>
                        )}
                        <Box sx={boxItemStyle}>
                            <Box sx={boxItemLoadStyle(theme)}>
                                {!state?.isWithdrawal ? (
                                    <Typography sx={loadedStyle(theme)}>
                                        {t('transfer-status.you-loaded')}
                                    </Typography>
                                ) : (
                                    <Typography sx={loadedStyle(theme)}>
                                        {t('withdraw.withdrawal-amount')}
                                    </Typography>
                                )}
                                <Typography sx={successfulTitleStyle(theme)}>
                                    {state.amount}
                                </Typography>
                            </Box>
                            <Box sx={boxItemInfoStyle}>
                                {state?.isWithdrawal && (
                                    <Box sx={boxInformationStyle(theme)}>
                                        <CustomSVG
                                            src={resourceUrl(
                                                'icons/information-24px.svg',
                                            )}
                                        />
                                        <Typography sx={descInformationStyle}>
                                            {t(
                                                'withdraw.email-notification-message',
                                            )}
                                        </Typography>
                                    </Box>
                                )}
                                <Box sx={flexEndStyle}>
                                    <Typography sx={titleItemStyle(theme)}>
                                        {t('transfer-status.transfer-from')}
                                    </Typography>
                                    <Typography sx={valueStyle(theme)}>
                                        {state.transferFrom}
                                    </Typography>
                                </Box>
                                <Box sx={flexEndStyle}>
                                    <Typography sx={titleItemStyle(theme)}>
                                        {t('transfer-status.transfer-to')}
                                    </Typography>
                                    <Typography sx={valueStyle(theme)}>
                                        {state.transferTo}
                                    </Typography>
                                </Box>
                                <Box sx={flexEndStyle}>
                                    <Typography sx={titleItemStyle(theme)}>
                                        {t('transfer-status.transaction-ID')}
                                    </Typography>
                                    <Typography sx={valueStyle(theme)}>
                                        {state.transactionId}
                                    </Typography>
                                </Box>
                                <Box sx={flexEndStyle}>
                                    <Typography sx={titleItemStyle(theme)}>
                                        {t('transfer-status.fee')}
                                    </Typography>
                                    <Typography sx={valueStyle(theme)}>
                                        {state.fee}
                                    </Typography>
                                </Box>
                                <Box sx={flexEndStyle}>
                                    <Typography sx={titleItemStyle(theme)}>
                                        {t('transfer-status.memo')}
                                    </Typography>
                                    <Typography sx={valueStyle(theme)}>
                                        {state.memo}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Button
                            label={t('button.ok')}
                            variant="contained"
                            onClick={redirectToDashboard}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
};

export default TransferStatus;

