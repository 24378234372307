/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { useState } from 'react';
import { Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { containerStyle } from './Settings.style';
import Tab from 'components/tab/Tab';
import TabPanel from 'components/tab/tab-panel/TabPanel';
import LanguageSelector from 'components/language-selector/LanguageSelector';

const Settings = () => {
    const { t } = useTranslation();
    const [value, setValue] = useState(0);
    const tabs = [t('preferred-language.title')];
    const handleChange = (newValue) => {
        setValue(newValue);
    };
    return (
        <Container sx={containerStyle}>
            <Grid>
                <Tab tabs={tabs} onTabChange={handleChange} />
            </Grid>
            <Grid>
                <TabPanel value={value} index={0}>
                    <LanguageSelector />
                </TabPanel>
            </Grid>
        </Container>
    );
};

export default Settings;
