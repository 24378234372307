/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Box, Typography, useTheme } from '@mui/material';
import ActionGroup from 'components/action-group/ActionGroup';
import React from 'react';
import {
    boxContainerStyle,
    titleStyle,
    valueStyle,
    boxStyle,
    w50PercentStyle,
} from './WalletInfo.style';
import { walletInfoPropTypes } from 'types/walletInfoPropTypes';

const WalletInfo = ({ financialSummary, transferActionGroup }) => {
    const theme = useTheme();
    return (
        <Box sx={boxContainerStyle}>
            <Box sx={boxStyle(theme)}>
                {financialSummary &&
                    financialSummary.map((item) => (
                        <Box key={item.id} sx={w50PercentStyle}>
                            <Typography sx={titleStyle(theme)}>
                                {item.category}
                            </Typography>
                            <Typography sx={valueStyle(theme)}>
                                {item.amount}
                            </Typography>
                        </Box>
                    ))}
            </Box>
            <ActionGroup actionItems={transferActionGroup} width='40%' />
        </Box>
    );
};

WalletInfo.propTypes = {
    ...walletInfoPropTypes,
};

export default WalletInfo;

