/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

const PAYMENT_METHOD = {
    CREDIT_CARD: 'CREDIT_CARD',
    CASH_CARD: 'CASH_CARD',
    SAND_DOLLAR: 'SAND_DOLLAR',
    BANK_TRANSFER: 'BANK_TRANSFER',
    SA: 'SA',
};

export default PAYMENT_METHOD;

