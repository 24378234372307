/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { tabPanelPropTypes } from '../../../types/tabPanelPropTypes';

const TabPanel = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tab-panel-${index}`}
            aria-labelledby={`tab-${index}`}
        >
            {children}
        </div>
    );
};
TabPanel.propTypes = {
    ...tabPanelPropTypes,
};
export default TabPanel;
