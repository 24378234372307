/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const containerStyle = {
    mt: '100px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginBottom: '60px',
    width: '1008px',
};

export const networkLabelStyle = (theme) => {
    return {
        color: theme.appPalette?.grey.grey60,
        fontWeight: 600,
        lineHeight: '18px',
    };
};

export const boxSliderStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
};

export const boxItemSliderStyle = {
    width: '468px',
};

