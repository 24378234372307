/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { styled } from '@mui/material/styles';
import DIRECTION from 'constants/direction';
import { ReactSVG } from 'react-svg';

export const listItemStyle = {
    width: '600px',
    height: '50px',
    borderRadius: '8px',
    background:
        '#FFF',
    boxShadow: '0px 0px 12px 0px #EAEEF3',
    '&:hover': {
        boxShadow: '0px 0px 16px 0px rgba(178, 164, 202, 0.50)',
    },
};

export const listItemButtonStyle = {
    width: 'inherit',
    height: 'inherit',
    borderRadius: 'inherit',
    '&.MuiButtonBase-root': {
        background: 'inherit',
    },
};

export const primaryTypographyProps = (theme) => ({
    color: theme.appPalette?.grey.grey80,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
});

export const buttonContentContainerStyle = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
};

export const imageBoxStyle = {
    flexDirection: 'row',
    justifyContent: 'center',
    width: 32,
    height: 32,
    alignItems: 'center',
};

export const CustomSVG = styled(ReactSVG)(({ theme, primary, dir }) => ({
    svg: {
        'path[fill]': {
            fill: primary ? theme.appPalette?.primary.primary100 : theme.appPalette?.grey.grey60,
        },
        'path[stroke]': {
            stroke: primary ? theme.appPalette?.primary.primary100 : theme.appPalette?.grey.grey60,
        },
    },
    lineHeight: 0,
    transform: dir === DIRECTION.RTL ? 'rotate(180deg) !important' : 'unset', 
}));