/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

/**
 * @description Define style attribute for Textarea component.
 */

import { styled } from '@mui/material/styles';
import TextareaAutosize from 'react-textarea-autosize';
export const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'Avenir Next LT Pro', sans-serif;
  font-size: '14px';
  font-weight: 600;
  line-height: 18px;
  gap: 8px;
  padding: 16px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.appPalette?.grey.grey80};
  border-radius: 8px;
  border: 1px solid ${theme.appPalette?.grey.grey12};
  background: #FFF;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(228, 229, 252, 0.50), 0px 12px 96px 0px rgba(228, 229, 252, 0.12);
  min-height: 50px;
  &:focus {
      outline: none;
      border: 1px solid ${theme.appPalette?.grey.grey12};
      box-shadow: 0px 0px 16px 0px rgba(178, 164, 202, 0.50);
  }

  &:focus-visible {
    outline: 0;
    box-shadow: 0px 0px 16px 0px rgba(178, 164, 202, 0.50);
  }

  &::placeholder {
      color: ${theme.appPalette?.grey.grey60};
  }

  &:disabled {
      background: ${theme.appPalette?.grey.grey8};
      color: ${theme.appPalette?.grey.grey60};
      -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
  }
`,
);

