/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import PropTypes from "prop-types";

export const buttonPropTypes = {
    disabled: PropTypes.bool,
    isPrimary: PropTypes.bool,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
};