/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import Api from 'configs/api';
import { isSuccess } from 'constants/statusCode';
import ApiClient from 'services/api-client/apiClient';

const KOUNT_IFRAME_ID = 'ibody';

class Kount {
    constructor(initializerArgs = []) {
        this._values = [...initializerArgs];
    }

    loadEvent(kountUrl) {
        const script = document.createElement('script');
        script.src = kountUrl;
        document.body.appendChild(script);

        script.onload = () => {
            // eslint-disable-next-line no-undef
            const client = new ka.ClientSDK(); // NOSONAR
            // eslint-disable-next-line no-undef
            client.autoLoadEvents();
        };
    }

    removeKountScript(baseKountUrl) {
        // Get the reference to the script element
        const scripts = document.querySelectorAll('script');
        if (baseKountUrl && scripts) {
            // Check if the script element exists
            for (const element of scripts) {
                const scriptSrc = element.getAttribute('src');
                if (scriptSrc?.startsWith(baseKountUrl)) {
                    // Remove the script element
                    element.parentNode.removeChild(element);
                }
            }
        }
    };

    removeKountIframe() {
        // Get the reference to the iframe element
        const iframes = document.querySelectorAll('iframe');

        // Check if the iframe element exists
        if (iframes) {
            // Remove the iframe element
            for (const element of iframes) {
                const scriptSrc = element.getAttribute('id');
                if (scriptSrc === KOUNT_IFRAME_ID) {
                    // Remove the script element
                    element.parentNode.removeChild(element);
                }
            }
        }
    }

    clean(baseKountUrl) {
        this.removeKountScript(baseKountUrl);
        this.removeKountIframe();
    }
}
const kount = new Kount();
export { kount };

export async function transferMoney(jwtToken, body, fallbackResponse) {
    try {
        const headers = {
            Authorization: 'Bearer ' + jwtToken,
        };
        const response = await ApiClient.post(Api.TRANSFER_MONEY, body, {
            ...headers,
        });

        let mappedData;

        if (response) {
            const data = response.result;
            mappedData = {
                transferFrom: fallbackResponse.transferFrom,
                transferTo: fallbackResponse.transferTo,
                amount: data.formattedAmount,
                fee: data.formattedFee,
                transactionId: data.referenceId,
                memo: data.memo,
                extraInfo3SP: data.extraInfo3SP,
                isSuccess: isSuccess(response.code),
            };
        }

        return {
            ...mappedData,
            message: response?.message,
        };
    } catch (e) {
        return null;
    }
}

export async function getTransferInfo(jwtToken, amount, remitterPaymentMethod) {
    try {
        const response = await ApiClient.get(Api.TRANSFER_INFO, {
            params: {
                amount,
                remitterPaymentMethod,
            },
            headers: {
                Authorization: 'Bearer ' + jwtToken,
            },
        });

        if (response && isSuccess(response.code)) {
            return response.result;
        }

        return null;
    } catch (e) {
        return null;
    }
}

export async function getBranding(jwtToken, subSection) {
    try {
        const response = await ApiClient.get(Api.GET_BRANDING, {
            params: {
                subSection,
            },
            headers: {
                Authorization: 'Bearer ' + jwtToken,
            },
        });

        if (response && isSuccess(response.code)) {
            return response.result;
        }

        return [];
    } catch (e) {
        return [];
    }
}

export async function getWithdrawalRequestInfo(
    jwtToken,
    amount,
    remitterPaymentMethod,
) {
    try {
        const response = await ApiClient.get(Api.GET_WITHDRAWAL_REQUEST_INFO, {
            params: {
                amount,
                remitterPaymentMethod,
            },
            headers: {
                Authorization: 'Bearer ' + jwtToken,
            },
        });

        return response;
    } catch (e) {
        return null;
    }
}

export async function withdrawalRequest(jwtToken, body, fallbackResponse) {
    try {
        const headers = {
            Authorization: 'Bearer ' + jwtToken,
        };
        const response = await ApiClient.post(Api.WITHDRAWAL_REQUEST, body, {
            ...headers,
        });

        let mappedData;

        if (response && isSuccess(response.code)) {
            const data = response.result;
            mappedData = {
                transferFrom: fallbackResponse.transferFrom,
                transferTo: fallbackResponse.transferTo,
                amount: data.formattedAmount,
                fee: data.formattedFee,
                transactionId: data.referenceId,
                memo: data.memo,
                isSuccess: true,
            };
        }

        return {
            ...mappedData,
            message: response?.message,
        };
    } catch (e) {
        return null;
    }
}

