/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React from 'react';
import { StyledCircularProgress } from './Loading.style';
const Loading = () => {
    return <StyledCircularProgress />;
};

export default Loading;
