/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Dialog, DialogContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
export const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        padding: '24px',
        gap: '24px',
        minWidth: '320px',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '12px',
        maxWidth: '520px',
    },
    '& .MuiBackdrop-root': {
        backgroundColor: '#000000 !important',
        opacity: '0.4 !important',
    },
}));

export const disablePadding = { padding: '0' };

export const StyledDialogContent = styled(DialogContent)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
}));

export const DialogTitleTypography = styled(Typography)(() => ({
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '28px',
}));

export const DialogContentTypography = styled(Typography)(() => ({
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
}));

export const viewDetailsContainer = {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
};

export const linkStyle = theme => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.appPalette?.grey.grey80,
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    cursor: 'pointer',
});

export const contentDetailsStyle = theme => ({
    backgroundColor: theme.appPalette?.grey.grey2,
    borderRadius: '8px',
    padding: '16px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
});

export const expandIconStyle = {
    '& .MuiSvgIcon-root': {
        transform: 'rotate(180deg)',
    }
};

