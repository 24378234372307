/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { useRef, useState, useEffect } from 'react';
import { Container, Box, Typography, useTheme } from '@mui/material';
import PageTitle from 'components/page-title/PageTitle';
import {
    containerStyle,
    boxItemStyle,
    hyperTextStyle,
    errorStyle,
    requiredStyle,
    formStyle,
    boxStyle,
    boxExpiryStyle,
} from './CardInformationForm.style';
import { useNavigate, useLocation } from 'react-router-dom';
import PAGE from 'constants/page';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from 'components/input/Input';
import Button from 'components/button/Button';
import DatePicker from 'components/date-picker/DatePicker';
import INPUT_FORMAT from 'constants/inputFormat';
import { useToast } from 'contexts/ToastContext';
import WalletService from 'services/wallet/walletService';
import { useAuth } from 'hooks/useAuth';
import PAYMENT_METHOD from 'constants/paymentMethod';
import { formatISO } from 'date-fns';
import Popup from '../../components/pop-up/Popup';

const CONSTANTS = {
    LOCALIZATION: {
        VALIDATION_REQUIRED: 'validation.required',
        VALIDATION_MAX_LENGTH: 'validation.max-length',
        EXPIRY: 'add-funds.card-information.expiry',
    },
    VALIDATION_RULES: {
        REQUIRED: 'required',
        MIN_LENGTH: 'minLength',
        MAX_LENGTH: 'maxLength',
        VALIDATE: 'validate',
    },
    MIN_LENGTH: {
        CARD_NUMBER: 13,
        CVV_CVC: 3,
    },
    MAX_LENGTH: {
        CARD_HOLDER_NAME: 50,
        CARD_NUMBER: 19,
        CVV_CVC: 4,
        ZIP_CODE: 20,
        NICK_NAME: 50,
    },
};

const CardInformationForm = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { jwtToken } = useAuth();
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();
    const { t } = useTranslation();
    const cardNumber = useRef({});
    cardNumber.current = watch('cardNumber');
    const confirmCardNumber = useRef({});
    confirmCardNumber.current = watch('confirmCardNumber');
    const { showToast } = useToast();
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [errorMsg, setErrorMsg] = useState({
        title: t('add-funds.error.error-message-title'),
    });

    const redirectToDashboard = () => {
        navigate(PAGE.DASHBOARD);
    };

    useEffect(() => {
        if (!state) {
            redirectToDashboard();
        }
    }, []);

    const onSubmit = data => {
        setLoading(true);
        const date = new Date(data.expiry);
        const year = date.getFullYear();
        const month = date.getMonth();
        const newDate = new Date(year, month);
        const expiry = formatISO(newDate, { representation: 'date' });
        const body = {
            paymentMethod: PAYMENT_METHOD.CREDIT_CARD,
            cardHolderName: data.cardHolderName.trim(),
            cardNumber: data.cardNumber,
            expiry: expiry,
            cvv: data.cvv,
            zipCode: data.zipCode,
            nickName: data.nickName?.trim() || '',
        };
        WalletService.addExternalPaymentMethod(jwtToken, body).then(
            response => {
                if (response?.isSuccess) {
                    showToast(
                        true,
                        false,
                        false,
                        false,
                        '',
                        t(
                            'add-funds.card-information.toast-message.added-successfully',
                        ),
                        '360px',
                    );
                    navigate(PAGE.DASHBOARD);
                } else {
                    if (response?.result) {
                        setErrorMsg({
                            title: errorMsg.title,
                            message: t('add-funds.error.error-message-content'),
                            extraInfo: response?.result.extraInfo3SP,
                        });
                    } else {
                        setErrorMsg({
                            title: errorMsg.title,
                            message: t('error-message.general-error'),
                        });
                    }
                    setIsShowPopup(true);
                }
                setLoading(false);
            },
        );
    };

    const validateDate = val => {
        const currentDate = new Date();
        const value = new Date(val);
        if (isNaN(value) || currentDate > value) {
            return false;
        }
        return true;
    };

    const validCvvTypes = [
        CONSTANTS.VALIDATION_RULES.REQUIRED,
        CONSTANTS.VALIDATION_RULES.MIN_LENGTH,
        CONSTANTS.VALIDATION_RULES.MAX_LENGTH,
    ];
    const validExpiryTypes = [
        CONSTANTS.VALIDATION_RULES.REQUIRED,
        CONSTANTS.VALIDATION_RULES.VALIDATE,
    ];

    const isCvvError = errors.cvv && validCvvTypes.includes(errors.cvv.type);
    const isExpiryError = errors.expiry && validExpiryTypes.includes(errors.expiry.type);

    return (
        <>
            {state?.allowCreation && (
                <Container sx={containerStyle}>
                    <Box sx={boxItemStyle}>
                        <PageTitle
                            title={t('add-funds.card-information.title')}
                            onClick={() => navigate(PAGE.ADD_FUNDS)}
                        />
                    </Box>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={formStyle(theme)}
                    >
                        {isShowPopup && (
                            <Popup
                                open={isShowPopup}
                                title={errorMsg?.title}
                                content={errorMsg?.message}
                                viewDetails={errorMsg?.extraInfo}
                                onClose={() => {
                                    setIsShowPopup(false);
                                }}
                                onAgree={() => {
                                    setIsShowPopup(false);
                                }}
                            />
                        )}
                        <Box sx={boxItemStyle}>
                            <Typography>
                                {t('add-funds.card-information.name-on-card')}{' '}
                                <span style={requiredStyle(theme)}>*</span>
                            </Typography>
                            <Controller
                                name="cardHolderName"
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength:
                                        CONSTANTS.MAX_LENGTH.CARD_HOLDER_NAME,
                                }}
                                render={({ field }) => (
                                    <Input
                                        readOnly={loading}
                                        defaultValue=""
                                        placeholder={t(
                                            'add-funds.card-information.enter-name-on-card',
                                        )}
                                        format={
                                            INPUT_FORMAT
                                                .ALPHABETICAL_CHARACTER_SPACE
                                                .name
                                        }
                                        onValueChange={value =>
                                            field.onChange(value)
                                        }
                                    />
                                )}
                            />
                            <NameOnCardErrorMessageComponent t={t} errors={errors}/>
                        </Box>
                        <Box sx={boxItemStyle}>
                            <Typography>
                                {t('add-funds.card-information.card-number')}{' '}
                                <span style={requiredStyle(theme)}>*</span>
                            </Typography>
                            <Controller
                                name="cardNumber"
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: CONSTANTS.MAX_LENGTH.CARD_NUMBER,
                                    minLength: CONSTANTS.MIN_LENGTH.CARD_NUMBER,
                                }}
                                render={({ field }) => (
                                    <Input
                                        readOnly={loading}
                                        defaultValue=""
                                        placeholder="XXXX-XXXX-XXXX-XXXX"
                                        onValueChange={value =>
                                            field.onChange(
                                                value.replaceAll('-', ''),
                                            )
                                        }
                                        format={
                                            INPUT_FORMAT.CARD_NUMBER_FORMAT.name
                                        }
                                    />
                                )}
                            />
                            <CardNumberErrorMessageComponent t={t} theme={theme} errors={errors}/>
                        </Box>
                        <Box sx={boxStyle}>
                            <Box
                                sx={boxExpiryStyle(isCvvError || isExpiryError)}
                            >
                                <Typography>
                                    {t(CONSTANTS.LOCALIZATION.EXPIRY)}{' '}
                                    <span style={requiredStyle(theme)}>*</span>
                                </Typography>
                                <Controller
                                    name="expiry"
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: validateDate,
                                    }}
                                    render={({ field }) => (
                                        <DatePicker
                                            disablePast={true}
                                            views={['month', 'year']}
                                            format={'MM/YYYY'}
                                            onChange={value =>
                                                field.onChange(value)
                                            }
                                        />
                                    )}
                                />
                                <ExpiryErrorMessageComponent t={t} errors={errors}/>
                            </Box>
                            <Box
                                sx={boxExpiryStyle(isCvvError || isExpiryError)}
                            >
                                <Typography>
                                    {t('add-funds.card-information.cvv-cvc')}{' '}
                                    <span style={requiredStyle(theme)}>*</span>
                                </Typography>
                                <Controller
                                    name="cvv"
                                    control={control}
                                    rules={{
                                        required: true,
                                        minLength: CONSTANTS.MIN_LENGTH.CVV_CVC,
                                        maxLength: CONSTANTS.MAX_LENGTH.CVV_CVC,
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            readOnly={loading}
                                            defaultValue=""
                                            placeholder="123"
                                            onValueChange={value =>
                                                field.onChange(value)
                                            }
                                            format={INPUT_FORMAT.NUMERIC.name}
                                            inputProps={{
                                                'data-testid': 'cvv',
                                            }}
                                        />
                                    )}
                                />
                                <CVVErrorMessageComponent t={t} errors={errors}/>
                            </Box>
                        </Box>
                        <Box sx={{ ...boxItemStyle, mb: 1}}>
                            <Typography>
                                {t('add-funds.card-information.zip-code')}{' '}
                                <span style={requiredStyle(theme)}>*</span>
                            </Typography>
                            <Controller
                                name="zipCode"
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: CONSTANTS.MAX_LENGTH.ZIP_CODE,
                                }}
                                render={({ field }) => (
                                    <Input
                                        readOnly={loading}
                                        defaultValue=""
                                        placeholder={t(
                                            'add-funds.card-information.enter-zip-code',
                                        )}
                                        onValueChange={value =>
                                            field.onChange(value)
                                        }
                                        format={
                                            INPUT_FORMAT.NUMERIC_HYPHEN.name
                                        }
                                    />
                                )}
                            />
                            <Typography sx={hyperTextStyle(theme)}>
                                {t('add-funds.card-information.zip-code-desc')}
                            </Typography>
                            <ZipCodeErrorMessageComponent t={t} errors={errors}/>
                        </Box>
                        <Button
                            type="submit"
                            disabled={loading}
                            loading={loading}
                            variant="contained"
                            label={t('manage-accounts.add-card')}
                        />
                    </form>
                </Container>
            )}
        </>
    );
};

const NameOnCardErrorMessageComponent = ({ t, errors }) => {
    return (
        <>
            {errors.cardHolderName?.type ===
                CONSTANTS.VALIDATION_RULES.REQUIRED && (
                <Typography sx={errorStyle}>
                    {t(CONSTANTS.LOCALIZATION.VALIDATION_REQUIRED, {
                        field: t('add-funds.card-information.name-on-card'),
                    })}
                </Typography>
            )}
            {errors.cardHolderName?.type ===
                CONSTANTS.VALIDATION_RULES.MAX_LENGTH && (
                <Typography sx={errorStyle}>
                    {t(CONSTANTS.LOCALIZATION.VALIDATION_MAX_LENGTH, {
                        maxLength: CONSTANTS.MAX_LENGTH.CARD_HOLDER_NAME,
                    })}
                </Typography>
            )}
        </>
    );
}; 

const CardNumberErrorMessageComponent = ({ t, theme, errors }) => {
    return (
        <>
            {errors.cardNumber?.type ===
                CONSTANTS.VALIDATION_RULES.REQUIRED && (
                <Typography sx={errorStyle(theme)}>
                    {t(CONSTANTS.LOCALIZATION.VALIDATION_REQUIRED, {
                        field: t('add-funds.card-information.card-number'),
                    })}
                </Typography>
            )}
            {errors.cardNumber?.type ===
                CONSTANTS.VALIDATION_RULES.MAX_LENGTH && (
                <Typography sx={errorStyle(theme)}>
                    {t(CONSTANTS.LOCALIZATION.VALIDATION_MAX_LENGTH, {
                        maxLength: CONSTANTS.MAX_LENGTH.CARD_NUMBER,
                    })}
                </Typography>
            )}
            {errors.cardNumber?.type ===
                CONSTANTS.VALIDATION_RULES.MIN_LENGTH && (
                <Typography sx={errorStyle(theme)}>
                    {t('validation.min-length', {
                        minLength: CONSTANTS.MIN_LENGTH.CARD_NUMBER,
                    })}
                </Typography>
            )}
        </>
    );
};

const ExpiryErrorMessageComponent = ({ t, errors }) => {
    return (
        <>
            {errors.expiry?.type === CONSTANTS.VALIDATION_RULES.REQUIRED && (
                <Typography sx={errorStyle}>
                    {t(CONSTANTS.LOCALIZATION.VALIDATION_REQUIRED, {
                        field: t(CONSTANTS.LOCALIZATION.EXPIRY),
                    })}
                </Typography>
            )}
            {errors.expiry?.type === CONSTANTS.VALIDATION_RULES.VALIDATE && (
                <Typography sx={errorStyle}>
                    {t('validation.invalid', {
                        field: t(CONSTANTS.LOCALIZATION.EXPIRY),
                    })}
                </Typography>
            )}
        </>
    );
};

const CVVErrorMessageComponent = ({ t, errors }) => {
    return (
        <>
            {errors.cvv?.type === CONSTANTS.VALIDATION_RULES.REQUIRED && (
                <Typography sx={errorStyle}>
                    {t(CONSTANTS.LOCALIZATION.VALIDATION_REQUIRED, {
                        field: t('add-funds.card-information.cvv-cvc'),
                    })}
                </Typography>
            )}
            {errors.cvv?.type === CONSTANTS.VALIDATION_RULES.MIN_LENGTH && (
                <Typography sx={errorStyle}>
                    {t('validation.min-length', {
                        minLength: CONSTANTS.MIN_LENGTH.CVV_CVC,
                    })}
                </Typography>
            )}
            {errors.cvv?.type === CONSTANTS.VALIDATION_RULES.MAX_LENGTH && (
                <Typography sx={errorStyle}>
                    {t(CONSTANTS.LOCALIZATION.VALIDATION_MAX_LENGTH, {
                        maxLength: CONSTANTS.MAX_LENGTH.CVV_CVC,
                    })}
                </Typography>
            )}
        </>
    );
};

const ZipCodeErrorMessageComponent = ({ t, errors }) => {
    return (
        <>
            {errors.zipCode?.type === CONSTANTS.VALIDATION_RULES.REQUIRED && (
                <Typography sx={errorStyle}>
                    {t(CONSTANTS.LOCALIZATION.VALIDATION_REQUIRED, {
                        field: t('add-funds.card-information.zip-code'),
                    })}
                </Typography>
            )}
            {errors.zipCode?.type === CONSTANTS.VALIDATION_RULES.MAX_LENGTH && (
                <Typography sx={errorStyle}>
                    {t(CONSTANTS.LOCALIZATION.VALIDATION_MAX_LENGTH, {
                        maxLength: CONSTANTS.MAX_LENGTH.ZIP_CODE,
                    })}
                </Typography>
            )}
        </>
    );
};

export default CardInformationForm;

