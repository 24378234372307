/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const logoStyle = {
    width: 'auto',
};
export const inlineErrorStyle = {
    color: '#FF4646',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
    marginTop: '4px',
};
export const inputPanelStyle = {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    padding: '8px 0',
};
export const formTitleStyle = {
    fontSize: '34px',
    fontWeight: 600,
    lineHeight: '40px',
};
export const formStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: '28px',
    minHeight: '633px',
};
export const imageContainerStyle = { 
    marginBottom: '8px', 
    width: '100%' 
};
