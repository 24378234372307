/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

const SUBSECTION = {
    KASH: 'KASH',
    SAVING: 'SAVING',
};

export default SUBSECTION;
