/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const containerStyle = {
    mt: '100px',
    mb: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
};

export const tabGroupStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '55%',
};

export const tabPanelStyle = {
    width: '100%',
};

export const buttonStyle = {
    marginTop: '24px',
};

export const emptyPageStyle = {
    height: 'fit-content',
};
