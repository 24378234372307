/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import DIRECTION from 'constants/direction';

export const StyledPagination = styled(Pagination)(({ theme, dir }) => ({
    '& .MuiPaginationItem-root': {
        color: theme.appPalette?.grey.grey80,
    },
    '& .Mui-disabled': {
        color: theme.appPalette?.grey.grey20,
        opacity: '1 !important',
    },
    '& .MuiPaginationItem-root.Mui-selected': {
        backgroundColor: theme.appPalette?.primary.primary12,
        '&:hover': {
            backgroundColor: theme.appPalette?.primary.primary12,
        },
    },
    '& .MuiButtonBase-root:hover': {
        backgroundColor: theme.appPalette?.primary.primary8,
    },
    '& .MuiPaginationItem-previousNext': {
        transform: dir === DIRECTION.RTL ? 'rotate(180deg)' : 'unset',
    }
}));
