/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import resourceUrl from 'resourceResolver';
import { CustomSVG, boxContainerStyle, titleStyle } from './PageTitle.style';
import { Box, Typography, useTheme } from '@mui/material';
import { pageTitlePropTypes } from 'types/pageTitlePropTypes';
import { useTranslation } from 'react-i18next';
const arrowLeft = resourceUrl('icons/arrow-left.svg');

const PageTitle = ({ onClick, title, submit }) => {
    const theme = useTheme();
    const { i18n } = useTranslation();
    const dir = i18n.dir();
    return (
        <Box sx={boxContainerStyle}>
            <CustomSVG src={arrowLeft} dir={dir} onClick={onClick} submit={submit} />
            <Typography sx={titleStyle(theme, submit)}>{title}</Typography>
        </Box>
    );
};

PageTitle.propTypes = {
    ...pageTitlePropTypes,
};

export default PageTitle;

