/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const containerStyle = {
    marginTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    width: '600px',
}

export const titleStyle = (theme) => ({
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '34px',
    color: theme.appPalette?.grey.grey80,
});