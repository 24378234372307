/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const cardAccountCustomComboBoxPropTypes = {
    options: PropTypes.array,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    isSvgImage: PropTypes.bool,
    onChangeValue: PropTypes.func,
};
