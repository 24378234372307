/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    StyledH4,
    StyledBody2,
    contentGridStyle,
    subContentGridStyle,
    rightGridStyle,
} from './TransactionItem.style';
import { transactionItemPropTypes } from 'types/transactionItemPropTypes';

const TransactionItem = ({ txnItem }) => {
    const { t } = useTranslation();
    return (
        <Grid item>
            <StyledBody2>{t('transactions.date')} {txnItem.dateStatus}</StyledBody2>
            <Grid container sx={contentGridStyle}>
                <Grid item  sx={subContentGridStyle}>
                    <StyledH4>{txnItem.actionType}</StyledH4>
                    <StyledBody2>{txnItem.categoryType}</StyledBody2>
                </Grid>
                <Grid
                    item
                    sx={{ ...subContentGridStyle, ...rightGridStyle }}
                >
                    <StyledH4>
                        {t('transfer.tab.transfer-money.amount')}:{' '}
                        {txnItem.amount}
                    </StyledH4>
                </Grid>
            </Grid>
        </Grid>
    );
};

TransactionItem.propTypes = {
    ...transactionItemPropTypes,
};
export default TransactionItem;

