/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactSVG } from 'react-svg';

export const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        display: 'flex',
        width: '240px',
        padding: '8px 0',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '8px',
        background: '#FFF',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
        overflow: 'hidden !important',
    },
    '& .MuiList-padding': {
        padding: 0,
    },
    '& .MuiList-root': {
        width: '100%',
    },
    '& .MuiButtonBase-root': {
        paddingLeft: '24px',
        paddingRight: '24px',
        display: 'flex',
        gap: '8px',
    },
}));

export const CustomSVG = styled(ReactSVG)(({ theme }) => ({
    svg: {
        'path[fill]': {
            fill: theme.appPalette?.grey.grey80,
        },
        'path[stroke]': {
            stroke: theme.appPalette?.grey.grey80,
        },
    },
    lineHeight: 0,
}));

export const buttonStyle = {
    minWidth: 'auto',
};
