/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { PropTypes } from 'prop-types';

export const noDataFoundPropTypes = {
    icon: PropTypes.string,
    label: PropTypes.string,
};
