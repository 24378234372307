/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import DIRECTION from 'constants/direction';

export const boxContainerStyle = {
    position: 'relative',
    width: '420px',
    height: '265px',
};

export const cardNameStyle = (dir) => ({
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '30px',
    color: '#FFF',
    position: 'absolute',
    top: '24px',
    left: dir === DIRECTION.RTL ? 'unset' : '25px',
    right: dir === DIRECTION.RTL ? '25px' : 'unset',
});

export const backgroundImageStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '12px',
};

export const bodyStyle = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    alignItems: 'center',
    top: '110px',
    width: '325px',
    left: '44px',
};

export const cardNumStyle = {
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '42px',
    color: '#FFF',
    textShadow: '0px 0px 9.097px rgba(0, 0, 0, 0.30)',
};

export const footerStyle = {
    position: 'absolute',
    bottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    width: '368px',
    left: '25px',
};

export const cardHolderStyle = {
    fontSize: '21px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '27px',
    color: '#FFF',
    textShadow: '0px 0px 9.097px rgba(0, 0, 0, 0.30)',
};

export const expiryBoxStyle = {
    textAlign: 'end',
};

export const expiryDateStyle = {
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '12px',
    color: '#FFF',
    textShadow: '0px 0px 6px rgba(0, 0, 0, 0.30)',
};

export const endingInStyle = {
    textShadow: '0px 0px 6px rgba(0, 0, 0, 0.30)',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '8px',
    textTransform: 'uppercase',
};
