/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { PropTypes } from 'prop-types';

export const searchBoxPropTypes = {
    placeholder: PropTypes.string,
    filterAction: PropTypes.func,
    searchAction: PropTypes.func,
};
