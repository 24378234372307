/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { useTheme, Box } from '@mui/material';
import React from 'react';
import OverflowTip from '../tooltip/OverflowTip';
import {
    componentStyle,
    contentContainerStyle,
    contentHeaderStyle,
    contentStyle,
    imageStyle,
    titleStyle,
    tooltipStyle,
} from './ShoppingNetworkItem.style';
import { shoppingNetworkItemPropTypes } from 'types/shoppingNetworkItemPropTypes';
import { useTranslation } from 'react-i18next';

const ShoppingNetworkItem = ({ title, contentHeader, content, imagePath }) => {
    const theme = useTheme();
    const { i18n } = useTranslation();
    const dir = i18n.dir();
    return (
        <Box sx={componentStyle(theme, dir)}>
            <Box>
                <OverflowTip
                    title={title}
                    textStyle={titleStyle(theme, dir)}
                ></OverflowTip>
                <Box sx={contentContainerStyle(theme)}>
                    <OverflowTip
                        title={contentHeader}
                        textStyle={contentHeaderStyle(theme, dir)}
                    ></OverflowTip>
                    <OverflowTip
                        title={content}
                        tooltipStyle={tooltipStyle(theme, dir)}
                        textStyle={contentStyle(theme, dir)}
                    ></OverflowTip>
                </Box>
            </Box>
            <Box>
                <img src={imagePath} alt="imageItem" style={imageStyle} />
            </Box>
        </Box>
    );
};

ShoppingNetworkItem.propTypes = {
    ...shoppingNetworkItemPropTypes,
};

export default ShoppingNetworkItem;

