/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StyledPagination } from './Pagination.style';
import { paginationPropTypes } from 'types/paginationPropTypes';
import { useTranslation } from 'react-i18next';
const Pagination = ({ defaultPage, totalPages, onValueChange }) => {
    const [page, setPage] = useState(defaultPage);
    const { i18n } = useTranslation();
    const dir = i18n.dir();
    useEffect(() => {
        setPage(defaultPage);
    }, [defaultPage]);

    const handleChange = (event, value) => {
        setPage(value);
        onValueChange && onValueChange(value);
    };

    return (
        <Stack>
            <StyledPagination
                count={totalPages}
                page={page}
                onChange={handleChange}
                dir={dir}
            />
        </Stack>
    );
};

Pagination.propTypes = {
    ...paginationPropTypes,
};

export default Pagination;

