/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import LOCAL_STORAGE_KEYS from 'constants/localStorageKey';
const LocalStorageService = {
    setJwt: (token, expireDate) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.JWT_TOKEN, token);
        localStorage.setItem(LOCAL_STORAGE_KEYS.JWT_EXPIRE_DATE, expireDate);
    },
    getJwtToken: () => {
        return localStorage.getItem(LOCAL_STORAGE_KEYS.JWT_TOKEN);
    },
    getJwtExpireDate: () => {
        return localStorage.getItem(LOCAL_STORAGE_KEYS.JWT_EXPIRE_DATE);
    },
    removeJwt: () => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.JWT_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.JWT_EXPIRE_DATE);
    },
    setOtpSignature: (nonce, expireDate) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.OTP_NONCE, nonce);
        localStorage.setItem(LOCAL_STORAGE_KEYS.OTP_EXPIRE_DATE, expireDate);
    },
    setSearch: (search) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.SEARCH, search);
    },
    getSearch: () => {
        return localStorage.getItem(LOCAL_STORAGE_KEYS.SEARCH);
    },
    getOtpNonce: () => {
        return localStorage.getItem(LOCAL_STORAGE_KEYS.OTP_NONCE);
    },
    getOtpExpireDate: () => {
        return localStorage.getItem(LOCAL_STORAGE_KEYS.OTP_EXPIRE_DATE);
    },
    removeOtpSignature: (nonce, expireTime) => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.OTP_NONCE);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.OTP_EXPIRE_DATE);
    },
    getUserBrowerLanguage: () => {
        return localStorage.getItem(LOCAL_STORAGE_KEYS.USER_BROWSER_LANGUAGE);
    },
    setUserBrowerLanguage: (language) => {
        localStorage.setItem(
            LOCAL_STORAGE_KEYS.USER_BROWSER_LANGUAGE,
            language,
        );
    },
};

export default LocalStorageService;

