/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ShoppingNetworkItem from 'components/shopping-network-item/ShoppingNetworkItem';
import TravelNetworkItem from 'components/travel-network-item/TravelNetworkItem';
import PointNetworkItem from 'components/point-network-item/PointNetworkItem';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getAccountBalance } from 'services/accounting/accountingService';
import { useAuth } from 'hooks/useAuth';
import ACCOUNT_TYPE from 'constants/accountType';
import { getNearbyMerchantCount } from 'services/profile/profileService';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import {
    btnArrowLeftStyle,
    btnArrowRightStyle,
    btnStyles,
    carouselItemStyles,
    iconStyle,
    networkBoxStyle,
    responsive,
} from './Network.style';
import { networkPropTypes } from 'types/networkPropTypes';

const CustomArrowLeft = ({ onClick }) => (
    <button onClick={onClick} style={{ ...btnStyles, ...btnArrowLeftStyle }}>
        <ArrowBackIosIcon sx={iconStyle} />
    </button>
);

CustomArrowLeft.propTypes = {
    onClick: PropTypes.func,
};

const CustomArrowRight = ({ onClick }) => (
    <button onClick={onClick} style={{ ...btnStyles, ...btnArrowRightStyle }}>
        <ArrowForwardIosIcon sx={iconStyle} />
    </button>
);

CustomArrowRight.propTypes = {
    onClick: PropTypes.func,
};

const RewardAccount = function (balance) {
    this.balance = balance;
};

const Shopping = function (nearByMerchantCount) {
    this.nearByMerchantCount = nearByMerchantCount;
};

const Network = () => {
    const { jwtToken } = useAuth();
    const { t } = useTranslation();

    const [shopping, setShopping] = useState(null);
    const [rewardAccount, setRewardAccount] = useState(null);

    const [location, setLocation] = useState(null);

    useEffect(() => {
        navigator.geolocation?.getCurrentPosition((position) => {
            setLocation({
                longitude: position.coords.longitude,
                latitude: position.coords.latitude,
            });
        });
    }, []);

    useEffect(() => {
        const fetchShoppingInfo = async () => {
            if (!location) {
                return new Shopping(0);
            }
            return getNearbyMerchantCount(jwtToken, location);
        };
        fetchShoppingInfo().then((r) => setShopping(new Shopping(r?.total)));
    }, [location]);

    useEffect(() => {
        const fetchRewardAccountInfo = async () => {
            return getAccountBalance(jwtToken, ACCOUNT_TYPE.REWARD_ACCOUNT);
        };
        fetchRewardAccountInfo().then((r) =>
            setRewardAccount(new RewardAccount(r?.balance)),
        );
    }, []);

    const theme = useTheme();
    return (
        <Box sx={networkBoxStyle}>
            <Carousel
                deviceType="desktop"
                ssr={true}
                responsive={responsive}
                customLeftArrow={<CustomArrowLeft />}
                customRightArrow={<CustomArrowRight />}
            >
                <div style={carouselItemStyles}>
                    <PointNetworkItem
                        title={t('network.reward-account.title')}
                        content={(rewardAccount?.balance || 0).toString()}
                        caption={t('network.reward-account.caption').replace(
                            '%s',
                            theme.rewardAccount.poweredBy,
                        )}
                        imagePath={'images/point-network-image.png'}
                    />
                </div>
                <div style={carouselItemStyles}>
                    <ShoppingNetworkItem
                        title={t('network.shopping.title')}
                        content={t('network.shopping.content')}
                        imagePath={'images/shopping-image.png'}
                        contentHeader={(
                            shopping?.nearByMerchantCount || 0
                        ).toString()}
                    />
                </div>
                <div style={carouselItemStyles}>
                    <TravelNetworkItem
                        title={t('network.travel.title')}
                        content={t('network.travel.content')}
                        imagePath={'images/travel-image.png'}
                    />
                </div>
            </Carousel>
        </Box>
    );
};

Network.propTypes = {
    ...networkPropTypes,
};

export default Network;

