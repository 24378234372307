/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { useTheme, Box } from '@mui/material';
import React from 'react';
import OverflowTip from '../tooltip/OverflowTip';
import {
    componentStyle,
    titleStyle,
    contentStyle,
    imageStyle,
    captionStyle,
    tooltipStyle,
} from './PointNetworkItem.style';
import { pointNetworkItemPropTypes } from 'types/pointNetworkItemPropTypes';
import { useTranslation } from 'react-i18next';

const PointNetworkItem = ({ title, content, caption, imagePath }) => {
    const theme = useTheme();
    const { i18n } = useTranslation();
    const dir = i18n.dir();
    return (
        <Box sx={componentStyle(theme, dir)}>
            <Box>
                <OverflowTip
                    title={title}
                    textStyle={titleStyle(theme, dir)}
                ></OverflowTip>
                <OverflowTip
                    title={content}
                    textStyle={contentStyle(theme, dir)}
                ></OverflowTip>
                <OverflowTip
                    title={caption}
                    tooltipStyle={tooltipStyle(theme, dir)}
                    textStyle={captionStyle(theme, dir)}
                ></OverflowTip>
            </Box>
            <Box>
                <img src={imagePath} alt="imageItem" style={imageStyle} />
            </Box>
        </Box>
    );
};

PointNetworkItem.propTypes = {
    ...pointNetworkItemPropTypes,
};

export default PointNetworkItem;

