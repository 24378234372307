/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DIRECTION from 'constants/direction';

export const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
};

export const StyledTransactionTitle = styled(Typography)(({ theme }) => ({
    color: theme.appPalette?.grey.grey80,
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '18px',
}));

export const transactionContentStyle = {
    margin: '0 28px',
};

export const paginationStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
};

export const noresultBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '24px',
};

export const noTransactionFoundContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
};

export const noTransactionFoundBoxStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '24px 0',
    gap: '16px',
    borderRadius: '10px',
    border: `1px solid ${theme.appPalette?.grey.grey12}`,
});

export const noresultTextStyle = (theme) => ({
    color: theme.appPalette?.grey.grey60,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
});

export const headerTextStyle = (theme) => ({
    color: theme.appPalette?.grey.grey80,
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '34px',
});

export const noresultHeaderBoxStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
};

export const resultTextStyle = (theme) => ({
    color: theme.appPalette?.grey.grey60,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '18px',
});

export const headerBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
};

export const noresultImageStyle = {
    width: '143px',
    height: '120px',
};

export const noTransactionFoundImageStyle = {
    width: '120px',
    height: '120px',
    padding: '15px 0',
};

export const centerStyle = {
    margin: '0 auto',
};

export const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    cursor: 'pointer',
    textDecoration: 'none',
    marginTop: '8px',
};

export const viewAllStyle = (theme) => ({
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    color: theme.appPalette?.primary.primary100,
});

export const StyledKeyboardArrowRightIcon = styled(KeyboardArrowRightIcon)(({theme, dir}) => ({
    '&.MuiSvgIcon-root': {
        transform: dir === DIRECTION.RTL ? 'rotate(180deg)' : 'unset',
    }
}));