/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { CssBaseline } from '@mui/material';
import Header from 'components/header/Header';
import Sidebar from 'components/sidebar/Sidebar';
import React from 'react';
import PropTypes from 'prop-types';
import {Box} from "@mui/system";
const ScenesPropTypes = {
    children: PropTypes.node.isRequired,
};
const mainStyles = {width: '100%'};
const Scenes = ({ children }) => {
    return (
        <Box display="flex">
            <CssBaseline />
            <Header />
            <Sidebar></Sidebar>
            <main className="content" style={mainStyles}>
                {children}
            </main>
        </Box>
    );
};

Scenes.propTypes = {
    ...ScenesPropTypes,
};

export default Scenes;
