/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

/**
 * @description Define the expected data types for props passed to Textarea component.
 */

import { PropTypes } from 'prop-types';

export const textareaPropTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    focus: PropTypes.bool,
};
