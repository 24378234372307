/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const containerStyle = id => ({
    paddingTop: (id && '100px') || 0,
    paddingBottom: (id && '60px') || 0,
    width: '588px',
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
});

export const formStyle = theme => ({
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    alignSelf: 'stretch',
    borderRadius: '16px',
    border: `1px solid ${theme.appPalette?.grey.grey8}`,
    background: '#FFF',
    boxShadow: '0px 8px 15px 0px rgba(95, 104, 113, 0.12)',
});

export const boxContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
};

export const descStyle = theme => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineheight: '20px',
    color: theme.appPalette?.grey.grey60,
    marginLeft: '44px',
});

export const boxItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignSelf: 'stretch',
};

export const requiredStyle = theme => ({
    color: theme.appPalette.danger,
});

export const inputStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    width: '280px',
};

export const titleStyle = {
    fontWeight: 600,
    lineHeight: '18px',
    fontSize: '14px',
};

export const hyperTextStyle = theme => ({
    fontWeight: 400,
    color: theme.appPalette?.grey.grey60,
    lineHeight: '20px',
    letterSpacing: '0.3px',
    fontSize: '12px',
});

export const errorStyle = theme => ({
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    color: theme.appPalette.danger,
});

