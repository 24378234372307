/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import PropTypes from "prop-types";

export const cardImagePropTypes = {
    cardType: PropTypes.string,
    cardNum: PropTypes.string,
    cardEmail: PropTypes.string,
    cardHolder: PropTypes.string,
    cardExpiry: PropTypes.string,
    cardName: PropTypes.string,
    balance: PropTypes.string,
    cardNetwork: PropTypes.string,
    cardCode: PropTypes.string,
};
