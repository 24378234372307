/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import Api from 'configs/api';
import {isSuccess} from "constants/statusCode";
import ApiClient from 'services/api-client/apiClient';

const AuthenticationService = {
    login: async (username, password) => {
        try {
            const response = await ApiClient.post(Api.LOGIN, {
                username: username,
                password: password,
            });

            if (response && isSuccess(response.code)) {
                const { nonce, expiry } = response.result;
                const jwtToken =
                    response.result.tokenDto && response.result.tokenDto.jwtToken
                        ? response.result.tokenDto.jwtToken
                        : undefined;
                const jwtExpireDate =
                    response.result.tokenDto && response.result.tokenDto.jwtToken
                        ? response.result.tokenDto.expireDate
                        : undefined;

                return {
                    jwt: jwtToken,
                    jwtExpireDate: jwtExpireDate,
                    nonce: nonce,
                    expiry: expiry
                };
            }

            return {
                code: response.code,
                message: response.message
            };
        } catch (e) {
            return undefined;
        }
    },


};

export default AuthenticationService;
