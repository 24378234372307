/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

const DIRECTION = {
    LTR: 'ltr',
    RTL: 'rtl',
};

export default DIRECTION;
