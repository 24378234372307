/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { styled } from '@mui/material/styles';
import { ReactSVG } from 'react-svg';
import resourceUrl from 'resourceResolver';
import { Snackbar } from '@mui/material';

export const titleStyle = (theme) => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    color: theme.appPalette?.grey.grey80,
});

export const contentStyle = (theme, title) => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: title ? '400' : '600',
    lineHeight: '20px',
    color: theme.appPalette?.grey.grey80,
});

export const boxStyle = (title) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: title ? '4px' : 0,
    marginTop: title ? 0 : '2px'
});

export const StyledSnackbar = styled(Snackbar)(({ theme, maxwidth, title }) => ({
    '& .MuiPaper-root': {
        padding: '16px',
        background: '#FFF',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
        borderRadius: '8px',
        gap: '16px',
        alignItems: !title ? 'center': 'flex-start',
        minWidth: '320px',
        maxWidth: maxwidth || 'none',
    },
    '& .MuiAlert-message': {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        padding: '0',
        color: theme.appPalette?.grey.grey80,
    },
    '& .MuiAlert-icon': {
        opacity: 1,
        height: '24px',
        padding: '0',
        marginInlineEnd: '0',
    },
    '& .MuiAlert-action': {
        padding: '0',
        marginInlineEnd: '0',
    },
}));

const CustomSVG = styled(ReactSVG)(({ theme }) => ({
    svg: {
        'path[fill]': {
            fill: theme.appPalette?.grey.grey80,
        },
        'path[stroke]': {
            stroke: theme.appPalette?.grey.grey80,
        },
    },
    lineHeight: 0,
    cursor: 'pointer',
}));

export const Icon = ({ isSuccess, isError, isWarning, isInformation }) => {
    return (
        <>
            {isSuccess && (
                <img
                    src={resourceUrl('icons/toast-success.svg')}
                    alt="Success icon"
                />
            )}
            {isError && (
                <img
                    src={resourceUrl('icons/toast-failed.svg')}
                    alt="Error icon"
                />
            )}
            {isWarning && (
                <img
                    src={resourceUrl('icons/warning.svg')}
                    alt="Warning icon"
                />
            )}
            {isInformation && (
                <img
                    src={resourceUrl('icons/information.svg')}
                    alt="Information icon"
                />
            )}
        </>
    );
};

export const ActionIcon = ({ handleClose }) => {
    return (
        <CustomSVG
            onClick={handleClose}
            src={resourceUrl('icons/toast-close.svg')}
        />
    );
};
