/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const boxContentStyle = (theme, widthValue, isCenter, isSpaceEvenly) => ({
    display: 'flex',
    padding: '16px 32px',
    alignItems: 'center',
    gap: '40px',
    borderRadius: '10px',
    background: theme.appPalette?.primary.primary8,
    height: '142px',
    width: widthValue,
    justifyContent: getJustifyContent(isCenter, isSpaceEvenly),
});

const getJustifyContent = (isCenter, isSpaceEvenly) => {
    if (isCenter) {
        return 'center';
    } else if (isSpaceEvenly) {
        return 'space-evenly';
    } else {
        return 'space-between';
    }
};

