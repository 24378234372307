/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const boxNoAccountsFoundStyle = (theme) => ({
    display: 'flex',
    padding: '24px 0px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    borderRadius: '10px',
    border: `1px solid ${theme.appPalette?.grey.grey12}`,
});

export const noAccountsFoundStyle = (theme) => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    color: theme.appPalette?.grey.grey60,
});

export const imgStyle = {
    width: '120px',
    height: '120px',
    padding: '15px 0',
};
