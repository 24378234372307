/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import DIRECTION from "constants/direction";

export const componentStyle = (theme, dir) => ({
    backgroundColor: theme.appPalette?.grey.white,
    padding: '16px',
    border: 1,
    borderColor: 'white',
    borderRadius: '10px',
    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexShrink: 0,
    direction: dir,
    justifyContent: 'space-between',
    alignItems: 'center',
});

export const titleStyle = (theme, dir) => ({
    fontSize: '16px',
    color: theme.appPalette?.grey.grey80,
    marginBottom: '5px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    maxHeight: '20px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: dir === DIRECTION.RTL ? 'right' : 'left',
});

export const contentStyle = (theme, dir) => ({
    color: theme.appPalette?.grey.grey80,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: dir === DIRECTION.RTL ? 'right' : 'left',
});

export const imageContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
};

export const imageStyle = {
    width: '64px',
    minWidth: '64px',
    height: '64px',
    minHeight: '64px',
    borderRadius: '15px',
};

export const captionStyle = (theme, dir) => ({
    color: theme.appPalette?.grey.grey60,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14px',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: dir === DIRECTION.RTL ? 'right' : 'left',
});

export const contentGroupStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
};
export const tooltipStyle = (theme) => ({
    color: theme.appPalette?.grey.white,
    backgroundColor: theme.appPalette?.grey.grey80,
    transform: 'translateX(-20px) !important',
    padding: '8px',
    marginTop: '20px !important',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
});
