/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

const PAGE = {
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    OTP: '/otp',
    TRANSACTIONS: '/dashboard/transactions',
    LOGOUT: '/logout',
    TRANSFER: '/transfer',
    LOAD: '/load',
    ADD_FUNDS: '/add-funds',
    CARDS: '/cards',
    BILL_PAY: '/bill-pay',
    SPENDING_CONTROLS: '/spending-controls',
    REWARDS: '/rewards',
    NOTIFICATIONS: '/notifications',
    MANAGE_ACCOUNTS: '/manage-accounts',
    OVERDRAFT: '/overdraft',
    SETTINGS: '/settings',
    TRANSFER_SUCCESSFUL: '/transfer/successful',
    TRANSFER_FAILED: '/transfer/failed',
    ADD_FUNDS_CARD_INFORMATION: '/add-funds/card-information',
    ADD_FUNDS_PAYPAL: '/add-funds/paypal',
    ADD_FUNDS_NEQUI: '/add-funds/nequi',
    ADD_FUNDS_GOOGLE_PAY: '/add-funds/google-pay',
    EMPTY_PAGE: '/empty-page',
    WITHDRAW: '/withdraw',
    WITHDRAW_SUCCESSFUL: '/withdraw/successful',
    LINKED_BANK_ACCOUNTS: '/linked-bank-accounts',
    LINKED_BANK_ACCOUNTS_DETAIL: '/linked-bank-accounts/:id',
};
export default PAGE;

