/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const labelStyle = {
    color: '#9D9DB0',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0.3px',
    textTransform: 'none',
}

export const overrideLabelStyle = (theme) => ({
    '& .MuiTab-root': {
        padding: 0,
        minHeight: '33px'
    },
    '& .MuiTab-root.Mui-selected': {
        color: theme.appPalette?.grey.grey80,
    },
    '& .MuiTabs-flexContainer': {
        borderBottom: `1px solid ${theme.appPalette?.grey.grey12}`,
        display: 'flex',
        gap: '24px'
    }
});

export const minHeight = {
    minHeight: '33px'
}

export const indicatorStyle = (theme) => ({
    height: '1px',
    backgroundColor: theme.appPalette?.primary.primary100,
});