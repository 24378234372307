/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { PropTypes } from 'prop-types';

export const rowsPerPagePropTypes = {
    defaultValue: PropTypes.number,
    rowsPerPage: PropTypes.array,
    totalRecords: PropTypes.number,
    firstRecordOfPage: PropTypes.number,
    lastRecordOfPage: PropTypes.number,
    onValueChange: PropTypes.func,
};
