/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

export const btnStyles = {
    position: 'absolute',
    zIndex: 1,
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
};

export const btnArrowLeftStyle = {
    left: '-15px',
};

export const btnArrowRightStyle = {
    right: '-15px',
};

export const carouselItemStyle = {
    margin: '0 16px',
    padding: '16px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export const imgStyle = {
    width: '120px',
    height: '120px',
    padding: '15px 0',
};

export const containerStyle = (theme) => ({
    borderRadius: '10px',
    background: theme.appPalette?.primary.primary8,
    padding: '28px 16px',
});

export const boxContainerStyle = (theme) => ({
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignUtems: 'center',
    gap: '24px',
    borderRadius: '12px',
    background: '#FFF',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.20)',
    minHeight: '376px'
});

export const boxNoAccountsFoundStyle = (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
});

export const titleStyle = (theme) => ({
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    color: theme.appPalette?.grey.grey80,
    position: 'relative',
    zIndex: 10,
});

export const noAccountsFoundStyle = (theme) => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    color: theme.appPalette?.grey.grey60,
});
