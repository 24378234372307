/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

const TENANT = {
    KANOO: 'kanoo',
    COOMEVA: '1006',
    GEORGIA: '1007',
    HYT: 'hytapp',
    KARD: '1004',
    UAE: '1008',
    JAPAN: '1009',
};

export default TENANT;
