/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React, { createContext, useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
const RootColorsContext = createContext();

export const RootColorsProvider = ({ children }) => {
    const theme = useTheme();
    const [colors, setColors] = useState({
        '--swiper-pagination-bullet-color': theme.appPalette?.primary.primary40,
        '--swiper-pagination-bullet-active-color': theme.appPalette?.primary.primary80,
    });
    useEffect(() => {
        const root = document.querySelector(':root');
        Object.keys(colors).forEach((key) => {
            root.style.setProperty(key, colors[key]);
        });
    }, [colors]);

    return (
        <RootColorsContext.Provider value={{ colors, setColors }}>
            {children}
        </RootColorsContext.Provider>
    );
};
