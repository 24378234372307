/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

/**
 * @description Withdraw component.
 */

import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, useTheme, Box } from '@mui/material';
import {
    containerStyle,
    titleStyle,
    hyperTextStyle,
    formStyle,
    boxContainerStyle,
    boxTitleStyle,
    confirmTitleStyle,
    boxSuccessStyle,
    imageStyle,
    successfulTitleStyle,
    CustomSVG,
    boxItemStyle,
    requiredStyle,
    inlineErrorStyle,
    boxTransferStyle,
    loadTitleStyle,
    boxInfoStyle,
    boxInfoItemStyle,
    titleInfoStyle,
    memoStyle,
    formattedAmountStyle,
    boxInformationStyle,
    descInformationStyle,
} from './Withdraw.style';
import {
    getWithdrawalRequestInfo,
    withdrawalRequest,
} from 'services/payment/paymentService';
import { getCardListing } from 'services/accounting/accountingService';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import CardAccountCustomComboBox from 'components/card-account-custom-combo-box/CardAccountCustomComboBox';
import Input from 'components/input/Input';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import PAGE from 'constants/page';
import PaymentMethod from 'constants/paymentMethod';
import Button from 'components/button/Button';
import { Controller, useForm } from 'react-hook-form';
import resourceUrl from 'resourceResolver';
import Popup from 'components/pop-up/Popup';
import Textarea from 'components/textarea/Textarea';
import { isSuccess } from 'constants/statusCode';

const arrowLeft = resourceUrl('icons/arrow-left.svg');
const iconCard = resourceUrl('icons/card.svg');
const iconBank = resourceUrl('icons/bank.svg');

const CONSTANTS = {
    LOCALIZATION: {
        WITHDRAW_FROM: 'withdraw.withdraw-from',
        TO_BANK: 'withdraw.to-bank',
        VALIDATION_REQUIRED: 'validation.required',
        VALIDATION_INVALID: 'validation.invalid',
    },
    MEMO_MAX_LENGTH: 200,
    AMOUNT_GREATER_THAN: 0,
};

const Withdraw = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { jwtToken } = useAuth();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [withdrawalRequestInfo, setWithdrawalRequestInfo] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [bankAccounts, setBankAccounts] = useState([]);
    const [cashAccounts, setCashAccounts] = useState([]);
    const [cashAccountOptions, setCashAccountOptions] = useState([]);
    const [bankAccountOptions, setBankAccountOptions] = useState([]);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [formattedAmount, setFormattedAmount] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [remitterInfo, setRemitterInfo] = useState({});
    const [beneficiaryInfo, setBeneficiaryInfo] = useState({});
    const dir = i18n.dir();
    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        watch,
    } = useForm();
    const memo = useRef({});
    memo.current = watch('memo');
    const getCardInfoById = (data, id) => data.find(card => card.id === id);

    const onBackClick = () => {
        setIsSubmit(false);
        setWithdrawalRequestInfo(null);
    };

    const normalizeCardOptions = (data, setOptions, logo) => {
        const cardOptions = data.map(item => {
            return {
                title: item.cardName + ' *' + item.cardNum,
                value: item.id,
                logo: logo,
            };
        });
        setOptions(cardOptions);
    };

    const getBankAccounts = () => {
        getCardListing(jwtToken, PaymentMethod.BANK_TRANSFER, true).then(
            response => {
                setBankAccounts(response);
                const mappedData = response.map(item => {
                    return { ...item, cardName: item.bankName };
                });
                normalizeCardOptions(
                    mappedData,
                    setBankAccountOptions,
                    iconBank,
                );
            },
        );
    };

    const getCashAccounts = () => {
        getCardListing(jwtToken, PaymentMethod.CASH_CARD, true).then(
            response => {
                //Remove after support transfer for SA card
                const mappedData = response.filter(
                    val => val.paymentMethod !== PaymentMethod.SA,
                );
                setCashAccounts(mappedData);
                normalizeCardOptions(
                    mappedData,
                    setCashAccountOptions,
                    iconCard,
                );
            },
        );
    };

    useEffect(() => {
        getCashAccounts();
        getBankAccounts();
    }, []);

    const onSubmit = data => {
        const remitterCard = getCardInfoById(cashAccounts, data.transferFrom);
        setRemitterInfo(remitterCard);
        const beneficiaryInfo = getCardInfoById(bankAccounts, data.transferTo);
        setBeneficiaryInfo(beneficiaryInfo);
        getWithdrawalInfo(data.amount, remitterCard.paymentMethod);
    };

    const getWithdrawalInfo = async (amount, remitterPaymentMethod) => {
        await getWithdrawalRequestInfo(
            jwtToken,
            amount,
            remitterPaymentMethod,
        ).then(response => {
            if (response && isSuccess(response.code)) {
                setWithdrawalRequestInfo(response.result);
            } else {
                setErrorMsg(response?.message || t('error-message.common'));
                setIsShowPopup(true);
            }
        });
    };

    const onConfirm = async data => {
        setIsSubmit(true);
        const remitterInfo = getCardInfoById(cashAccounts, data.transferFrom);
        const beneficiaryInfo = getCardInfoById(bankAccounts, data.transferTo);
        const body = {
            remitterWalletSessionId: remitterInfo.id,
            remitterCardType: remitterInfo.paymentMethod,
            beneficiaryWalletSessionId: beneficiaryInfo.id,
            beneficiaryCardType: beneficiaryInfo.paymentMethod,
            amount: data.amount,
            memo: data.memo,
        };
        const fallbackResponse = {
            transferFrom: remitterInfo.cardName + ' *' + remitterInfo.cardNum,
            transferTo:
                beneficiaryInfo.bankName + ' *' + beneficiaryInfo.cardNum,
        };
        await withdrawalRequest(jwtToken, body, fallbackResponse).then(
            response => {
                if (response?.isSuccess) {
                    setIsSuccessful(true);
                    setTransactionId(response.transactionId);
                } else {
                    setErrorMsg(response?.message || t('error-message.common'));
                    setIsShowPopup(true);
                }
                setIsSubmit(false);
            },
        );
    };

    return (
        <Container sx={containerStyle}>
            <PopupComponent isShowPopup={isShowPopup} errorMsg={errorMsg} setIsShowPopup={setIsShowPopup}/>
            <Typography sx={titleStyle}>{t('wallet-info.withdraw')}</Typography>
            <form
                onSubmit={handleSubmit(
                    withdrawalRequestInfo ? onConfirm : onSubmit,
                )}
                style={formStyle(theme)}
            >
                {withdrawalRequestInfo && (
                    <Box sx={boxContainerStyle}>
                        {!isSuccessful && (
                            <Box sx={boxTitleStyle}>
                                <CustomSVG
                                    dir={dir}
                                    src={arrowLeft}
                                    onClick={onBackClick}
                                    color={theme.appPalette?.grey.grey80}
                                    sx={{ cursor: 'pointer' }}
                                />
                                <Typography sx={confirmTitleStyle}>
                                    {t('transfer.confirm-information')}
                                </Typography>
                            </Box>
                        )}
                        <IconSuccessComponent t={t} isSuccessful={isSuccessful} />
                        <Box sx={boxTransferStyle(theme)}>
                            <Typography sx={loadTitleStyle(theme)}>
                                {t('withdraw.withdrawal-amount')}
                            </Typography>
                            <Typography noWrap sx={formattedAmountStyle}>
                                {formattedAmount}
                            </Typography>
                        </Box>
                        <Box sx={boxInfoStyle}>
                            {isSuccessful && (
                                <Box sx={boxInformationStyle(theme)}>
                                    <CustomSVG
                                        src={resourceUrl(
                                            'icons/information-24px.svg',
                                        )}
                                        color={
                                            theme.appPalette?.primary.primary100
                                        }
                                    />
                                    <Typography sx={descInformationStyle}>
                                        {t(
                                            'withdraw.email-notification-message',
                                        )}
                                    </Typography>
                                </Box>
                            )}
                            <Box sx={boxInfoItemStyle}>
                                <Typography sx={titleInfoStyle(theme)}>
                                    {t(CONSTANTS.LOCALIZATION.WITHDRAW_FROM)}
                                </Typography>
                                <Typography>
                                    {remitterInfo.cardName +
                                        ' *' +
                                        remitterInfo.cardNum}
                                </Typography>
                            </Box>
                            <Box sx={boxInfoItemStyle}>
                                <Typography sx={titleInfoStyle(theme)}>
                                    {t(CONSTANTS.LOCALIZATION.TO_BANK)}
                                </Typography>
                                <Typography>
                                    {beneficiaryInfo.bankName +
                                        ' *' +
                                        beneficiaryInfo.cardNum}
                                </Typography>
                            </Box>
                            {transactionId && (
                                <Box sx={boxInfoItemStyle}>
                                    <Typography sx={titleInfoStyle(theme)}>
                                        {t('transfer-status.transaction-ID')}
                                    </Typography>
                                    <Typography>{transactionId}</Typography>
                                </Box>
                            )}
                            <Box sx={boxInfoItemStyle}>
                                <Typography sx={titleInfoStyle(theme)}>
                                    {t('transfer.tab.transfer-money.fee')}
                                </Typography>
                                <Typography>
                                    {withdrawalRequestInfo?.formattedTotalFee}
                                </Typography>
                            </Box>
                            <Box sx={boxInfoItemStyle}>
                                <Typography sx={titleInfoStyle(theme)}>
                                    {t('transfer.tab.transfer-money.memo')}
                                </Typography>
                                <Typography sx={memoStyle}>
                                    {getValues('memo')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )}

                {!withdrawalRequestInfo && (
                    <Box sx={boxContainerStyle}>
                        <Box sx={boxItemStyle}>
                            <Typography>
                                {t(CONSTANTS.LOCALIZATION.WITHDRAW_FROM)}{' '}
                                <span style={requiredStyle(theme)}>*</span>
                            </Typography>
                            <Controller
                                name="transferFrom"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <CardAccountCustomComboBox
                                        defaultValue={getValues('transferFrom')}
                                        disabled={
                                            !!withdrawalRequestInfo ||
                                            cashAccountOptions?.length === 0
                                        }
                                        label={t('withdraw.choose-a-cash-card')}
                                        isSvgImage={true}
                                        options={cashAccountOptions}
                                        onChangeValue={e =>
                                            field.onChange(e.target.value)
                                        }
                                    />
                                )}
                            />
                            {errors.transferFrom?.type === 'required' && (
                                <Typography sx={inlineErrorStyle}>
                                    {t(CONSTANTS.LOCALIZATION.VALIDATION_REQUIRED, {
                                        field: t(CONSTANTS.LOCALIZATION.WITHDRAW_FROM),
                                    })}
                                </Typography>
                            )}
                        </Box>
                        <Box sx={boxItemStyle}>
                            <Typography>
                                {t(CONSTANTS.LOCALIZATION.TO_BANK)}{' '}
                                <span style={requiredStyle(theme)}>*</span>
                            </Typography>
                            <Controller
                                name="transferTo"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <CardAccountCustomComboBox
                                        defaultValue={getValues('transferTo')}
                                        disabled={
                                            !!withdrawalRequestInfo ||
                                            bankAccountOptions?.length === 0
                                        }
                                        label={t(
                                            'withdraw.choose-a-bank-account',
                                        )}
                                        isSvgImage={true}
                                        options={bankAccountOptions}
                                        onChangeValue={e =>
                                            field.onChange(e.target.value)
                                        }
                                    />
                                )}
                            />
                            {errors.transferTo?.type === 'required' && (
                                <Typography sx={inlineErrorStyle}>
                                    {t(CONSTANTS.LOCALIZATION.VALIDATION_REQUIRED, {
                                        field: t(CONSTANTS.LOCALIZATION.TO_BANK),
                                    })}
                                </Typography>
                            )}
                        </Box>
                        <Box sx={boxItemStyle}>
                            <Typography>
                                {t('transfer.tab.transfer-money.amount')}{' '}
                                <span style={requiredStyle(theme)}>*</span>
                            </Typography>
                            <Controller
                                name="amount"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: {
                                        largerThanZero: v =>
                                            v > CONSTANTS.AMOUNT_GREATER_THAN,
                                    },
                                }}
                                render={({ field }) => (
                                    <Input
                                        defaultValue={getValues('amount')}
                                        onValueChange={value => {
                                            setFormattedAmount(value);
                                            field.onChange(
                                                value
                                                    .replace(/[\$,]/g, '')
                                                    .replace(
                                                        theme.numericFormatted
                                                            .prefix,
                                                        '',
                                                    ),
                                            );
                                        }}
                                        name="amount-input"
                                        placeholder={t(
                                            'transfer.tab.transfer-money.enter-amount',
                                        )}
                                        inputProps={{
                                            inputComponent: NumericFormatCustom,
                                            inputProps: {
                                                prefix: theme.numericFormatted
                                                    .prefix,
                                                scale: theme.numericFormatted
                                                    .scale,
                                            },
                                        }}
                                    />
                                )}
                            />
                            <AmountErrorMessageComponent t={t} errors={errors}/>
                        </Box>
                        <Box sx={boxItemStyle}>
                            <Typography>
                                {t('transfer.tab.transfer-money.memo')}
                            </Typography>
                            <Controller
                                name="memo"
                                control={control}
                                render={({ field }) => (
                                    <Textarea
                                        maxLength="200"
                                        maxWidth="488px"
                                        defaultValue={getValues('memo')}
                                        onChange={value =>
                                            field.onChange(value)
                                        }
                                        placeholder={t('transfer.enter-memo')}
                                    />
                                )}
                            />
                            <Typography sx={hyperTextStyle(theme)}>
                                {memo.current?.length || '0'}/{CONSTANTS.MEMO_MAX_LENGTH}
                            </Typography>
                        </Box>
                    </Box>
                )}
                <ButtonComponent
                    navigate={navigate}
                    t={t}
                    isSuccessful={isSuccessful}
                    isSubmit={isSubmit}
                    withdrawalRequestInfo={withdrawalRequestInfo}
                />
            </form>
        </Container>
    );
};

const NumericFormatCustom = React.forwardRef(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={values => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.formattedValue,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                allowNegative={false}
                decimalScale={other.scale}
                prefix={other.prefix}
            />
        );
    },
);

const PopupComponent = ({ isShowPopup, errorMsg, setIsShowPopup }) => {
    return (
        isShowPopup && (
            <Popup
                open={isShowPopup}
                content={errorMsg}
                onClose={() => {
                    setIsShowPopup(false);
                }}
                onAgree={() => {
                    setIsShowPopup(false);
                }}
            />
        )
    );
};

const IconSuccessComponent = ({ t, isSuccessful }) => {
    return (
        isSuccessful && (
            <Box sx={boxSuccessStyle}>
                <img
                    style={imageStyle}
                    src={resourceUrl('icons/successful-icon.svg')}
                    alt={t('withdraw.create-successful-request')}
                />
                <Typography sx={successfulTitleStyle}>
                    {t('withdraw.create-successful-request')}
                </Typography>
            </Box>
        )
    );
};

const AmountErrorMessageComponent = ({ t, errors }) => {
    return (
        (errors.amount?.type === 'required' ||
            errors.amount?.type === 'largerThanZero') && (
            <Typography sx={inlineErrorStyle}>
                {t(
                    errors.amount?.type === 'required'
                        ? CONSTANTS.LOCALIZATION.VALIDATION_REQUIRED
                        : CONSTANTS.LOCALIZATION.VALIDATION_INVALID,
                    {
                        field: t('transfer.tab.transfer-money.amount'),
                    },
                )}
            </Typography>
        )
    );
};

const ButtonComponent = ({
    navigate,
    t,
    isSuccessful,
    isSubmit,
    withdrawalRequestInfo,
}) => {
    return (
        <>
            {!isSuccessful && (
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmit}
                    loading={isSubmit}
                    fullWidth={true}
                    label={
                        withdrawalRequestInfo
                            ? t('transfer.tab.transfer-money.confirm')
                            : t('button.continue')
                    }
                />
            )}
            {isSuccessful && (
                <Button
                    onClick={() => navigate(PAGE.DASHBOARD)}
                    variant="contained"
                    fullWidth={true}
                    label={t('button.ok')}
                />
            )}
        </>
    );
};

export default Withdraw;

