/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import { useEffect } from 'react';
import { useTranslation, I18nextProvider } from 'react-i18next';
import I18n from 'i18n';
import DIRECTION from 'constants/direction';
import { useTheme } from '@mui/material';
import LANGUAGE_SUPPORTED_RTL from 'constants/languageSupportedRtl';

const I18nProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        let language =
            (navigator.language ||
            navigator.userLanguage).toLowerCase();
        const tenantBaseLanguage = theme.languageSupported.find(
            (lng) => lng.isBase,
        );
        i18n.changeLanguage(tenantBaseLanguage.code);
        const isLanguageSupported = theme.languageSupported.some(
            (lng) => lng.code === language,
        ) || theme.languageSupported.some(
            (lng) => {
                if (lng.code === language.split('-')[0]) {
                    language = lng.code;
                    return true;
                }
                return false;
            },
        );
        if (isLanguageSupported) {
            i18n.changeLanguage(language);
        }
    }, []);

    const updateDir = () => {
        const html = document.getElementsByTagName('html')[0];
        html.dir = LANGUAGE_SUPPORTED_RTL.includes(i18n.language)
            ? DIRECTION.RTL
            : DIRECTION.LTR;
    };

    updateDir();

    useEffect(() => {
        i18n.on('languageChanged', updateDir);
    }, [i18n]);

    return <I18nextProvider i18n={I18n}>{children}</I18nextProvider>;
};

export default I18nProvider;

