/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import LoginForm from 'components/login-form/LoginForm';
import OtpConfirmationForm from 'components/otp-confirmation-form/OtpConfirmationForm';
import PAGE from 'constants/page';
import { Routes as ReactRoutes, Route, useLocation } from 'react-router-dom';
import { ProtectedRoute } from 'routes/ProtectedRoute';
import Scenes from 'scenes/Scenes';
import Dashboard from 'scenes/dashboard/Dashboard';
import Login from 'scenes/login/Login';
import Logout from 'scenes/logout/Logout';
import Transactions from 'scenes/transactions/Transactions';
import EmptyPage from 'scenes/empty-page/EmptyPage';
import TransferStatus from 'scenes/transfer-status/TransferStatus';
import Transfer from '../scenes/transfer/Transfer';
import AddFunds from 'scenes/add-funds/AddFunds';
import CardInformationForm from 'components/card-information-form/CardInformationForm';
import Settings from 'scenes/settings/Settings';
import ManagaAccounts from 'scenes/manage-accounts/ManageAccounts';
import Withdraw from 'scenes/withdraw/Withdraw';
import BankAccount from 'scenes/bank-account/BankAccount';
import BankAccountForm from 'components/bank-account-form/BankAccountForm';

const Routes = () => {
    const location = useLocation();
    return (
        <ReactRoutes>
            <Route
                path={PAGE.LOGIN}
                element={
                    <Login>
                        <LoginForm />
                    </Login>
                }
            />

            <Route
                path="/"
                element={
                    <Login>
                        <LoginForm />
                    </Login>
                }
            />

            <Route
                path={PAGE.OTP}
                element={
                    <Login>
                        <OtpConfirmationForm />
                    </Login>
                }
            />
            <Route
                path={PAGE.DASHBOARD}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <Dashboard />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.TRANSACTIONS}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <Transactions />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.LOAD}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <Transfer key={PAGE.LOAD} />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.WITHDRAW}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <Withdraw />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.TRANSFER}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <Transfer key={PAGE.TRANSFER} />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.WITHDRAW_SUCCESSFUL}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <TransferStatus />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.ADD_FUNDS}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <AddFunds />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.BILL_PAY}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <EmptyPage />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.SPENDING_CONTROLS}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <EmptyPage />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.REWARDS}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <EmptyPage />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.NOTIFICATIONS}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <EmptyPage />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.CARDS}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <ManagaAccounts />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.LINKED_BANK_ACCOUNTS}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <BankAccount />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.LINKED_BANK_ACCOUNTS_DETAIL}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <BankAccountForm />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.OVERDRAFT}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <EmptyPage />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.SETTINGS}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <Settings />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.TRANSFER_SUCCESSFUL}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <TransferStatus />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.TRANSFER_FAILED}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <TransferStatus />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.ADD_FUNDS_CARD_INFORMATION}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <CardInformationForm />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.ADD_FUNDS_PAYPAL}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <EmptyPage />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.ADD_FUNDS_NEQUI}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <EmptyPage />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.ADD_FUNDS_GOOGLE_PAY}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <EmptyPage />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route
                path={PAGE.EMPTY_PAGE}
                element={
                    <ProtectedRoute>
                        <Scenes>
                            <EmptyPage />
                        </Scenes>
                    </ProtectedRoute>
                }
            />
            <Route path={PAGE.LOGOUT} element={<Logout />} />
        </ReactRoutes>
    );
};
export default Routes;

